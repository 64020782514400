import React, { useRef } from 'react';

import colors from 'Constants/colors';
import useCombinedRefs from 'Hooks/useCombinedRefs';
import styled, { css } from 'styled-components';

import { InfoText } from './informational/InfoText';

export const InputElement = styled.input<Partial<InputProps>>`
  ::placeholder {
    color: ${({ error, warning, disabled }) =>
      (error && colors.redRose) ||
      (warning && colors.yellowDaffodil) ||
      (disabled && colors.greyAsher) ||
      colors.greySterling};
  }
  ${props =>
    (props.error && `color: ${colors.redTart}};`) ||
    (props.warning && `color: ${colors.yellowButter}};`) ||
    ''}
  text-align: ${({ unit }) => (unit ? 'right' : 'left')};
  padding: ${({ small }) => (small ? '0.5rem' : '0.7rem')};
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  &:focus {
    box-shadow: 0 0 0 0 !important;
  }
  border-radius: 1rem;
  cursor: ${({ dropdown }) => (dropdown ? 'pointer' : 'text')};
  height: ${({ small }) => (small ? '2rem' : '3rem')};
  display: flex;
  align-items: center;
  background: ${({ disabled }) => (disabled ? colors.greyPearl : colors.white)};
  z-index: 0;
  position: relative;
  overflow: hidden;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  box-sizing: border-box;
  width: 100%;
`;

const InputContainer = styled.div<Partial<InputProps>>`
  background: ${({ disabled }) => (disabled ? colors.greyPearl : colors.white)};
  border-radius: 1rem;
  &:focus-within {
    border-color: ${({ error, warning }) =>
      (error && colors.redTart) || (warning && colors.yellowButter) || colors.nexusGreen};
    ${({ dropdown, error, warning }) =>
      !dropdown &&
      `box-shadow: 0 0 0px 3px ${
        (error && colors.redRose) || (warning && colors.yellowDaffodil) || colors.greenSeafoam
      } !important;`}
  }
  border: 1px solid
    ${({ error, warning }) =>
      (error && colors.redTart) || (warning && colors.yellowButter) || colors.greyPearl};
  display: flex;
  position: relative;
  ${({ error, disabled }) =>
    (disabled && `color: ${colors.greyAsher}};`) ||
    (error && `color: ${colors.redTart}};`) ||
    `color: ${colors.greyAsher} !important;`}
  &[disabled] {
    cursor: not-allowed;
  }
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  width: 100%;
  align-items: center;
  padding-left: 1rem;
  box-sizing: border-box;
`;

export const Unit = styled.div<Partial<InputProps>>`
  width: ${({ unitWidth }) => unitWidth || 'auto'};
  margin-right: ${({ small }) => (small ? '0.5rem' : '1rem')};
  color: ${({ error }) => (error && colors.redTart) || colors.greyAsher};
  color: ${({ error, warning }) =>
    (error && colors.redTart) || (warning && colors.yellowButter) || colors.greyAsher};
`;

const iconCss = css`
  background: ${colors.transparent};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.greyStone};
  border: 0;
  width: 3rem;
  text-align: center;
  line-height: 3rem;
`;

const IconContainer = styled.div`
  ${iconCss};
`;
const IconButton = styled.button<Partial<InputProps>>`
  ${iconCss};
  border-radius: 0 1rem 1rem 0;
  &:hover,
  &:focus {
    font-size: 1.5rem;
  }
  &:focus {
    box-shadow: inset 0 0 0px 3px
      ${({ error, warning }) =>
        (error && colors.redRose) ||
        (warning && colors.yellowDaffodil) ||
        colors.greenSeafoam} !important;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  width: 100%;
  padding-bottom: 1rem;
  box-sizing: border-box;
`;

export type InputProps = {
  value: string | number;
  onChange?: React.EventHandler<any>;
  onFocus?: React.EventHandler<any>;
  onBlur?: React.EventHandler<any>;
  unit?: string;
  unitWidth?: string;
  small?: boolean;
  dropdown?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  info?: string;
  error?: boolean | string;
  warning?: boolean | string;
  type?: string;
  inputMode?: 'text' | 'none' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal';
  pattern?: string;
  min?: string;
  max?: string;
  maxWidth?: string;
  ref?: React.Ref<HTMLInputElement>;
  icon?: React.ReactNode;
  'data-testid'?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ onChange, ...props }, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombinedRefs(innerRef, ref);
  return (
    <InputWrapper>
      <InputContainer
        disabled={props.disabled}
        error={props.error}
        warning={props.warning}
        dropdown={props.dropdown}
        maxWidth={props.maxWidth}
        onChange={e => e.stopPropagation()}
        onClick={() => {
          innerRef && innerRef.current && innerRef.current.focus();
        }}
      >
        {props.icon}
        <InputElement
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e)}
          ref={combinedRef}
        />
        {props.unit ? (
          <Unit
            error={props.error}
            warning={props.warning}
            small={props.small}
            unitWidth={props.unitWidth}
          >
            {props.unit}
          </Unit>
        ) : null}
        {props.dropdown ? (
          <IconContainer>▾</IconContainer>
        ) : (
          !props.unit &&
          !props.small &&
          props.value &&
          !props.disabled && (
            <IconButton onClick={() => onChange && onChange({ target: { value: '' } })}>
              ×
            </IconButton>
          )
        )}
      </InputContainer>
      {!props.small && props.error && <InfoText error>{props.error}</InfoText>}
      {!props.small && props.warning && <InfoText warning>{props.warning}</InfoText>}
      <InfoText>{props.info}</InfoText>
    </InputWrapper>
  );
});

Input.displayName = 'Input';

export default Input;
