import { Provider } from '@ethersproject/providers';
import { abis, addresses } from '@nexusmutual/sdk';
import { getContract } from 'Services/getContract';

const address = addresses.wNXM;

const wNXM = (provider: Provider, account?: string) =>
  getContract(address, abis.wNXM, provider, account);

export default wNXM;
