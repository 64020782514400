const MAKER_DAO_ID = '5';
const CELSIUS_ADDRESS = '0xc57D000000000000000000000000000000000001';

export type Asset = 'ETH' | 'DAI' | 'USDC' | 'cbBTC' | 'NXM';

export const ASSET_BY_ID: Record<number, Asset> = {
  0: 'ETH',
  1: 'DAI',
  6: 'USDC',
  7: 'cbBTC',
  255: 'NXM',
};

const COVER_ASSETS_MAP: Record<Asset, number> = {
  ETH: 0,
  DAI: 1,
  USDC: 6,
  cbBTC: 7,
  NXM: 255,
};

type PaymentAssetOption = { [K in Asset]: { value: K; label: K } };

const COVER_PAYMENT_ASSETS_OPTIONS: PaymentAssetOption = {
  ETH: { value: 'ETH', label: 'ETH' },
  DAI: { value: 'DAI', label: 'DAI' },
  USDC: { value: 'USDC', label: 'USDC' },
  cbBTC: { value: 'cbBTC', label: 'cbBTC' },
  NXM: { value: 'NXM', label: 'NXM' },
};

const COVER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  EXPIRED: 'Expired',
  PENDING_CLAIM: 'Pending claim',
  PENDING_PAYOUT: 'Pending payout',
};

export {
  CELSIUS_ADDRESS,
  COVER_ASSETS_MAP,
  COVER_PAYMENT_ASSETS_OPTIONS,
  COVER_STATUS,
  MAKER_DAO_ID as MAKER_DAO_ADDRESS,
};
