import type { RootStateOrAny } from 'react-redux';

import { composeWithDevTools } from '@redux-devtools/extension';
import {
  type Reducer,
  type ReducersMapObject,
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import createSagaMiddleware, { type Saga, type Task } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import captureExceptionsMiddleware from 'Services/captureExceptionsMiddleware';
import rootStateReducer from 'Services/rootDuck/reducer';
import rootStateSaga from 'Services/rootDuck/sideEffects';

export type StoreInjectable = ReturnType<typeof createStore> & {
  injectReducer(key: string, reducer: Reducer): void;
  asyncReducers: ReducersMapObject;
  injectSaga: ReturnType<typeof createSagaInjector>;
};

// Define the reducers that will always be present in the application
const staticReducers = { root: rootStateReducer };

// Define sagas that will always be present in the application
const staticSagas = { root: rootStateSaga } as {
  [key: string]: Saga;
};
const rootSaga = function* rootSaga() {
  yield all(Object.keys(staticSagas).map(key => fork(staticSagas[key])));
};

const sagaMiddleware = createSagaMiddleware();

// runSaga is middleware.run function
// statigSagas is a root saga for static sagas
function createSagaInjector(runSaga: (s: Saga) => Task, root: Saga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map<string, Task>();

  const isInjected = (key: string) => injectedSagas.has(key);

  const injectSaga = (key: string, saga: Saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) {
      return;
    }

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a staticlly loaded file,
  injectSaga('root', root);

  return injectSaga;
}

function createReducer(asyncReducers?: ReducersMapObject) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

// Configure the store
function configureStore(initialState: RootStateOrAny) {
  const composeEnhancers =
    composeWithDevTools({
      name: `Redux`,
      trace: true,
      traceLimit: 50,
    }) || compose;

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, captureExceptionsMiddleware)),
  );

  // Add a dictionary to keep track of the registered async reducers
  (store as StoreInjectable).asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  (store as StoreInjectable).injectReducer = (key, asyncReducer) => {
    (store as StoreInjectable).asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer((store as StoreInjectable).asyncReducers));
  };

  // Add injectSaga method to our store
  (store as StoreInjectable).injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

  // Return the modified store
  return store;
}

const store = configureStore(window.REDUX_DATA);

export type RootState = ReturnType<typeof createReducer>;

export default store;
