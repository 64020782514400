import colors from 'Constants/colors';
import styled from 'styled-components';

export const DetailCardWithPattern = styled.div<{ patternUrl: string; hasError?: boolean }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 22rem;
  height: 14rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: ${colors.nexusGreen};
  background-image: url(${({ patternUrl }) => patternUrl});
  background-repeat: no-repeat;
  background-size: 22rem;
  background-position: left bottom;
  color: ${colors.white};
  font-size: 3.5rem;

  span {
    font-size: 1rem;
    position: absolute;
    color: black;
    &:first-child {
      left: 1rem;
      top: 0.5rem;
    }
    &:last-child {
      right: 1rem;
      bottom: 0.5rem;
      font-size: 1.5rem;
    }
    &:only-child {
      font-size: 1rem;
    }
  }

  ${({ hasError }) =>
    hasError &&
    ` :after {
        content: url('assets/warning.svg');
        position: absolute;
        top: -2rem;
        right: 1rem;
        fill: ${colors.yellowDaffodil};
    }
  `}
`;
