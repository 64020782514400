import React from 'react';

import Ripple from 'Components/Ripple';
import Spinner from 'Components/Spinner';
import colors from 'Constants/colors';
import styled from 'styled-components';

const Container = styled.div<MessageProps>`
  min-height: 2rem;
  color: ${props =>
    (props.icon && colors.greyAsher) ||
    (props.danger && colors.redTart) ||
    (props.success && colors.nexusGreen) ||
    (props.warning && colors.yellowDark) ||
    colors.greyAsher};
  text-align: left;
`;

const RippleWrapper = styled.div`
  margin-right: 1rem;
  margin-left: 0.5rem;
  display: inline-block;
`;

const SpinnerWrapperRelative = styled.div`
  display: inline-block;
  position: relative;
  width: 2.5rem;
`;
const SpinnerWrapperAbsolute = styled.div`
  position: absolute;
  top: -1.25rem;
  left: 0.25rem;
`;

type MessageProps = {
  icon?: string;
  success?: boolean;
  pulse?: boolean;
  danger?: boolean;
  warning?: boolean;
  loading?: boolean;
  className?: string;
};

const Message = ({ children, loading, ...props }: React.PropsWithChildren<MessageProps>) => (
  <Container {...props}>
    {children ? (
      <>
        {loading ? (
          <SpinnerWrapperRelative>
            <SpinnerWrapperAbsolute>
              <Spinner color={colors.nexusGreen} size="1.5rem" />
            </SpinnerWrapperAbsolute>
          </SpinnerWrapperRelative>
        ) : (
          <RippleWrapper>
            <Ripple
              {...props}
              pulse={props.pulse || (!props.pulse && (props.danger || props.warning))}
            />
          </RippleWrapper>
        )}
        {children}
      </>
    ) : null}
  </Container>
);

export default Message;
