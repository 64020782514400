import React from 'react';

import { RadioOption } from '../components';
import { RadioGroupProps } from '../RadioGroup';

export const transformChildren = (
  children: any | any[],
  selected: RadioGroupProps['selected'],
  onChange?: RadioGroupProps['onChange'],
): React.ReactNode[] => {
  // We use this `React.Children.map` because the `children` prop can be an object or array, this
  // React helper method knows how to deal with that
  return React.Children.map(children, child => {
    if (child?.type?.name === RadioOption.name) {
      // Add required props to RadioOption element and return a cloned (new) element
      return React.cloneElement(child, {
        checked: child.props.checked || selected === child.props.value,
        onClick: () => onChange?.(child.props.value),
      });
    } else {
      // This is not a `RadioOption` element, probably a div or something else to wrap the options
      // for styling purposes. So clone the element and recursively tranform the children. If no
      // children are found it is probably text or some other leaf element, just return that as is
      return child?.props?.children
        ? React.cloneElement(child, {}, transformChildren(child.props.children, selected, onChange))
        : child;
    }
  });
};
