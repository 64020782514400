import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Allowance from 'Components/Allowance';
import PropTypes from 'prop-types';
import rootActions from 'Services/rootDuck/actions';
import {
  selectAccount,
  selectNxmAllowance,
  selectProvider,
  selectSetNxmAllowanceLoading,
  selectSetNxmAllowanceWaiting,
} from 'Services/rootDuck/selectors';

const AllowanceNXM = ({ name, requiredAllowance }) => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const provider = useSelector(selectProvider);
  const nxmAllowance = useSelector(selectNxmAllowance);
  const setNxmAllowanceLoading = useSelector(selectSetNxmAllowanceLoading);
  const setNxmAllowanceWaiting = useSelector(selectSetNxmAllowanceWaiting);

  return (
    <Allowance
      name={name}
      requiredAllowance={requiredAllowance}
      grantedAllowance={nxmAllowance}
      loading={setNxmAllowanceLoading}
      waiting={setNxmAllowanceWaiting}
      token="NXM"
      setAllowance={value => dispatch(rootActions.setNXMAllowance({ account, provider, value }))}
    />
  );
};

AllowanceNXM.propTypes = {
  name: PropTypes.string,
  requiredAllowance: PropTypes.object,
};

export default AllowanceNXM;
