import React from 'react';

import colors from 'Constants/colors';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 48em) {
    margin-bottom: 1rem;
  }
  margin-bottom: 2rem;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  margin: 0 2rem 0 0;
  color: ${colors.greyStone};
`;

const Description = styled.div`
  font-weight: 400;
  color: ${colors.greyStone};
  text-align: justify;
`;

const ContentWrapper = styled.div`
  flex: 1;
  margin: 1rem 0;
`;

type ContentLayoutProps = {
  children: React.ReactNode;
  heading: string;
  description: string | React.ReactNode;
};

const ContentLayout = ({ children, heading, description }: ContentLayoutProps) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      <Description>{description}</Description>
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
};

export default ContentLayout;
