import React from 'react';
import Modal from 'react-modal';

import Button from 'Components/Button';
import { Row } from 'Components/gridComponents';
import colors from 'Constants/colors';
import styled from 'styled-components';

import { GreyText } from './FormElements';

const CloseBtn = styled.button`
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.greyAsher};
  font-size: 2rem;
  font-weight: 600;
  &:focus {
    outline: none;
  }
`;

const ModalTitle = styled.h1`
  color: ${colors.greyAsher};
  font-size: 2rem;
  font-weight: 200;
  width: inherit;
  text-align: center;
`;

const ButtonsContainer = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const CenteredContent = styled.div`
  text-align: center;
`;

type ModalProps = {
  isOpen: boolean;
  toggle: () => void;
  title?: string;
  description?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  children?: React.ReactNode;
};

const ConfirmationModal = ({
  children,
  isOpen,
  toggle,
  title,
  description,
  confirmText = 'Yes',
  cancelText = 'No',
  onConfirm,
  onCancel,
}: ModalProps) => {
  return (
    <>
      <Modal
        appElement={document.getElementById('root') || undefined}
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={toggle}
        shouldFocusAfterRender={false}
        style={{
          overlay: {
            zIndex: 9999,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.70)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: colors.white,
            overflow: 'auto',
            borderRadius: '1rem',
            outline: 'none',
            padding: '2rem',
            width: '60%',
            height: '30%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          },
        }}
      >
        <CloseBtn onClick={toggle}>x</CloseBtn>
        <CenteredContent>
          <ModalTitle>{title}</ModalTitle>
          <GreyText>{description}</GreyText>
        </CenteredContent>
        {children}
        <ButtonsContainer>
          <Button
            width={'10rem'}
            onClick={() => {
              onCancel?.();
              toggle();
            }}
          >
            {cancelText}
          </Button>
          <Button
            primary
            width={'10rem'}
            onClick={() => {
              onConfirm?.();
              toggle();
            }}
          >
            {confirmText}
          </Button>
        </ButtonsContainer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
