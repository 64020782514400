import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const StickyContainer = styled.div`
  position: sticky;
  transition: top 0.2s;
  top: 5rem;
`;

const StickySidebarContainer = ({ children }) => {
  const scrollY = useRef(window.pageYOffset);
  const element = useRef(null);
  const handler = () => {
    const currentScroll = window.pageYOffset;
    if (element.current) {
      element.current.style.top =
        scrollY.current > currentScroll || currentScroll < 60 ? '5rem' : '2rem';
    }
    scrollY.current = currentScroll;
  };

  useEffect(() => {
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);
  return <StickyContainer ref={element}>{children}</StickyContainer>;
};

const SidebarLayout = ({ components, ...props }) => {
  const { Sidebar, Content } = components;

  return (
    <div className="row">
      {Sidebar ? (
        <>
          <div className="col-xs-12 col-sm-7 col-md-6 col-lg-7">
            <Content {...props} />
          </div>
          <div className="col-xs-12 col-sm-5 col-md-6 col-lg-5">
            <StickySidebarContainer>
              <Sidebar {...props} />
            </StickySidebarContainer>
          </div>
        </>
      ) : (
        <div className="col-xs-12">
          <Content {...props} />
        </div>
      )}{' '}
    </div>
  );
};

SidebarLayout.propTypes = {
  components: PropTypes.shape({
    Content: PropTypes.func,
    Sidebar: PropTypes.func,
  }),
};

export default SidebarLayout;
