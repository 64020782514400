import React, { useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

import { ExternalLink } from 'Components';
import colors from 'Constants/colors';
import { NEXUS_MUTUAL_COOKIE_CONSENT } from 'Constants/cookieConsent';
import { clearGTM, consentAnalytics, initGTM, initSentry } from 'Services/analyticsUtils';
import styled from 'styled-components';

import { ToggleButton } from './buttons/ToggleButton';

export const CookieBannerStyles = styled.div`
  .consentOptionsRow {
    display: flex;
    margin-top: 1rem;
    padding: 0 1rem;
  }
  .toggleBtnWrapper {
    width: 10rem;
    label {
      color: ${colors.white};
      font-weight: 700;
    }
  }
  .cookieBtnWrapper {
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
    width: 20%;
    height: 100%;
    align-self: center;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .noticeText {
    line-height: 1.5rem;
    font-size: 13px;
    padding: 1rem 1rem 0 1rem;
  }
`;

type CookieValue = 'decline' | 'necessary' | 'analytics';

const CustomCookieBanner = () => {
  const [consentToggle, setConsentToggle] = React.useState(true);

  const initAnalytics = () => {
    const cookie = getCookieConsentValue(NEXUS_MUTUAL_COOKIE_CONSENT) as CookieValue | undefined;

    switch (cookie) {
      case 'decline':
        clearGTM();
        break;

      case 'necessary':
        initGTM();
        break;

      case 'analytics':
        initSentry();
        initGTM();
        consentAnalytics();
        break;

      default:
        // Do nothing — cookie banner is shown
        break;
    }
  };

  useEffect(initAnalytics, []);

  return (
    <CookieBannerStyles>
      <CookieConsent
        cookieName={NEXUS_MUTUAL_COOKIE_CONSENT}
        cookieValue={consentToggle ? 'analytics' : 'necessary'}
        declineCookieValue="decline"
        enableDeclineButton
        buttonText={consentToggle ? 'Allow all' : 'Allow selection'}
        declineButtonText={'Reject'}
        onAccept={initAnalytics}
        onDecline={initAnalytics}
        buttonStyle={{
          background: colors.nexusBlue,
          border: `1px solid ${colors.white}`,
          color: colors.white,
          borderRadius: '5px',
          height: '2.5rem',
          fontSize: '1rem',
          fontWeight: 600,
          marginBottom: 0,
        }}
        declineButtonStyle={{
          background: colors.whitePale,
          color: colors.nexusBlue,
          borderRadius: '5px',
          height: '2.5rem',
          fontSize: '1rem',
          fontWeight: 600,
        }}
        style={{
          background: colors.nexusBlue,
          padding: '0 1.5rem',
          margin: 0,
          fontWeight: 600,
        }}
        contentStyle={{
          margin: '0 0.3rem',
          fontWeight: 600,
        }}
        buttonWrapperClasses="cookieBtnWrapper"
        flipButtons
      >
        <div className="noticeText">
          We use cookies to provide you with the best experience and to help improve our app. By
          clicking "Allow all", you agree to the storing of cookies on your device to enhance site
          navigation, analyze app usage and provide user support. Read our{' '}
          <ExternalLink href="https://uploads-ssl.webflow.com/63736bd2a2814959db751b1c/63ce96d557a9453cb40dda0b_Privacy-Policy.pdf">
            Privacy Policy
          </ExternalLink>{' '}
          for more information.
        </div>

        <div className="consentOptionsRow">
          <div className="toggleBtnWrapper">
            <ToggleButton
              disabled
              label="Necessary"
              isActive={true}
              onClick={() =>
                alert(
                  'Mandatory - can not be deselected. Necessary cookies help make an app usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                )
              }
            />
          </div>
          <div className="toggleBtnWrapper">
            <ToggleButton
              label="Analytics"
              isActive={consentToggle}
              onClick={() => setConsentToggle(!consentToggle)}
            />
          </div>
        </div>
      </CookieConsent>
    </CookieBannerStyles>
  );
};

export default CustomCookieBanner;
