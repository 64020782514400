import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { StepBar } from 'Components';
import colors from 'Constants/colors';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LabelsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
`;

const Step = styled.div`
  color: ${({ current, previous }) => (current || previous ? colors.nexusGreen : colors.nexusBlue)};
  font-weight: ${props => (props.current ? 600 : 400)};
`;

const StepBarContainer = styled.div`
  height: 1rem;
  margin-top: 2rem;
`;

const getLabelProgress = ref => {
  const element = ref.current;
  const elementWidth = element.clientWidth;
  const elementLeftOffset = element.offsetLeft;
  const labelElements = Array.from(element.children);
  const labelElementsDimensions = labelElements.map(x => ({
    width: x.clientWidth,
    left: x.offsetLeft,
  }));

  const stepsPercentages = labelElementsDimensions.map((x, i) => {
    if (i === 0) {
      return 0;
    }
    if (i === labelElements.length - 1) {
      return 100;
    }
    const total = elementWidth;
    const leftSide = x.left - elementLeftOffset;
    const halfElementWidth = x.width / 2;
    const percentage = Math.floor(((leftSide + halfElementWidth) / total) * 100);
    return percentage;
  });

  return stepsPercentages;
};

const Stepper = ({ routes, currentStep, search = '' }) => {
  const elRef = useRef(null);
  const steps = routes.map(x => x.title);
  const [barProgress, setBarProgress] = useState(Array(steps.length).fill(0));

  useEffect(() => {
    setBarProgress(getLabelProgress(elRef));
  }, [routes]);

  return (
    <>
      <StepBarContainer>
        <StepBar progress={barProgress[currentStep]} />
      </StepBarContainer>
      <LabelsContainer ref={elRef}>
        {routes.map((route, index) =>
          index < currentStep ? (
            <Link to={route.url + search} key={route.title}>
              <Step previous>{route.title}</Step>
            </Link>
          ) : (
            <Step key={route.title} current={index === currentStep}>
              {route.title}
            </Step>
          ),
        )}
      </LabelsContainer>
    </>
  );
};

Stepper.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ),
  currentStep: PropTypes.number,
  search: PropTypes.string,
};

export default Stepper;
