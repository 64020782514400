import React from 'react';

import GradientShimmer from 'Components/GradientShimmer';

import { Row } from './gridComponents';

type InfoRowProps = {
  left: string | number | JSX.Element;
  right: string | number | JSX.Element;
  loading?: boolean;
  bold?: boolean;
  'data-rh'?: string;
  'data-testid'?: string;
};

const InfoRow = ({ left, right, loading, bold, ...props }: InfoRowProps) =>
  loading ? (
    <GradientShimmer width="100%" height="1.5rem" margin="0.25rem 0 0.25rem 0" />
  ) : (
    <Row
      data-testid={props['data-testid']}
      justifyContent="space-between"
      align="center"
      bold={bold}
    >
      <div data-rh={!right ? props['data-rh'] : null}>{left}</div>
      <div data-rh={left ? props['data-rh'] : null}>{right}</div>
    </Row>
  );

export default InfoRow;
