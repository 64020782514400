import React from 'react';

import ExplorerLink from 'Components/ExplorerLink';

type EtherscanLinkProps = {
  address: string;
  children?: React.ReactNode;
  loading?: boolean;
  warning?: boolean;
  passive?: boolean;
  full?: boolean;
  length?: number;
};

const EtherscanLink = ({
  children,
  address,
  loading,
  warning,
  passive,
  full,
  length = 4,
}: EtherscanLinkProps) => (
  <ExplorerLink
    address={address}
    chain="ethereum"
    warning={warning}
    passive={passive}
    loading={loading}
    length={length}
    full={full}
  >
    {children}
  </ExplorerLink>
);

export default EtherscanLink;
