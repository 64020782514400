export const PRODUCTS_IDS = {
  '0X_V3': 0,
  '1INCH_(DEX_&_LIQUIDITY_POOLS)': 1,
  AAVE_V2: 2,
  ABRACADABRA: 3,
  ALCHEMIX_V2: 4,
  ANCHOR: 5,
  ANGLE: 6,
  ARGENT: 7,
  AURA: 8,
  BABYLON_FINANCE: 9,
  BADGERDAO: 10,
  BALANCER_V2: 11,
  BANCOR_V2: 12,
  BANCOR_V3: 13,
  BEEFY: 14,
  BINANCE: 15,
  BLOCKFI: 16,
  'BUNDLE:_GELT_+_MSTABLE_+_AAVE_V2': 17,
  CENTRIFUGE_TINLAKE: 18,
  COINBASE: 19,
  COMPOUND_V2: 20,
  'CONVEX_3CRV_(CVX3CRV)': 21,
  CONVEX_FINANCE_V1: 22,
  'CONVEX_STETHCRV_(CVXSTETHCRV)': 23,
  'CRYPTO.COM': 24,
  'CURVE_3POOL_LP_(3CRV)': 25,
  'CURVE_ALL_POOLS_(INCL_STAKING)': 26,
  'CURVE_SETH_LP_(ECRV)': 27,
  DYDX_PERPETUAL: 28,
  EASE: 29,
  ENZYME_V3: 30,
  ENZYME_V4: 31,
  'ETH_2.0_(DEPOSIT_CONTRACT)': 32,
  EULER: 33,
  FODL: 34,
  FTX: 35,
  GEARBOX_V2: 36,
  GEMINI: 37,
  GMX: 38,
  GOLDFINCH: 39,
  HODLNAUT: 40,
  KRAKEN: 41,
  LEDN: 42,
  LIQUID_COLLECTIVE: 43,
  LIQUITY: 44,
  MAKERDAO_MCD: 45,
  MSTABLE: 46,
  NESTED: 47,
  NEXO: 48,
  NOTIONAL_FINANCE_V2: 49,
  OLYMPUSDAO: 50,
  OPYN_V2: 51,
  ORIGIN_OUSD: 52,
  PANGOLIN: 53,
  PERPETUAL_PROTOCOL: 54,
  POOL_TOGETHER_V4: 55,
  PREMIA_FINANCE: 56,
  RARI_CAPITAL: 57,
  REFLEXER: 58,
  RIBBON_FINANCE_V2: 59,
  'SAFE_(FORMERLY,_GNOSIS_SAFE)': 60,
  SET_PROTOCOL: 61,
  SET_PROTOCOL_V2: 62,
  SHERLOCK: 63,
  STAKE_DAO: 64,
  'STAKEWISE_3RD_PARTY_(3_ETH_/_VALIDATOR)': 65,
  'STAKEWISE_OPERATED_(3_ETH_/_VALIDATOR)': 66,
  SUSHISWAP_V1: 67,
  SYNTHETIX: 68,
  'THORCHAIN_(THORCHAIN_NETWORK)': 69,
  TRADER_JOE: 70,
  UNISWAP_V2: 71,
  UNISWAP_V3: 72,
  VECTOR: 73,
  VESPER: 74,
  'YEARN_FINANCE_(ALL_VAULTS)': 75,
  YEARN_YVUSDC_V2: 76,
  YETI_FINANCE: 77,
  'YIELD.APP': 78,
};
