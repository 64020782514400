import { immediateToast } from 'izitoast-react';
import React from 'react';

import Copy from 'Assets/copy.svg';
import Button from 'Components/Button';
import toastOptions from 'Constants/toastOptions';

type CopyButtonProps = {
  content: string;
  children?: React.ReactNode;
  width?: string;
};

const CopyButton = ({ content, children, width }: CopyButtonProps) => {
  const copy = async () => {
    await navigator.clipboard.writeText(content);
    immediateToast('success', {
      message: `Copied to clipboard`,
      ...toastOptions,
    });
  };

  return (
    <Button small onClick={copy} icon={<Copy />} width={width || '6rem'} fixedWidth>
      {children || 'Copy'}
    </Button>
  );
};

export default CopyButton;
