import colors from 'Constants/colors';
import styled from 'styled-components';

export const InfoText = styled.div<{ error?: boolean; warning?: boolean }>`
  font-size: 0.8rem;
  line-height: 1rem;
  margin-top: 0.5rem;
  padding-left: ${({ error, warning }) => (error || warning ? '0.5rem' : '0')};
  color: ${({ error, warning }) =>
    (error && colors.redTart) || (warning && colors.yellowButter) || colors.greyStone};
`;
