import React, { ReactElement } from 'react';

import { RadioOption, RadioOptionProps } from './components';
import { transformChildren } from './utils/transformChildren';

export type RadioGroupProps = {
  options?: string[];
  selected: string;
  children?: ReactElement<RadioOptionProps> | ReactElement<RadioOptionProps>[];
  onChange?: (selected: string) => void;
};

export const RadioGroup = ({
  options = [],
  children = [],
  selected,
  onChange,
}: RadioGroupProps) => {
  // If direct children are used we need to recursively go through all children and pick out the
  // elements which are RadioOption's. Then we transform them so they use the suppled `onChange`
  // callback etc.
  if (children) {
    return <div>{transformChildren(children, selected, onChange)}</div>;
  }

  // If the `options` prop is used we create a list of components and return those.
  return (
    <div>
      {options.map(option => (
        <RadioOption
          key={option}
          value={option}
          label={option}
          checked={selected === option}
          onClick={() => onChange?.(option)}
        />
      ))}
    </div>
  );
};
