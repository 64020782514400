import { getAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';
import { Zero } from '@ethersproject/constants';
import { parseEther, parseUnits } from '@ethersproject/units';
import { CHAIN_ID } from 'Config';
import { getYearlyCost } from 'Constants/formulas';
import {
  createReducer,
  createSagaRoutineReducer,
  displayFormat,
  formatUnitsDisplay,
} from 'Services/utils';

import initialState from './initialState';
import t from './types';

const isMember = createSagaRoutineReducer(
  t.IS_MEMBER,
  'isMemberLoading',
  'isMemberError',
  ['isMember'],
  initialState,
);

const loadEthToUsdRate = createSagaRoutineReducer(
  t.LOAD_ETH_TO_USD_RATE,
  'ethUsdRateLoading',
  'ethUsdRateError',
  ['ethUsdRate'],
  initialState,
);

const loadEthToDaiRate = createSagaRoutineReducer(
  t.LOAD_ETH_TO_DAI_RATE,
  'ethDaiRateLoading',
  'ethDaiRateError',
  ['ethDaiRate'],
);

const loadNxmToEthRate = createSagaRoutineReducer(
  t.LOAD_NXM_TO_ETH_RATE,
  'nxmEthRateLoading',
  'nxmEthRateError',
  ['nxmEthRate'],
  initialState,
);

const loadNXMBalance = createSagaRoutineReducer(
  t.LOAD_NXM_BALANCE,
  'balanceNXMLoading',
  'balanceNXMError',
  ['balanceNXM'],
  initialState,
);

const loadwNXMBalance = createSagaRoutineReducer(
  t.LOAD_WNXM_BALANCE,
  'balancewNXMLoading',
  'balancewNXMError',
  ['balancewNXM'],
  initialState,
);

const loadETHBalance = createSagaRoutineReducer(
  t.LOAD_ETH_BALANCE,
  'balanceETHLoading',
  'balanceETHError',
  ['balanceETH'],
  initialState,
);

const loadNXMTotalBalance = createSagaRoutineReducer(
  t.LOAD_NXM_TOTAL_BALANCE,
  'totalBalanceNXMLoading',
  'totalBalanceNXMError',
  ['totalBalanceNXM'],
  initialState,
);

const isLockedForMV = createSagaRoutineReducer(
  t.IS_LOCKED_FOR_MV,
  'isLockedForMVLoading',
  'isLockedForMVError',
  ['isLockedForMV'],
  initialState,
);

const loadNXMAllowance = createSagaRoutineReducer(
  t.LOAD_NXM_ALLOWANCE,
  'nxmAllowanceLoading',
  'loadNxmAllowanceError',
  ['nxmAllowance'],
  initialState,
);

const setNXMAllowance = createSagaRoutineReducer(
  t.SEND_SET_NXM_ALLOWANCE_TX,
  ['setNxmAllowanceWaiting', 'setNxmAllowanceLoading'],
  'setNxmAllowanceError',
  ['nxmAllowance'],
  initialState,
);

const loadProductCapacities = createSagaRoutineReducer(
  t.LOAD_PRODUCT_CAPACITIES,
  'productCapacitiesLoading',
  'productCapacitiesError',
  ['productCapacities'],
  initialState,
);

const loadMemberRoles = createSagaRoutineReducer(
  t.LOAD_MEMBER_ROLES,
  'memberRolesLoading',
  'memberRolesError',
  ['memberRoles'],
  initialState,
);

const loadTotalSupply = createSagaRoutineReducer(
  t.LOAD_TOTAL_SUPPLY,
  'totalSupplyLoading',
  'loadTotalSupplyError',
  ['totalSupply'],
  initialState,
);

const loadWnxmToEthRate = createSagaRoutineReducer(
  t.LOAD_WNXM_RATE,
  'wnxmToEthRateLoading',
  'wnxmToEthRateError',
  ['wnxmToEthRate'],
  initialState,
);

const loadProducts = createSagaRoutineReducer(
  t.LOAD_PRODUCTS,
  'productsLoading',
  'productsError',
  ['products'],
  initialState,
);

const loadDaiBalance = createSagaRoutineReducer(
  t.LOAD_DAI_BALANCE,
  'balanceDAILoading',
  'balanceDAIError',
  ['balanceDAI'],
  initialState,
);

const setDaiAllowance = createSagaRoutineReducer(
  t.SEND_SET_DAI_ALLOWANCE_TX,
  ['setDaiAllowanceWaiting', 'setDaiAllowanceLoading'],
  'setDaiAllowanceError',
  ['daiAllowance'],
  initialState,
);

const loadDaiAllowance = createSagaRoutineReducer(
  t.LOAD_DAI_ALLOWANCE,
  'daiAllowanceLoading',
  'daiAllowanceError',
  ['daiAllowance'],
  initialState,
);

const reducer = createReducer(
  {
    ...loadwNXMBalance,
    ...loadNXMBalance,
    ...loadNXMTotalBalance,
    ...loadETHBalance,
    ...loadNxmToEthRate,
    ...loadEthToUsdRate,
    ...loadEthToDaiRate,
    ...isMember,
    ...isLockedForMV,
    ...loadNXMAllowance,
    ...setNXMAllowance,
    ...loadProductCapacities,
    ...loadMemberRoles,
    ...loadTotalSupply,
    ...loadWnxmToEthRate,
    ...loadProducts,
    ...loadDaiBalance,
    ...loadDaiAllowance,
    ...setDaiAllowance,

    [t.CHANGE_IS_MEMBER_VALUE]: ({ isMember }) => ({ isMember }),

    // Resets all states that are account sensitive and sets loading states
    // which are intiially true to false
    [t.NO_ACCOUNT_STATE_INIT]: () => {
      /* eslint-disable no-unused-vars */
      const {
        txHistory,
        chainId,
        ethUsdRate,
        ethUsdRateLoading,
        ethUsdRateError,
        isUserMenuOpen,
        isNavMenuOpen,
        productCapacitiesLoading,
        productCapacitiesError,
        productCapacities,
        products,
        provider,
        wallet,
        ...resetStates
      } = initialState;
      /* eslint-enable no-unused-vars */

      return {
        ...resetStates,
        isMemberLoading: false,
      };
    },

    [t.LOAD_PRODUCT_CAPACITIES.SUCCEEDED]: ({ productCapacities }) => ({
      productCapacitiesLoading: false,
      productCapacitiesError: '',
      productCapacities: productCapacities.reduce((acc, { productId, ...x }) => {
        acc[productId] = {
          capacityETH: formatUnitsDisplay(x.capacityETH).split('.')[0],
          capacityDAI: formatUnitsDisplay(x.capacityDAI).split('.')[0],
          allocatedNxm: formatUnitsDisplay(x.allocatedNxm).split('.')[0],
        };
        return acc;
      }, {}),
    }),

    [t.SET_CHAIN_ID]: ({ chainId }) => ({
      chainId: chainId ? Number(chainId) : CHAIN_ID,
    }),

    [t.LOAD_ETH_TO_USD_RATE.SUCCEEDED]: ({ decimals, latestAnswer }) => ({
      ethUsdRate: latestAnswer,
      ethUsdRateDecimals: decimals,
      ethUsdRateLoading: false,
    }),

    [t.LOAD_ETH_TO_DAI_RATE.SUCCEEDED]: ({ decimals, latestAnswer }) => ({
      ethDaiRate: latestAnswer,
      ethDaiRateDecimals: decimals,
      ethDaiRateLoading: false,
    }),

    [t.TOGGLE_USER_MENU]: ({ state }) => ({
      isUserMenuOpen: !state.isUserMenuOpen,
      isNavMenuOpen: false,
    }),

    [t.OPEN_USER_MENU]: () => ({ isUserMenuOpen: true, isNavMenuOpen: false }),

    [t.CLOSE_USER_MENU]: () => ({ isUserMenuOpen: false }),

    [t.TOGGLE_NAV_MENU]: ({ state }) => ({
      isNavMenuOpen: !state.isNavMenuOpen,
      isUserMenuOpen: false,
    }),

    [t.OPEN_NAV_MENU]: () => ({ isNavMenuOpen: true, isUserMenuOpen: false }),

    [t.CLOSE_NAV_MENU]: () => ({ isNavMenuOpen: false }),

    [t.ADD_PENDING_TX]: ({ tx, message = '', state }) => {
      return {
        txHistory: [
          {
            nonce: tx.nonce,
            from: tx.from,
            hash: tx.hash,
            message,
            status: 'PENDING',
          },
          ...state.txHistory,
        ],
      };
    },

    [t.CLOSE_ALL_MENUS]: () => ({
      isNavMenuOpen: false,
      isUserMenuOpen: false,
    }),

    [t.RESTORE_TX_HISTORY_VALUE]: ({ txHistoryToRestore }) => {
      const txHistory = JSON.parse(txHistoryToRestore);
      return { txHistory };
    },

    [t.SET_ACCOUNT]: ({ account }) => ({
      account: account ? getAddress(account) : '',
    }),

    [t.SET_WALLET]: ({ wallet }) => ({ wallet }),

    [t.SET_PROVIDER]: ({ provider }) => ({ provider }),

    [t.SET_ETH_BALANCE]: ({ balanceETH }) => ({
      balanceETH: balanceETH ? parseUnits(balanceETH, 0) : Zero,
    }),

    [t.UPDATE_TX_HISTORY]: ({ receipt, txHistoryItem, state }) => {
      const status = receipt.status ? 'CONFIRMED' : 'REVERTED';
      return {
        txHistory: state.txHistory.map(x =>
          x.nonce === txHistoryItem.nonce && (x.from === receipt.from || !x.from)
            ? { ...x, status }
            : x,
        ),
      };
    },

    [t.LOAD_WNXM_RATE.SUCCEEDED]: ({ res }) => ({
      wnxmToEthRate: BigNumber.from(res.amount),
      wnxmToEthRateError: '',
      wnxmToEthRateLoading: false,
    }),
  },

  initialState,
);

export default reducer;
