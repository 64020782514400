import React from 'react';

import DiscordLogo from 'Assets/discord.svg';
import Config from 'Config';
import colors from 'Constants/colors';
import styled from 'styled-components';

const AnchorContainer = styled.a`
  &:hover {
    transform: scale(1.1);
  }
  transition: transform 0.2s ease;
  & > svg {
    fill: ${colors.white};
    transition: fill 0.2s ease;
  }
  background: ${colors.nexusGreen};
  position: fixed;
  display: flex;
  right: 15px;
  bottom: 15px;
  border-radius: 3rem;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ChatButton = () => (
  <AnchorContainer href={Config.CHAT_URL} target="_blank">
    <DiscordLogo width={45} />
  </AnchorContainer>
);

export default ChatButton;
