import React from 'react';

import { Button, Card } from 'Components';
import GradientShimmer from 'Components/GradientShimmer';
import { Row } from 'Components/gridComponents';
import ProductDetailsWrapper from 'Components/ProductDetailsWrapper';
import colors from 'Constants/colors';
import { Product } from 'Features/Membership/ducks/covers/initialState';
import styled from 'styled-components';

const Container = styled.div`
  font-weight: 600;
`;

const InfoValue = styled.div<{ danger?: boolean }>`
  height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ danger }) => (danger && colors.redTart) || colors.greyAsher};
`;

const CornerLabel = styled.div`
  background: ${({ color }) => color};
  height: 2rem;
  padding: 0 2rem;
  border-radius: 0 1rem;
  line-height: 2rem;
  font-weight: 600;
  right: 0rem;
  top: 0rem;
  position: absolute;
  text-align: center;
  color: ${colors.white};
`;

type PromptProps = {
  message?: string;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
  loading?: boolean;
};

const PromptColor = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ success, warning, danger }: Partial<PromptProps>) =>
    (success && colors.greenDark) ||
    (warning && colors.yellowButter) ||
    (danger && colors.redTart)};
`;

const PromptContainer = styled.div`
  height: 2rem;
`;

const Prompt = ({ message, ...props }: PromptProps) => {
  if (!message) {
    return <PromptContainer />;
  }
  return (
    <PromptContainer>
      <Row>
        <PromptColor {...props}>{message}</PromptColor>
      </Row>
    </PromptContainer>
  );
};

const NoWrapRow = styled(Row)`
  flex-wrap: nowrap;
`;

type ProductCardProps = {
  product: Product;
  isNew?: boolean;
  info?: Array<{
    value: any;
    title?: string;
    danger?: boolean;
    warning?: boolean;
  }>;
  isInfoLoading?: boolean;
  primaryButton?: {
    text: string;
    onClick: React.MouseEventHandler;
    disabled?: boolean;
    tooltip?: string;
  };
  secondaryButton?: {
    text: string;
    onClick: React.MouseEventHandler;
    disabled?: boolean;
    tooltip?: string;
  };

  loading?: boolean;
  hasIncentive?: boolean;
  hasMaxCapacity?: boolean;
  shouldAdjustToSidebar?: boolean;
};

const ProductCard = (props: ProductCardProps) => {
  const {
    product,
    isNew,
    hasIncentive,
    hasMaxCapacity,
    info,
    primaryButton,
    secondaryButton,
    isInfoLoading,
    loading,
    shouldAdjustToSidebar,
  } = props;

  const getPromptMessage = () => {
    if (hasMaxCapacity) {
      return 'Maximum stake capacity reached!';
    }
    return hasIncentive ? `Earn ${hasIncentive} weekly!` : '';
  };

  return (
    <div
      className={
        shouldAdjustToSidebar
          ? 'col-xs-12 col-sm-12 col-md-6 col-lg-4'
          : 'col-xs-12 col-sm-6 col-md-4 col-lg-3'
      }
    >
      <Container>
        <Card>
          {loading
            ? null
            : (hasIncentive && <CornerLabel color={colors.greenDark}>INCENTIVIZED</CornerLabel>) ||
              (isNew && <CornerLabel color={colors.redTart}>NEW</CornerLabel>)}
          <ProductDetailsWrapper product={product} size="md" loading={loading} />
          {'incentive' in props || 'maxCapacity' in props ? (
            <Prompt
              danger={hasMaxCapacity}
              loading={loading}
              success={hasIncentive && !hasMaxCapacity}
              message={getPromptMessage()}
            />
          ) : null}
          {info
            ? info.map(({ title, value, ...props }) => (
                <NoWrapRow justifyContent="space-between" key={title || value}>
                  {isInfoLoading || loading ? (
                    <GradientShimmer height="1.5rem" margin="0 0 0.5rem 0" />
                  ) : (
                    <>
                      {title ? <InfoValue {...props}>{title}:</InfoValue> : null}
                      {value ? <InfoValue {...props}>{value}</InfoValue> : null}
                    </>
                  )}
                </NoWrapRow>
              ))
            : null}
          <Row margin="1rem 0 0 0">
            {secondaryButton ? (
              <Button
                width="50%"
                disabled={secondaryButton.disabled}
                onClick={secondaryButton.onClick}
              >
                {secondaryButton.text}
              </Button>
            ) : null}
            {primaryButton ? (
              <Button
                width={secondaryButton ? '50%' : '100%'}
                disabled={primaryButton.disabled}
                data-rh={primaryButton.tooltip}
                primary={!!secondaryButton}
                onClick={primaryButton.onClick}
              >
                {primaryButton.text}
              </Button>
            ) : null}
          </Row>
        </Card>
      </Container>
    </div>
  );
};

export default ProductCard;
