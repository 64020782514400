import { useConnectWallet } from '@web3-onboard/react';
import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAddress } from '@ethersproject/address';
import { JsonRpcProvider, Web3Provider, WebSocketProvider } from '@ethersproject/providers';
import { CHAIN_ID, NETWORK_PROVIDER_WS_URL } from 'Config';
import { DEV_ADDRESS, DEV_CHAIN_ID, DEV_RPC_URL } from 'Constants/devSetup';
import PropTypes from 'prop-types';
import { initializeConnectionProvider } from 'Services/connectionProvider';
import actions from 'Services/rootDuck/actions';
import { selectAccount, selectChainId, selectProvider } from 'Services/rootDuck/selectors';

export const AppLifecycleContext = createContext({});
initializeConnectionProvider();

const AppLifecycle = ({ children }) => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const provider = useSelector(selectProvider);
  const chainId = useSelector(selectChainId);
  const setWallet = wallet => dispatch(actions.setWallet(wallet));
  const setAccount = account => dispatch(actions.setAccount(account));
  const setProvider = provider => dispatch(actions.setProvider(provider));
  const setChainId = chainId => dispatch(actions.setChainId(chainId));
  const [{ wallet }, connect, disconnect] = useConnectWallet();

  const initializeNetworkProvider = () => {
    const newProvider = new WebSocketProvider(
      NETWORK_PROVIDER_WS_URL + (process.env.REACT_APP_ALCHEMY_API_KEY || ''),
      CHAIN_ID,
    );
    setProvider(newProvider);
    setAccount('');
    setWallet(null);
  };

  const initializeWallet = () => {
    if (wallet) {
      const newProvider = DEV_RPC_URL
        ? new JsonRpcProvider(DEV_RPC_URL)
        : new Web3Provider(wallet.provider, 'any');
      const address = DEV_ADDRESS || wallet.accounts[0]?.address;
      const chainId = DEV_CHAIN_ID || wallet?.chains[0]?.id;

      setWallet(wallet);
      setAccount(getAddress(address) || '');
      setChainId(chainId);

      setProvider(newProvider);
      window.localStorage.setItem('connectedWallets', wallet.label);
    } else {
      initializeNetworkProvider();
    }
  };

  const reconnectWallet = () => {
    const loadedInIframe = window.self !== window.top;
    const previouslyConnectedWallet = loadedInIframe
      ? 'Gnosis Safe'
      : window.localStorage.getItem('connectedWallets');

    const connectWallet = async () => {
      if (previouslyConnectedWallet) {
        await connect({
          autoSelect: {
            label: previouslyConnectedWallet,
            disableModals: true,
          },
        });
      } else {
        initializeNetworkProvider();
      }
    };
    connectWallet();
  };

  const trackBalance = () => {
    const loadBalance = () => {
      dispatch(actions.loadETHBalance());
      dispatch(actions.loadNXMBalance());
      dispatch(actions.loadwNXMBalance());
    };
    loadBalance();
    const id = setInterval(loadBalance, 15000);
    return () => clearInterval(id);
  };

  useEffect(initializeWallet, [wallet]);

  useEffect(trackBalance, [account]);

  useEffect(() => {
    dispatch(actions.loadWnxmToEthRate());
  }, []);

  useEffect(reconnectWallet, []);

  useEffect(() => {
    if (provider) {
      dispatch(actions.restoreTxHistory());
    }
  }, [provider]);

  useEffect(() => {
    if (provider) {
      dispatch(actions.loadNxmToEthRate());
      dispatch(actions.loadEthToUsdRate());
      if (account) {
        dispatch(actions.isMember());
        dispatch(actions.isLockedForMV());
        dispatch(actions.loadNXMBalance());
        dispatch(actions.loadwNXMBalance());
        dispatch(actions.listenForTransferEvents());
        dispatch(actions.loadNXMAllowance());
      } else {
        dispatch(actions.noAccountStateInit());
      }
    }
  }, [account, provider, chainId]);

  const connectWallet = async () => {
    // Open wallet modal
    const selectedWallet = await connect();

    // User quit modal
    if (!selectedWallet) {
      /* empty */
    }
  };

  const disconnectWallet = async () => {
    disconnect(wallet);
    setAccount('');
    setWallet(null);
    window.localStorage.removeItem('selectedWallet');
  };

  return (
    <AppLifecycleContext.Provider
      value={{
        connectWallet: connectWallet,
        disconnectWallet: disconnectWallet,
      }}
    >
      {children}
    </AppLifecycleContext.Provider>
  );
};

AppLifecycle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default AppLifecycle;
