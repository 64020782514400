import colors from 'Constants/colors';
import styled from 'styled-components';

const getColors = (type?: 'success' | 'warning' | 'danger' | 'passive') => {
  const types = {
    success: [colors.greenDark, colors.greenSeafoam],
    warning: [colors.brown, colors.yellowDaffodil],
    danger: [colors.redTart, colors.redRose],
    passive: [colors.greyAsher, colors.greyPearl],
  };
  return type ? types[type] : [colors.greyAsher, colors.greyPearl];
};

const Pill = styled.div<{ type?: 'success' | 'warning' | 'danger' | 'passive' }>`
  color: ${props => getColors(props.type)?.[0] || colors.greyAsher};
  background: ${props => getColors(props.type)?.[1] || colors.greyPearl};
  border-radius: 1rem;
  height: 2rem;
  line-height: 2rem;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 10px 0 10px;
`;

export default Pill;
