import { CHAIN_ID } from 'Config';

interface ChainElementType {
  name: string;
  logo: string;
  explorer?: string;
}

// helper function which infers keys and restricts values to ChainElementType
const asChainElementTypes = <T>(cet: { [K in keyof T]: ChainElementType }) => cet;

const chains = asChainElementTypes({
  ethereum: {
    name: 'Ethereum',
    logo: 'eth.svg',
    explorer: CHAIN_ID === 42 ? 'https://kovan.etherscan.io/' : 'https://etherscan.io/',
  },
  arbitrum: {
    name: 'Arbitrum',
    logo: 'arbitrum.svg',
    explorer:
      CHAIN_ID === 42 ? 'https://rinkeby-explorer.arbitrum.io/' : 'https://explorer.arbitrum.io/',
  },
  avalanche: {
    name: 'Avalanche',
    logo: 'avalanche.svg',
    explorer: CHAIN_ID === 42 ? 'https://explorer.avax-test.network/' : 'https://snowtrace.io/',
  },
  bsc: {
    name: 'Binance Smart Chain',
    logo: 'binance.svg',
    explorer: 'https://www.bscscan.com/',
  },
  celo: {
    name: 'Celo',
    logo: 'celo.svg',
    explorer: 'https://explorer.celo.org/',
  },
  solana: { name: 'Solana', logo: 'solana.svg' },
  fantom: {
    name: 'Fantom',
    logo: 'fantom.svg',
    explorer: 'https://ftmscan.com/',
  },
  polygon: {
    name: 'Polygon',
    logo: 'polygon.svg',
    explorer: 'https://polygonscan.com/',
  },
  optimism: {
    name: 'Optimism',
    logo: 'optimism.svg',
    explorer: 'https://optimistic.etherscan.io/',
  },
  xdai: {
    name: 'xDAI',
    logo: 'xdai.png',
    explorer: 'https://blockscout.com/xdai/mainnet/',
  },
  starkware: { name: 'Starkware', logo: 'starkware.svg' },
  polkadot: { name: 'Polkadot', logo: 'polkadot.svg' },
  terra: {
    name: 'Terra',
    logo: 'terra-chain.svg',
    explorer:
      CHAIN_ID === 42
        ? 'https://finder.terra.money/tequila-0004/'
        : 'https://finder.terra.money/columbus-4/',
  },
  thorchain: { name: 'Thorchain', logo: 'thorchain.svg' },
  harmony: {
    name: 'Harmony',
    logo: 'harmony.svg',
    explorer: 'https://explorer.harmony.one/',
  },
  zksync: {
    name: 'zkSync',
    logo: 'zksync.svg',
    explorer: 'https://zkscan.io/explorer/',
  },
  heco: {
    name: 'Heco',
    logo: 'heco.svg',
    explorer: 'https://hecoinfo.com/',
  },
  moonriver: {
    name: 'Moonriver',
    logo: 'moonriver.svg',
    explorer: 'https://moonriver.moonscan.io/',
  },
  cronos: {
    name: 'Cronos',
    logo: 'cronos.svg',
    explorer: 'https://cronoscan.com/',
  },
  fuse: {
    name: 'Fuse',
    logo: 'fuse.svg',
    explorer: 'https://explorer.fuse.io/',
  },
  metis: {
    name: 'Metis',
    logo: 'metis.svg',
    explorer: 'https://andromeda-explorer.metis.io/',
  },
  aurora: {
    name: 'Aurora',
    logo: 'aurora.svg',
    explorer: 'https://aurorascan.dev/',
  },
  moonbeam: {
    name: 'Moonbeam',
    logo: 'moonbeam.svg',
    explorer: 'https://moonscan.io/',
  },
  base: {
    name: 'Base',
    logo: 'base.png',
    explorer: 'https://basescan.org/',
  },
  blast: {
    name: 'Blast',
    logo: 'blast.png',
    explorer: 'https://blastscan.io/',
  },
  linea: {
    name: 'Linea',
    logo: 'linea.svg',
    explorer: 'https://lineascan.build/',
  },
  mode: {
    name: 'Mode',
    logo: 'mode.png',
    explorer: 'https://modescan.io/',
  },
  mantle: {
    name: 'Mantle',
    logo: 'mantle.svg',
    explorer: 'https://explorer.mantle.xyz/',
  },
  scroll: {
    name: 'Scroll',
    logo: 'scroll.svg',
    explorer: 'https://scrollscan.com/',
  },
  gnosis: {
    name: 'Gnosis',
    logo: 'gnosis.svg',
    explorer: 'https://gnosisscan.io/',
  },
});

export const EVMCompatibleChains = [
  'ethereum',
  'xdai',
  'polygon',
  'bsc',
  'fantom',
  'optimism',
  'arbitrum',
  'harmony',
  'avalanche',
  'heco',
  'moonriver',
  'cronos',
  'fuse',
  'metis',
  'aurora',
  'moonbeam',
  'base',
  'blast',
  'linea',
  'mode',
  'mantle',
  'scroll',
  'gnosis',
];

export default chains;
