import React from 'react';
import { Link } from 'react-router-dom';

import { BreadcrumbStyles } from './Breadcrumb.style';

export type BreadcrumbItem = {
  text: string;
  route?: string;
};

export type BreadcrumbProps = {
  items: BreadcrumbItem[];
};

export const Breadcrumb = ({ items }: BreadcrumbProps) => {
  return (
    <BreadcrumbStyles>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item.route ? (
              <Link className="link" to={item.route}>
                {item.text}
              </Link>
            ) : (
              <span>{item.text}</span>
            )}
          </li>
        ))}
      </ul>
    </BreadcrumbStyles>
  );
};
