const captureExceptionsMiddleware = _ => next => action => {
  if (/.*_FAILED$/.test(action.type)) {
    const { error } = action.payload;
    if (error.code === 4001) {
      // MetaMask Tx Signature: User denied transaction signature.
      next({
        type: action.type.replace('_FAILED', '_CANCELLED'),
        payload: action.payload,
      });
      return;
    }
  }
  next(action);
};

export default captureExceptionsMiddleware;
