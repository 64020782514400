import { Row } from 'Components/gridComponents';
import colors from 'Constants/colors';
import styled from 'styled-components';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 0.9rem;
  color: ${colors.greyStone};
`;

export const LabelRow = styled(Row)`
  padding-right: 4rem;
  box-sizing: border-box;
`;

export const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
`;

export const GreyText = styled.span`
  color: ${colors.greyStone};
`;

export const MarginRow = styled.div`
  margin-bottom: 2rem;
`;
