import React from 'react';

import colors from 'Constants/colors';
import styled from 'styled-components';

const GradientShimmerContainer = styled.div`
  width: 100%;
`;

const GradientShimmerContent = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  margin: ${({ margin }) => margin || 'initial'};
  background: ${({ dark }) => (dark ? colors.greySterling : colors.greyPearl)};
  animation-name: shimmer;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: 4px;

  @keyframes shimmer {
    0% {
      opacity: 100%;
    }
    50% {
      opacity: 50%;
    }
    100% {
      opacity: 100%;
    }
  }
`;

export const GradientShimmer = ({ ...props }) => (
  <GradientShimmerContainer {...props}>
    <GradientShimmerContent {...props} />
  </GradientShimmerContainer>
);

export default GradientShimmer;
