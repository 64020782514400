import colors from 'Constants/colors';
import styled from 'styled-components';

// TODO change css props passed as strings
type ColumnProps = {
  overflow?: string;
  align?: string;
  height?: string;
  minHeight?: string;
  width?: string;
  maxWidth?: string;
  padding?: string;
  fontSize?: string | number;
  bold?: boolean;
  justifyContent?: string;
  spaced?: boolean;
  spacedBy?: boolean;
};

export const Column = styled.div<ColumnProps>`
  display: flex;
  overflow: ${({ overflow }) => overflow || 'initial'};
  flex-direction: column;
  align-items: ${({ align }) => align || 'initial'};
  height: ${({ height }) => height || 'initial'};
  width: ${({ width }) => width || 'initial'};
  max-width: ${({ maxWidth }) => maxWidth || 'initial'};
  min-height: ${({ minHeight }) => minHeight || 'initial'};
  padding: ${({ padding }) => padding || 'initial'};
  font-size: ${({ fontSize }) => fontSize || 'auto'};
  font-weight: ${({ bold }) => (bold ? '600' : 'auto')};
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  ${({ spacedBy }) =>
    spacedBy
      ? `
        & > *:not(:first-child):not(last-child) {
          margin-top: ${spacedBy};
        }
    `
      : ''};
  ${({ spaced }) =>
    spaced
      ? `
        & > *:not(:first-child):not(last-child) {
          margin-top: 1rem;
        }
    `
      : ''};
  flex-grow: 1;
`;

// [todo] Remove this
export const RowWithSpacing = styled.div`
  display: flex;
  & > *:not(:first-child):not(last-child) {
    margin-left: 1rem;
  }
`;

type RowProps = {
  align?: string;
  height?: string;
  minHeight?: string;
  width?: string;
  margin?: string;
  fontSize?: string | number;
  bold?: boolean;
  justifyContent?: string;
  spaced?: boolean;
  grow?: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: ${({ align }) => align || 'initial'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'initial'};
  margin: ${({ margin }) => margin || 'initial'};
  font-size: ${({ fontSize }) => fontSize || 'initial'};
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  color: ${colors.greyStone};
  min-height: ${({ minHeight }) => minHeight || 'initial'};
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  ${({ grow }) => (grow ? ' flex-grow: 1;' : '')}
  ${({ spaced }) =>
    spaced
      ? `
        & > *:not(:first-child):not(last-child) {
          margin-left: 1rem;
        }
    `
      : ''};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
