const colors = {
  // Neutral colors
  white: '#ffffffff',
  whitePale: '#fcfdfdff',
  greyLilia: '#F7F7F7',
  greyPearl: '#E5E5E5',
  greySterling: '#AFAFAF',
  greyStone: '#777777',
  greyAsher: '#4B4B4B',
  black: '#000000',
  blue200: '#f2f6ff',
  blue500: '#0a2231',

  // Primary colors
  nexusBlue: '#002332',
  nexusGreen: '#0AB682',
  greenDark: '#055037',
  greenSeaweed: '#00875F', // secondary
  greenSeafoam: '#46EBB4',
  greenTea: '#A5F5D7', // secondary
  greenMint: '#EBFFF5',

  // Secondary colors
  redTart: '#FA5050',
  redRose: '#FFD4D2',
  yellowButter: '#FFD200',
  yellowDaffodil: '#FFFAAA',
  yellowDark: '#EEB317',
  blueCobalt: '#3273DC',
  blueArctic: '#AFEBFA',
  purpleOrchid: '#9145FF',
  purpleLavender: '#CDB9F5',

  // Other
  transparent: '#00000000',
  redDark: '#a0021bff',
  brownLight: '#ccbea0',
  brown: '#7a4d06',

  boxShadowFirst: '#c8d2e1b2',
  boxShadowSecond: '#c8d2e1ff',
};

export default colors;
