import React from 'react';

import ExternalLink from 'Components/ExternalLink';
import coverableChains from 'Constants/chains';
import { shortenAddress } from 'Services/utils';

type ExplorerLinkProps = {
  chain: keyof typeof coverableChains;
  children?: React.ReactNode;
  address?: string;
  loading?: boolean;
  warning?: boolean;
  passive?: boolean;
  full?: boolean;
  length?: number;
  isTx?: boolean;
};

const ExplorerLink = ({
  chain,
  children,
  address,
  loading,
  warning,
  passive,
  full,
  length = 4,
  isTx = false,
}: ExplorerLinkProps) => {
  // handle case when address is a tx link
  const isAddress = !isTx || (isTx && address?.includes('address/'));
  const addressOrTx = isAddress ? 'address/' : 'tx/';
  const sanitisedAddress = isAddress
    ? address?.replace(coverableChains[chain].explorer + 'address/', '')
    : address?.replace(coverableChains[chain].explorer + 'tx/', '');
  return (
    <ExternalLink
      href={coverableChains[chain].explorer + addressOrTx + sanitisedAddress}
      warning={warning}
      passive={passive}
      loading={loading}
    >
      {children || (full ? sanitisedAddress : shortenAddress(sanitisedAddress, length, length))}
    </ExternalLink>
  );
};

export default ExplorerLink;
