import React from 'react';

import ProductLogo from 'Components/ProductLogo';
import coverableChains from 'Constants/chains';
import styled from 'styled-components';

const Container = styled.li`
  display: inline-block;
  padding-right: 0.5rem;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;

const NameContainer = styled.div`
  margin-left: 0.5rem;
`;

const NoPaddingUl = styled.ul`
  padding: 0;
`;

export const ChainIcon = ({ chain }: { chain: ChainType }) => (
  <ProductLogo size="1.5rem" src={coverableChains[chain].logo} name={coverableChains[chain].name} />
);

export type ChainType = keyof typeof coverableChains;

export type ChainListProps = {
  supportedChains: Array<ChainType>;
  compact?: boolean;
};

const ChainList = ({ supportedChains, compact }: ChainListProps) => {
  const chains = supportedChains || [];
  if (!chains.length) {
    return null;
  }
  return (
    <>
      {compact ? null : <>Supported chains:</>}
      <NoPaddingUl>
        {chains.map((chain, i: number) => {
          return (
            <Container key={i}>
              <InnerContainer>
                <ChainIcon chain={chain} />{' '}
                {compact ? null : (
                  <>
                    <NameContainer>{coverableChains[chain].name}</NameContainer>
                    {i !== chains.length - 1 ? ',' : ''}
                  </>
                )}
              </InnerContainer>
            </Container>
          );
        })}
      </NoPaddingUl>
    </>
  );
};

export default ChainList;
