import React from 'react';
import { useSelector } from 'react-redux';

import type { BigNumber } from '@ethersproject/bignumber';
import { MaxUint256 } from '@ethersproject/constants';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { addresses as CONTRACTS_ADDRESSES } from '@nexusmutual/sdk';
import A from 'Components/A';
import Button from 'Components/Button';
import ButtonGroup from 'Components/ButtonGroup';
import InfinitySign from 'Components/InfinitySign';
import Message from 'Components/Message';
import { ETHERSCAN_URL } from 'Config';
import { selectAccount } from 'Services/rootDuck/selectors';
import { formatUnitsDisplay, separatorsFormat } from 'Services/utils';
import styled from 'styled-components';

const ContractLink = ({ name }: { name: keyof typeof CONTRACTS_ADDRESSES }) => (
  <A target="_blank" href={ETHERSCAN_URL + '/address/' + CONTRACTS_ADDRESSES[name]}>
    {name}
  </A>
);

const Container = styled.span`
  line-height: 2rem;
`;

const Inline = styled.div`
  display: inline-block;
  padding: 0 0.5rem;
`;

const InlineBlock = styled.div`
  display: inline-block;
`;

const InfinitySignWrapper = styled.div`
  & > * {
    margin: 0.05rem 0;
  }
`;

const DISPLAY_AS_INFINITY_THRESHOLD = parseUnits('999999');

const renderAmount = ({
  granted,
  grantedAllowance,
  requiredAllowance,
  token,
  loading,
}: Omit<AllowanceProps, 'setAllowance' | 'waiting' | 'name'> & { granted: boolean }) => {
  if (granted) {
    if (grantedAllowance.gt(DISPLAY_AS_INFINITY_THRESHOLD)) {
      return (
        <>
          {' '}
          <InlineBlock data-rh={formatUnits(grantedAllowance) + ' ' + token}>infinite</InlineBlock>
        </>
      );
    } else {
      return ' ' + separatorsFormat(formatUnitsDisplay(grantedAllowance));
    }
  }
  if (loading) {
    if (requiredAllowance.gt(DISPLAY_AS_INFINITY_THRESHOLD)) {
      return (
        <>
          {' '}
          <InlineBlock data-rh={formatUnits(requiredAllowance) + ' ' + token}>infinite</InlineBlock>
        </>
      );
    }
    return ' ' + separatorsFormat(formatUnitsDisplay(requiredAllowance));
  }
};

type AllowanceProps = {
  name: keyof typeof CONTRACTS_ADDRESSES;
  // eslint-disable-next-line no-unused-vars
  setAllowance: (allowance: BigNumber) => void;
  token: string;
  requiredAllowance: BigNumber;
  grantedAllowance: BigNumber;
  loading?: boolean;
  waiting?: boolean;
};

const Allowance = ({
  name,
  grantedAllowance,
  requiredAllowance,
  setAllowance,
  loading,
  waiting,
  token,
}: React.PropsWithChildren<AllowanceProps>) => {
  const granted = requiredAllowance.lte(grantedAllowance);
  const account = useSelector(selectAccount);
  if (!account) {
    return (
      <Message warning>
        Connect your wallet to grant <ContractLink name={name} /> permission over your NXM.
      </Message>
    );
  }
  return (
    <Container>
      <Message loading={loading} warning={!granted} success={granted} pulse={!granted}>
        Grant{granted ? 'ed' : ''}
        {loading ? 'ing' : ''} <ContractLink name={name} /> permission over
        {granted || loading ? (
          renderAmount({
            granted,
            grantedAllowance,
            requiredAllowance,
            loading,
            token,
          })
        ) : (
          <Inline>
            <ButtonGroup>
              {requiredAllowance.lt(MaxUint256) ? (
                <Button
                  small
                  data-rh={separatorsFormat(formatUnits(requiredAllowance))}
                  onClick={() => setAllowance(requiredAllowance)}
                  disabled={waiting}
                >
                  {separatorsFormat(formatUnitsDisplay(requiredAllowance))}
                </Button>
              ) : null}
              <Button small onClick={() => setAllowance(MaxUint256)} disabled={waiting}>
                <InfinitySignWrapper>
                  <InfinitySign size="0.6rem" />
                </InfinitySignWrapper>
              </Button>
            </ButtonGroup>
          </Inline>
        )}{' '}
        {token}.
      </Message>
    </Container>
  );
};

export default Allowance;
