export const INVALID_TOKEN_VALUE_ERR = 'Enter a valid value (e.g. 1999.99).';
export const INVALID_INTEGER_TOKEN_VALUE_ERR = 'Enter a valid value (e.g. 1999).';
export const INSUFFICIENT_FUNDS_ERR = 'Insufficient funds.';
export const EXCEEDED_GAS_ESTIMATE_ERR = 'Estimated required gas exceeds your current ETH balance.';
export const MINIMUM_STAKE_ERR = 'Stake {1} NXM minumum per contract.';
export const MAXIMUM_STAKE_ERR = 'Top up with {1} NXM or stake {2} NXM maximum per contract.';
export const BELOW_EXISTING_STAKE_ERR =
  'Stake {1} NXM minimum which is the amount currently staked.';
export const MAX_SELL_AMOUNT_EXCEEDED_ERROR =
  "You can't swap more than {1} NXM per transaction due to the current MCR%. The limit will increase as more ETH is contributed to the pool.";
export const MAX_STAKED_TOTAL_EXCEEDED_ERROR = `Total stake exceeds your total deposit. Top up with {1} NXM or adjust stakes.`;
export const MIN_DEPOSIT_ERR = `The initial deposit must be at least {1} NXM`;
export const NOT_ENOUGH_CAPACITY_ERR = `Not enough capacity for the requested amount or period. Please try a lower amount or shorter period.`;
