import React from 'react';

import clsx from 'clsx';

import { RadioOptionStyles } from './RadioOption.styles';

export type RadioOptionProps = {
  value: string | number;
  label: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onClick?: (value: string | number) => void;
  'data-rh'?: string;
};

export const RadioOption = ({
  value,
  label,
  checked,
  disabled,
  onClick,
  ...props
}: RadioOptionProps) => {
  return (
    <RadioOptionStyles
      className={clsx({ checked, disabled })}
      onClick={() => {
        if (!disabled) {
          onClick?.(value);
        }
      }}
      {...props}
    >
      {label}
    </RadioOptionStyles>
  );
};
