import React from 'react';

import colors from 'Constants/colors';
import styled, { css, keyframes } from 'styled-components';

const Container = styled.div`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  position: relative;
`;

const rotateSecondary = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const Svg = styled.svg`
  animation: 2s linear infinite both ${rotateSecondary};
`;

const rotate = keyframes`
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`;

const Circle = styled.circle`
  animation: 1.4s ease-in-out infinite both ${rotate};
  fill: transparent;
  stroke: ${({ color }) => color || colors.greyAsher};
  stroke-dasharray: 285;
  stroke-linecap: round;
  stroke-width: 10px;
  transform-origin: 50% 50%;
`;

const Spinner = ({ size, color }) => (
  <Container size={size || '1.5rem'}>
    <Svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="50" cy="50" r="45" color={color} />
    </Svg>
  </Container>
);

export default Spinner;
