import React, { useEffect } from 'react';

import CustomCookieBanner from 'Components/CustomCookieBanner';
import Router from 'Core/router';

const handleFirstTab = e => {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
};

const App = () => {
  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab);
  }, []);

  return (
    <>
      <Router />
      <CustomCookieBanner />
    </>
  );
};
export default App;
