import React, { useEffect, useMemo, useRef, useState } from 'react';

import shadowStyle from 'Components/styles/shadowStyle';
import colors from 'Constants/colors';
import Downshift from 'downshift';
import { capitalize } from 'Services/utils';
import styled from 'styled-components';

import Input from './Input';
import ProductLogo from './ProductLogo';

const InnerMenuContainer = styled.div`
  padding: 0 1rem;
  max-height: 16rem;
  border-radius: 0 0 1rem 1rem;
  overflow-y: auto;
`;

const MenuContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background: ${colors.white};
  max-height: 16rem;
  overflow-y: hidden;
  border-radius: 0 0 1rem 1rem;
  ${shadowStyle}
`;

const Option = styled.button<Pick<DropdownProps, 'selected'>>`
  &:hover,
  &:focus {
    background: ${colors.greenDark};
    color: ${colors.white};
    outline: 0;
    box-shadow: 0 0 0 0 !important;
  }
  background: ${colors.white};
  color: ${({ selected }) => (selected ? colors.greenDark : colors.black)};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  font-size: 1rem;
  text-align: left;
  border: 0;
  display: block;
  width: calc(100% + 2rem);
  padding: 1rem;
  margin: 0 -1rem;
  cursor: pointer;
  text-transform: capitalize;
`;

const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  ${({ isOpen }) => (isOpen ? 'z-index: 3;' : '')}
  & > div:first-child {
    ${({ isOpen }) => (isOpen ? ` border-radius: 1rem 1rem 0 0; ` : '')}
  }
  & input {
    ::placeholder {
      color: ${colors.black} !important;
    }
  }
`;

const Caret = styled.div`
  display: inline-block;
  margin-left: 0.5rem;
`;

const Unit = styled.div`
  cursor: pointer;
  line-height: 2rem;
  text-align: right;
  color: ${colors.nexusGreen};
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;

type DropdownProps = {
  // eslint-disable-next-line no-unused-vars
  onChange: (selectedValue: string) => void;
  options: Array<{
    label: any;
    value: any;
    icon?: any;
  }>;
  selected?: string;
  initialInputValue?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnlyInput?: boolean;
  hasReadOnlyOptions?: boolean;
};

const Dropdown = ({
  options = [],
  onChange,
  selected,
  initialInputValue,
  placeholder,
  disabled,
  readOnlyInput,
  hasReadOnlyOptions,
}: DropdownProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(initialInputValue);

  const selectedItem = useMemo(
    () => options.find(option => option.value === selected),
    [selected, options],
  );

  useEffect(() => {
    // Clears the input if selectedItem is not found
    if (!selectedItem) {
      setInputValue(initialInputValue || '');
    } else {
      setInputValue(selectedItem.label);
    }
  }, [selectedItem, initialInputValue]);

  const close = () => {
    setIsOpen(false);
    selectedItem && setInputValue(selectedItem.label);
  };

  return (
    <Downshift
      isOpen={isOpen}
      onSelect={item => {
        onChange(item?.value);
        setInputValue(item?.label);
        setIsOpen(false);
        inputRef && inputRef.current && inputRef.current.blur();
      }}
      initialSelectedItem={selectedItem}
      inputValue={inputValue || selectedItem?.label || ''}
      onOuterClick={close}
      itemToString={item => (item ? item.label : '')}
    >
      {({ getRootProps, getItemProps, getMenuProps, isOpen, inputValue, selectedItem }) => {
        return (
          <Container {...getRootProps()} isOpen={isOpen}>
            {!readOnlyInput ? (
              <>
                <Input
                  icon={
                    selectedItem?.icon && (
                      <ProductLogo
                        size="1.5rem"
                        src={selectedItem?.icon}
                        name={selectedItem?.label}
                      />
                    )
                  }
                  dropdown
                  disabled={disabled}
                  value={capitalize(inputValue) || ''}
                  placeholder={placeholder}
                  onFocus={() => setIsOpen(true)}
                  onChange={e => {
                    setIsOpen(true);
                    setInputValue(e.target.value);
                  }}
                  ref={inputRef}
                  readOnly={hasReadOnlyOptions}
                />
              </>
            ) : (
              <Unit
                aria-disabled={disabled}
                onClick={() => {
                  !disabled && setIsOpen(true);
                }}
              >
                {inputValue || ''}
                <Caret>▾</Caret>
              </Unit>
            )}
            {isOpen ? (
              <MenuContainer>
                <InnerMenuContainer {...getMenuProps()}>
                  {options
                    .filter(item => {
                      // for dropdowns without search
                      // while typing, show all options
                      if (!initialInputValue) {
                        return true;
                      }
                      // for dropdowns with search
                      // while typing, show only options that match the search
                      return (
                        (!selectedItem && !inputValue) ||
                        (selectedItem &&
                          inputValue &&
                          selectedItem.label.toLowerCase() === inputValue.toLowerCase()) ||
                        (inputValue && item.label.toLowerCase().includes(inputValue.toLowerCase()))
                      );
                    })
                    .map((item, index) => (
                      <Option
                        key={item.value + item.label + index}
                        selected={selectedItem && selectedItem.value === item.value}
                        {...getItemProps({ key: item.value, index, item })}
                      >
                        {item.icon && (
                          <ProductLogo
                            margin="1rem"
                            size="1.5rem"
                            src={item.icon}
                            name={item.label}
                          />
                        )}
                        {item.label}
                      </Option>
                    ))}
                </InnerMenuContainer>
              </MenuContainer>
            ) : null}
          </Container>
        );
      }}
    </Downshift>
  );
};

export default Dropdown;
