import React from 'react';

import ExternalLinkIcon from 'Assets/external-link.svg';
import A from 'Components/A';
import GradientShimmer from 'Components/GradientShimmer';
import styled from 'styled-components';

const ExternalLinkContainer = styled.span`
  padding-left: 0.5rem;
`;

type ExternalLinkProps = {
  href?: string;
  children?: React.ReactNode;
  loading?: boolean;
  warning?: boolean;
  passive?: boolean;
  target?: string;
  rel?: string;
};

const ExternalLink = ({
  children,
  href,
  loading,
  warning,
  passive,
  target = '_blank',
}: ExternalLinkProps) =>
  loading ? (
    <GradientShimmer height="1.5rem" width="76px" />
  ) : (
    <A
      target={target}
      rel="noreferrer noopener"
      href={href as string}
      warning={warning}
      passive={passive}
    >
      {children}
      <ExternalLinkContainer>
        <ExternalLinkIcon width="12px" />
      </ExternalLinkContainer>
    </A>
  );

export default ExternalLink;
