import React, { useEffect, useRef } from 'react';

import GradientShimmer from 'Components/GradientShimmer';
import colors from 'Constants/colors';
import styled from 'styled-components';

const LastCellContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionRowContainer = styled.div`
  height: 2rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
`;

const Container = styled.div<{
  $loading?: boolean;
  embeddable?: boolean;
  stretchX?: boolean;
  maxHeight?: string;
  ref?: React.RefObject<HTMLDivElement> | any;
}>`
  border-radius: ${({ embeddable }) => (embeddable ? 'initial' : '1rem')};
  margin-bottom: ${({ embeddable }) => (embeddable ? 'initial' : '2rem')};
  overflow: auto;
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'auto')};
  width: ${props => (props.stretchX ? '100%' : 'auto')};
  border: 1px solid ${colors.greyPearl};
  ${({ embeddable }) => (embeddable ? 'border: 0;' : '')}
  ${({ $loading }) =>
    $loading &&
    `
      mask: linear-gradient(to bottom, black 10%, transparent 90%);
      height: 24rem;
    `}
`;

const composeRowKey = (row: any, rowKey: any, rowIdx: number) => {
  if (Array.isArray(rowKey)) {
    return rowKey.reduce((acc, key) => acc + row[key], '');
  }
  return row[rowKey] ?? rowIdx;
};

const Table = styled.table<{
  highlightRowOnHover?: boolean;
  width100?: boolean;
}>`
  table-layout: fixed;
  border-spacing: 0;
  width: ${({ width100 }) => (width100 ? '100%' : 'auto')};

  tbody > tr {
    background: ${colors.greenMint};

    &:nth-child(even) {
      background: ${colors.white};
    }

    ${({ highlightRowOnHover }) =>
      highlightRowOnHover &&
      `
      &:hover {
        cursor: pointer;
        background: ${colors.greyPearl};
      }

      &:active {
        background: ${colors.greenTea};
      }
    `}
  }
`;

const TH = styled.th<{ first: boolean; last: boolean; width: string }>`
  padding: ${({ first, last }) => {
    if (first) {
      return '1rem 1rem 1rem 2rem';
    }
    if (last) {
      return '1rem 2rem 1rem 1rem';
    }
    return '1rem';
  }};
  white-space: nowrap;
  width: ${({ width }) => width || 'auto'};
  text-align: ${({ align, first, last }) =>
    align || (first && 'left') || (last && 'right') || 'center'};
  font-weight: 600;
  background: ${colors.white};
  color: ${colors.greyStone};
`;

const TD = styled.td<{ first: boolean; last: boolean }>`
  padding: ${({ first, last }) => {
    if (first) {
      return '1rem 1rem 1rem 2rem';
    }
    if (last) {
      return '1rem 2rem 1rem 1rem';
    }
    return '1rem';
  }};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: ${({ align, first, last }) =>
    align || (first && 'left') || (last && 'right') || 'center'};
  text-transform: capitalize;
`;

const THead = styled.thead`
  color: ${colors.greyAsher};
  position: sticky;
  top: 0;
  text-transform: lowercase;
  th::first-letter {
    text-transform: uppercase;
  }
`;

const LoadingRows = ({ columns, rowCount = 5 }: { columns: any[]; rowCount?: number }) => (
  <>
    {Array(rowCount)
      .fill('')
      .map((_, i) => (
        <tr key={i}>
          {columns.map((_col, colIdx) => (
            <TD key={i + '-' + colIdx} first={colIdx === 0} last={colIdx === columns.length - 1}>
              {i === columns.length - 1 ? (
                <LastCellContainer>
                  <GradientShimmer height="1.5rem" margin="0.25rem 0" width="100%" />
                </LastCellContainer>
              ) : (
                <GradientShimmer height="1.5rem" width="100%" margin="0.25rem 0" />
              )}
            </TD>
          ))}
        </tr>
      ))}
  </>
);

const TableHeader = ({ columns }: { columns: any[] }) => {
  return (
    <THead>
      <tr>
        {columns.map((col, colIdx) => (
          <TH
            key={colIdx}
            first={colIdx === 0}
            last={colIdx === columns.length - 1}
            width={col.width}
            align={col.align}
          >
            {col.title}
          </TH>
        ))}
      </tr>
    </THead>
  );
};

const LoadingTable = ({ columns, embeddable }: { columns: any[]; embeddable?: boolean }) => {
  return (
    <Container embeddable={embeddable} $loading>
      <Table>
        <TableHeader columns={columns} />
        <tbody>
          <LoadingRows columns={columns} />
        </tbody>
      </Table>
    </Container>
  );
};

const TableRow = React.memo(
  ({ row, index, columns, onClick }: { row: any; index: number; columns: any[]; onClick: any }) => (
    <tr onClick={onClick}>
      {columns.map((col, colIdx) => (
        <TD
          key={colIdx}
          first={colIdx === 0}
          last={colIdx === columns.length - 1}
          width={col.width}
          align={col.align}
        >
          {colIdx === columns.length - 1 ? (
            <LastCellContainer>{col.renderCell(row, index)}</LastCellContainer>
          ) : (
            col.renderCell(row, index)
          )}
        </TD>
      ))}
    </tr>
  ),
);

TableRow.displayName = 'TableRow';

type TableComponentProps = {
  columns: Array<{
    title: string | React.ElementType;
    renderCell: (_data: any, i: number) => any;
    width?: string;
  }>;
  rows: any[];
  actionRow?: any;
  maxHeight?: string;
  rowKey?: string | string[];
  loading?: boolean;
  nextPageLoading?: boolean;
  nextPageSize?: number;
  loadNextPage?: () => void;
  infiniteScroll?: boolean;
  embeddable?: boolean;
  highlightRowOnHover?: boolean;
  width100?: boolean;
  onClickRowHandler?: (_row: any) => void;
};

const TableComponent = ({
  infiniteScroll,
  rows = [],
  columns,
  rowKey,
  actionRow,
  maxHeight,
  embeddable,
  loading,
  nextPageLoading,
  loadNextPage,
  nextPageSize,
  highlightRowOnHover,
  width100,
  onClickRowHandler,
}: TableComponentProps) => {
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handler = () => {
      const viewportBottom = window.scrollY + window.innerHeight;
      const containerBottom =
        (containerRef?.current?.offsetTop as number) +
        (containerRef?.current?.offsetHeight as number);
      if (nextPageSize && !nextPageLoading && viewportBottom > containerBottom && nextPageSize) {
        loadNextPage && loadNextPage();
      }
    };
    if (infiniteScroll) {
      const viewportBottom = window.scrollY + window.innerHeight;
      const containerBottom =
        (containerRef?.current?.offsetTop as number) +
        (containerRef?.current?.offsetHeight as number);

      if (nextPageSize && !nextPageLoading && viewportBottom > containerBottom) {
        loadNextPage && loadNextPage();
      }
      window.addEventListener('scroll', handler);
      return () => window.removeEventListener('scroll', handler);
    }
  }, [infiniteScroll, loadNextPage, nextPageLoading, nextPageSize]);

  if (loading) {
    return <LoadingTable embeddable={embeddable} columns={columns} />;
  }

  return (
    <>
      <Container maxHeight={maxHeight} embeddable={embeddable} ref={containerRef}>
        <Table highlightRowOnHover={highlightRowOnHover} width100={width100}>
          <TableHeader columns={columns} />
          <tbody>
            {rows?.map((row, rowIdx) =>
              row.separator ? (
                <tr key={composeRowKey(row, rowKey, rowIdx)}>
                  <td colSpan={columns.length}>{row.separator}</td>
                </tr>
              ) : (
                <TableRow
                  row={row}
                  index={rowIdx}
                  columns={columns}
                  key={composeRowKey(row, rowKey, rowIdx)}
                  onClick={() => onClickRowHandler && onClickRowHandler(row)}
                />
              ),
            )}
            {nextPageLoading ? <LoadingRows columns={columns} rowCount={nextPageSize} /> : null}
          </tbody>
        </Table>
        {actionRow ? <ActionRowContainer>{actionRow}</ActionRowContainer> : null}
      </Container>
    </>
  );
};

export default TableComponent;
