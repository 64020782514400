import colors from 'Constants/colors';
import styled from 'styled-components';

type AProps = { warning?: boolean; passive?: boolean; href: string };

const getColor = ({ warning, passive }: AProps) =>
  (warning && colors.yellowButter) || (passive && colors.greyStone) || colors.nexusGreen;

const A = styled.a<AProps>`
  fill: ${getColor};
  color: ${getColor};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  margin: 0.2rem;
`;

export default A;
