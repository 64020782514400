import { BigNumber } from '@ethersproject/bignumber';
import { Zero } from '@ethersproject/constants';
import { Provider } from '@ethersproject/providers';
import { formatUnits, parseEther, parseUnits } from '@ethersproject/units';
import {
  addresses as CONTRACTS_ADDRESSES,
  calculatePremiumWithCommissionAndSlippage,
} from '@nexusmutual/sdk';
import { Alchemy, Network } from 'alchemy-sdk';
import { CHAIN_ID, COVER_ROUTER_API, TESTING_API } from 'Config';
import { COVER_ASSETS_MAP } from 'Constants/covers';
import { BUY_COVER_COMMISSION_RATIO } from 'Constants/coverV2';
import { NOT_ENOUGH_CAPACITY_ERR } from 'Constants/errorMessages';
import { BuyCoverStateType, Capacity, Quote } from 'Features/Cover/ducks/root/initialState';
import CoverNFT from 'Services/contracts/CoverNFT';

type CapacityObj = Array<{
  assetId: string;
  amount: string;
}>;

type CapacityApiResponse = {
  productId: string;
  capacity?: CapacityObj;
  availableCapacity?: CapacityObj;
  allocatedNxm?: string;
  error?: string;
};

type QuoteCapacityApiResponse = {
  poolId: string;
  capacity?: CapacityObj;
  availableCapacity?: CapacityObj;
};

const parseCapacityApiResponse = (capacityApiResponse: CapacityApiResponse): Capacity => {
  const productCapacity: Capacity & {
    [key: string]: BuyCoverStateType['capacityETH'] | BuyCoverStateType['capacityDAI'];
  } = {
    productId: capacityApiResponse.productId,
    capacityETH: Zero,
    capacityDAI: Zero,
    allocatedNxm: BigNumber.from(capacityApiResponse?.allocatedNxm || '0'),
  };

  const availableCapacity = capacityApiResponse?.capacity || capacityApiResponse?.availableCapacity;

  if (availableCapacity) {
    for (const assetCapacity of availableCapacity) {
      const assetName = Object.keys(COVER_ASSETS_MAP)[+assetCapacity.assetId];
      productCapacity[`capacity${assetName}`] = BigNumber.from(assetCapacity.amount);
    }
  }

  return productCapacity;
};

type QuoteApiResponse = {
  error?: string;
  quote: {
    annualPrice: string;
    premiumInAsset: string;
    premiumInNXM: string;
    poolAllocationRequests: Array<{
      poolId: string;
      coverAmountInAsset: string;
      skip: boolean;
    }>;
  };
  capacities: QuoteCapacityApiResponse[];
};

export const getCoverPriceWithCommission = (
  price: BigNumber,
  slippage = 0,
  commission = BUY_COVER_COMMISSION_RATIO,
): BigNumber => {
  if (!BigNumber.isBigNumber(price)) {
    price = BigNumber.from(price);
  }

  if (price.isZero()) {
    return price;
  }

  const priceWithCommissionAndSlippage = calculatePremiumWithCommissionAndSlippage(
    price.toBigInt(),
    commission,
    slippage,
  );

  return BigNumber.from(priceWithCommissionAndSlippage);
};

const getQuote = async ({
  productId,
  amount,
  currency,
  period,
  paymentAsset,
  slippage = '0',
  customCommission,
  chainId = CHAIN_ID,
}: {
  productId: string;
  amount: BuyCoverStateType['amount'];
  currency: BuyCoverStateType['currency'];
  paymentAsset: BuyCoverStateType['paymentAsset'];
  period: BuyCoverStateType['period'];
  slippage?: BuyCoverStateType['slippageInput'];
  customCommission?: number;
  chainId?: number;
}): Promise<Quote> => {
  const API_URL = chainId === 1 ? COVER_ROUTER_API : TESTING_API;

  if (!productId || !amount || !period || !currency || !paymentAsset) {
    throw new Error('Amount, period, cover and payment asset are required');
  }

  const query = {
    productId,
    amount: formatUnits(parseEther(amount || '0'), 0),
    period: (period || 0).toString(),
    coverAsset: COVER_ASSETS_MAP[currency].toString(),
    paymentAsset: COVER_ASSETS_MAP[paymentAsset].toString(),
  };

  const quoteApiResponse: QuoteApiResponse = await fetch(
    `${API_URL}/v2/quote?` + new URLSearchParams(query),
  )
    .then(res => res.json())
    .catch(_err => {
      return null;
    });

  if (!quoteApiResponse || quoteApiResponse.error) {
    let errorMessage = 'Quote could not be retrieved';
    if (quoteApiResponse?.error === 'Not enough capacity for the cover amount') {
      errorMessage = NOT_ENOUGH_CAPACITY_ERR;
    }
    throw new Error(errorMessage);
  }

  const response = quoteApiResponse;

  const { quote, capacities } = response;

  const slippageValue = +slippage * 100;

  const quoteObj: Quote = {
    productId,
    amount,
    currency,
    period,
    annualPrice: getCoverPriceWithCommission(
      parseUnits(quote?.annualPrice || '0').div(100),
      slippageValue,
      customCommission,
    ),
    // Add commission to the price
    price: getCoverPriceWithCommission(
      BigNumber.from(quote.premiumInAsset),
      slippageValue,
      customCommission,
    ),
    priceInNXM: getCoverPriceWithCommission(
      BigNumber.from(quote.premiumInNXM),
      slippageValue,
      customCommission,
    ),
    poolAllocationRequests: quote.poolAllocationRequests.map(pool => ({
      poolId: pool.poolId,
      coverAmountInAsset: BigNumber.from(pool.coverAmountInAsset), // make sure it's a BigNumber
      skip: pool.skip,
    })),
  };

  const poolsCapacities = capacities?.map(poolCapacity => {
    const capacity = parseCapacityApiResponse({ productId, ...poolCapacity });

    return {
      poolId: poolCapacity.poolId,
      capacity,
    };
  });

  quoteObj.poolsCapacities = poolsCapacities;

  return quoteObj;
};

const getProductCapacity = async (
  productId: BuyCoverStateType['selectedProductId'],
  chainId = CHAIN_ID,
): Promise<Capacity> => {
  const API_URL = chainId === 1 ? COVER_ROUTER_API : TESTING_API;

  if (!productId) {
    throw new Error('Product ID is required');
  }

  const productCapacityData: CapacityApiResponse = await fetch(
    `${API_URL}/v2/capacity/${productId}`,
  ).then(res => res.json());

  if (productCapacityData.error) {
    throw new Error('Product capacity could not be retrieved.');
  }

  const productCapacity = parseCapacityApiResponse(productCapacityData);

  return productCapacity;
};

const getProductCapacities = async (chainId = CHAIN_ID): Promise<Capacity[]> => {
  const API_URL = chainId === 1 ? COVER_ROUTER_API : TESTING_API;

  const capacitiesResponse = await fetch(`${API_URL}/v2/capacity`).then(res => res.json());
  const capacities = capacitiesResponse.map((capacity: CapacityApiResponse) =>
    parseCapacityApiResponse(capacity),
  );

  return capacities;
};

const getCoverOwnedTokenIdsIndexer = async (account: string, chainId = CHAIN_ID) => {
  const alchemy = new Alchemy({
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.ETH_MAINNET,
    // if running on Tenderly fork, use our custom indexer
    url: chainId !== CHAIN_ID ? TESTING_API : undefined,
  });

  const nftsIterable = alchemy.nft.getNftsForOwnerIterator(account, {
    contractAddresses: [CONTRACTS_ADDRESSES.CoverNFT],
    omitMetadata: true,
  });

  const ownedNfts = [];
  for await (const nft of nftsIterable) {
    ownedNfts.push(nft.tokenId);
  }

  return ownedNfts;
};

const getCoverOwnedTokenIdsOnChain = async (provider: Provider, account: string): Promise<any> => {
  const contract = CoverNFT(provider, account);
  const totalSupplyBN = await contract.totalSupply();
  const totalSupply = BigNumber.from(totalSupplyBN).toNumber();

  // Create list of numbers (as string) of length `totalSupply` starting from 1
  const ids: string[] = [...Array(totalSupply).keys()].map(id => `${id + 1}`);

  const ownedIds: string[] = [];

  for (const id of ids) {
    const owner = await contract.ownerOf(id);
    if (owner === account) {
      ownedIds.push(id);
    }
  }

  return ownedIds;
};

const getCoverOwnedTokens = async (provider: Provider, account: string, chainId = CHAIN_ID) => {
  const tokenIds: string[] = [];

  if (process.env.REACT_APP_NETWORK === 'mainnet' && process.env.REACT_APP_ALCHEMY_API_KEY) {
    const ownedIds = await getCoverOwnedTokenIdsIndexer(account, chainId);
    tokenIds.push(...ownedIds);
  } else {
    const ownedIds = await getCoverOwnedTokenIdsOnChain(provider, account);
    tokenIds.push(...ownedIds);
  }

  return tokenIds;
};

export default {
  getQuote,
  getProductCapacity,
  getProductCapacities,
  getAccountOwnedTokens: getCoverOwnedTokens,
};
