import React, { useState } from 'react';

import GradientShimmer from 'Components/GradientShimmer';
import colors from 'Constants/colors';
import styled from 'styled-components';

const Logo = styled.img<LogoContainerProps>`
  border-radius: 0.5rem;
  width: ${({ size }: { size: string }) => size};
  height: ${({ size }) => size};
  vertical-align: middle;
  margin-right: ${({ margin }) => margin};
`;

type LogoContainerProps = { size: string; warning?: boolean; margin?: string };

const FallbackLogoContainer = styled.div<LogoContainerProps>`
  & > svg {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    fill: ${({ warning }) => (warning ? colors.yellowButter : colors.greyAsher)};
  }
`;

const FallbackLogo = ({ size, warning = false }: LogoContainerProps) => (
  <FallbackLogoContainer size={size} warning={warning}>
    <GradientShimmer width={size} height={size} />
  </FallbackLogoContainer>
);

type ProductLogoProps = LogoContainerProps & {
  src: string;
  name: string;
  loading?: boolean;
};

const ProductLogo = ({ src, name, size, margin, loading, warning }: ProductLogoProps) => {
  const [error, setError] = useState(false);
  if (loading) {
    return <GradientShimmer width={size} height={size} />;
  }
  return src && !error ? (
    <Logo
      data-rh={name}
      alt={name}
      src={'/logos/' + src}
      size={size}
      margin={margin}
      onError={() => setError(true)}
    />
  ) : (
    <FallbackLogo size={size} warning={warning} />
  );
};

export default ProductLogo;
