import React from 'react';

import { PRODUCTS_IDS } from 'Constants/v2ProductsIds';

import ExternalLink from './ExternalLink';

const YIELD_AGGREGATORS_NOTE = (
  <>
    <li>
      Losses that occur outside the designated protocol, such as a separate protocol used in an
      underlying yield aggregation strategy
    </li>
  </>
);

const notes: { [key: number]: JSX.Element } = {
  [PRODUCTS_IDS.GMX]: (
    <>
      <li>
        Liquidity provider (LP) losses as a result of previously disclosed design issues, which
        include but is not limited to{' '}
        <ExternalLink href="https://www.coindesk.com/markets/2022/09/19/defi-trader-nets-over-500k-by-using-dex-gmx-to-manipulate-avalanche-token/">
          accumulated LP losses due to zero-slippage design
        </ExternalLink>
      </li>
    </>
  ),
  [PRODUCTS_IDS.BANCOR_V3]: (
    <>
      <li>
        Liquidity provider (LP) losses as a result of previously disclosed design issues, which
        include but is not limited to accumulated LP losses due to BNT pairs without IL protection
      </li>
    </>
  ),
  [PRODUCTS_IDS['YEARN_FINANCE_(ALL_VAULTS)']]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.BEEFY]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.GEARBOX_V2]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.NESTED]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.POOL_TOGETHER_V4]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.NOTIONAL_FINANCE_V2]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS['CONVEX_3CRV_(CVX3CRV)']]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.STAKE_DAO]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.VESPER]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.BADGERDAO]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.MSTABLE]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.ENZYME_V4]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.VECTOR]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.YETI_FINANCE]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.FODL]: YIELD_AGGREGATORS_NOTE,
  [PRODUCTS_IDS.ALCHEMIX_V2]: YIELD_AGGREGATORS_NOTE,
};

type ProductNoteProps = { productId: string | number };

const ProductNote = ({ productId }: ProductNoteProps) => {
  if (!notes[productId as number]) {
    return null;
  }

  return <>{notes[productId as number]}</>;
};

export default ProductNote;
