import colors from 'Constants/colors';
import styled from 'styled-components';

type TextContainerProps = {
  warning?: boolean;
  danger?: boolean;
  success?: boolean;
  passive?: boolean;
  bold?: boolean;
};

const TextContainer = styled.span<TextContainerProps>`
  color: ${({ warning, danger, success, passive }) =>
    (passive && colors.greyStone) ||
    (success && colors.greenSeaweed) ||
    (warning && colors.yellowButter) ||
    (danger && colors.redTart) ||
    'initial'};
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
`;

export default TextContainer;
