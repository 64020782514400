import { formatUnits, parseUnits } from '@ethersproject/units';
import { MEMBER_ROLES } from 'Constants/governance';
import { coverableProducts } from 'Constants/v1Products';
import { RootState } from 'Core/store';
import { createSelector } from 'reselect';
import { createDisplaySelector, displayObjFallback, toDisplayObj } from 'Services/selectorUtils';
import { formatUnitsDisplay, get, shortenNumeric } from 'Services/utils';

import { RootDuckState } from './initialState';

const KEY = 'root';

export const selectChainId = (state: RootState): RootDuckState['chainId'] => state[KEY].chainId;

export const selectIsMemberLoading = (state: RootState): RootDuckState['isMemberLoading'] =>
  state[KEY].isMemberLoading;

export const selectMemberRoles = (state: RootState): RootDuckState['memberRoles'] =>
  state[KEY].memberRoles;

export const selectIsABMember = createSelector(selectMemberRoles, memberRoles =>
  memberRoles.map(x => x.toNumber()).includes(MEMBER_ROLES.ADVISORY_BOARD),
);

export const selectMemberRolesLoading = (state: RootState): RootDuckState['memberRolesLoading'] =>
  state[KEY].memberRolesLoading;

export const selectMemberRolesError = (state: RootState): RootDuckState['memberRolesError'] =>
  state[KEY].memberRolesError;

export const selectBalanceNXMLoading = (state: RootState): RootDuckState['balanceNXMLoading'] =>
  state[KEY].balanceNXMLoading;

export const selectTotalBalanceNXMLoading = (
  state: RootState,
): RootDuckState['totalBalanceNXMLoading'] => state[KEY].totalBalanceNXMLoading;

export const selectEthUsdRate = (state: RootState): RootDuckState['ethUsdRate'] =>
  state[KEY].ethUsdRate;

export const selectEthUsdRateLoading = (state: RootState): RootDuckState['ethUsdRateLoading'] =>
  state[KEY].ethUsdRateLoading;

export const selectEthUsdRateDecimals = (state: RootState): RootDuckState['ethUsdRateDecimals'] =>
  state[KEY].ethUsdRateDecimals;

export const selectEthDaiRate = (state: RootState): RootDuckState['ethDaiRate'] =>
  state[KEY].ethDaiRate;

export const selectEthDaiRateLoading = (state: RootState): RootDuckState['ethDaiRateLoading'] =>
  state[KEY].ethDaiRateLoading;

export const selectEthDaiRateDecimals = (state: RootState): RootDuckState['ethDaiRateDecimals'] =>
  state[KEY].ethDaiRateDecimals;

export const selectNxmEthRate = (state: RootState): RootDuckState['nxmEthRate'] =>
  state[KEY].nxmEthRate;

export const selectNxmEthRateLoading = (state: RootState): RootDuckState['nxmEthRateLoading'] =>
  state[KEY].nxmEthRateLoading;

export const selectBalanceETH = (state: RootState): RootDuckState['balanceETH'] =>
  state[KEY].balanceETH;

export const selectWnxmToEthRate = (state: RootState): RootDuckState['wnxmToEthRate'] =>
  state[KEY].wnxmToEthRate;

export const selectWnxmToEthRateLoading = (
  state: RootState,
): RootDuckState['wnxmToEthRateLoading'] => state[KEY].wnxmToEthRateLoading;

export const selectWnxmToEthRateError = (state: RootState): RootDuckState['wnxmToEthRateError'] =>
  state[KEY].wnxmToEthRateError;

export const selectBalanceETHDisplay = createDisplaySelector(selectBalanceETH, formatUnits);

export const selectBalanceNXM = (state: RootState): RootDuckState['balanceNXM'] =>
  state[KEY].balanceNXM;

export const selectTotalBalanceNXM = (state: RootState): RootDuckState['totalBalanceNXM'] =>
  state[KEY].totalBalanceNXM;

export const selectBalanceByToken = (
  state: RootState,
): Record<string, RootDuckState['balanceETH'] | RootDuckState['balanceNXM']> => ({
  ETH: state[KEY].balanceETH,
  NXM: state[KEY].balanceNXM,
  wNXM: state[KEY].balancewNXM,
});

export const selectProductCapacities = (state: RootState): RootDuckState['productCapacities'] =>
  state[KEY].productCapacities;

export const selectProductCapacitiesDisplay = createSelector(
  selectProductCapacities,
  productCapacities =>
    coverableProducts.reduce((acc: Record<string, { capacityETH: any }>, address) => {
      const capacityETH = get(productCapacities, [address, 'capacityETH']);
      if (!capacityETH) {
        acc[address] = {
          capacityETH: displayObjFallback('N/A'),
        };
        return acc;
      }
      acc[address] = {
        capacityETH: toDisplayObj(capacityETH),
      };
      return acc;
    }, {}),
);

export const selectProductCapacitiesLoading = (
  state: RootState,
): RootDuckState['productCapacitiesLoading'] => state[KEY].productCapacitiesLoading;

export const selectProductCapacitiesError = (
  state: RootState,
): RootDuckState['productCapacitiesError'] => state[KEY].productCapacitiesError;

export const selectIsMember = (state: RootState): RootDuckState['isMember'] => state[KEY].isMember;

export const selectBalanceNXMDisplay = createDisplaySelector(selectBalanceNXM, formatUnits);

export const selectBalanceNXMCompactDisplay = createSelector(selectBalanceNXMDisplay, x =>
  shortenNumeric(x.shortInput),
);

export const selectIsLockedForMV = (state: RootState): RootDuckState['isLockedForMV'] =>
  state[KEY].isLockedForMV && state[KEY].isLockedForMV * 1000 > Date.now();

export const selectIsLockedForMVDate = (state: RootState): Date =>
  state[KEY].isLockedForMV && new Date(state[KEY].isLockedForMV * 1000).toLocaleString();

export const selectNxmAllowance = (state: RootState): RootDuckState['nxmAllowance'] =>
  state[KEY].nxmAllowance;

export const selectSetNxmAllowanceLoading = (
  state: RootState,
): RootDuckState['setNxmAllowanceLoading'] => state[KEY].setNxmAllowanceLoading;

export const selectSetNxmAllowanceWaiting = (
  state: RootState,
): RootDuckState['setNxmAllowanceWaiting'] => state[KEY].setNxmAllowanceWaiting;

export const selectTotalSupplyLoading = (state: RootState): RootDuckState['totalSupplyLoading'] =>
  state[KEY].totalSupplyLoading;

export const selectLoadTotalSupplyError = (
  state: RootState,
): RootDuckState['loadTotalSupplyError'] => state[KEY].loadTotalSupplyError;

export const selectTotalSupply = (state: RootState): RootDuckState['totalSupply'] =>
  state[KEY].totalSupply;

export const selectIsUserMenuOpen = (state: RootState): RootDuckState['isUserMenuOpen'] =>
  state[KEY].isUserMenuOpen;

export const selectIsNavMenuOpen = (state: RootState): RootDuckState['isNavMenuOpen'] =>
  state[KEY].isNavMenuOpen;

export const selectTxHistory = (state: RootState): RootDuckState['txHistory'] =>
  state[KEY].txHistory;

export const selectAccount = (state: RootState): RootDuckState['account'] => state[KEY].account;

export const selectIsAccountBlacklisted = createSelector(selectAccount, account =>
  [
    '0x30F05FC73FA489D5ab8222E479651a21F50332C4',
    '0x436E6b2b58a92eC7520cAd25768320607698d5C4',
    '0x56e217B012A1df6DAeB1c7BD2E734d2A1E959C61',
  ].includes(account),
);

export const selectUserTxHistory = createSelector(
  selectAccount,
  selectTxHistory,
  (account, txHistory) => txHistory.filter(x => x.from === account),
);

export const selectProvider = (state: RootState): RootDuckState['provider'] => state[KEY].provider;

export const selectWallet = (state: RootState): RootDuckState['wallet'] => state[KEY].wallet;

export const selectPendingTransactions = createSelector(
  selectUserTxHistory,
  txHistory => txHistory.filter(x => x.status === 'PENDING').length,
);

export const selectProducts = (state: RootState): RootDuckState['products'] => state[KEY].products;

export const selectBalanceDAI = (state: RootState): RootDuckState['balanceDAI'] =>
  state[KEY].balanceDAI;

export const selectDaiAllowance = (state: RootState): RootDuckState['daiAllowance'] =>
  state[KEY].daiAllowance;

export const selectBalanceDAIDisplay = createDisplaySelector(selectBalanceDAI, formatUnits);

export const selectDaiAllowanceLoading = (state: RootState): RootDuckState['daiAllowanceLoading'] =>
  state[KEY].daiAllowanceLoading;

export const selectSetDaiAllowanceWaiting = (
  state: RootState,
): RootDuckState['setDaiAllowanceWaiting'] => state[KEY].setDaiAllowanceWaiting;

export const selectSetDaiAllowanceLoading = (
  state: RootState,
): RootDuckState['setDaiAllowanceLoading'] => state[KEY].setDaiAllowanceLoading;
