import React, { useRef, useState } from 'react';

import { Row } from 'Components/gridComponents';
import colors from 'Constants/colors';
import styled from 'styled-components';

const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    @media (max-width: 48rem) {
      width: 100%;
    }
    width: 255px;
    border-radius: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid ${colors.greenDark};
    color: ${colors.greenDark};
    background: ${colors.transparent};
    transition: 0.2s ease;
  }

  & + label svg {
    width: 1em;
    height: 1em;
    fill: ${colors.greenDark};
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
  }

  &:focus-visible + label {
    outline: 0;
    border: 1px solid ${colors.greyPearl};
  }
  & + label:hover {
    & svg {
      fill: ${colors.white};
    }
    color: ${colors.white};
    border-color: ${colors.transparent};
    background: ${colors.nexusGreen};
    background-position: right center;
  }
`;

type FileUploadProps = {
  accept: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (files: FileList) => void;
  multiple?: boolean;
  selectedFiles?: number;
};

const FileUplaod = ({ onChange, accept, multiple, selectedFiles }: FileUploadProps) => {
  const element = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState(selectedFiles || 0);
  const onFilesSelected = () => {
    if (element.current && element.current.files) {
      setSelected(element.current.files.length);
      onChange(element.current.files);
    }
  };

  return (
    <Row>
      <InputFile
        type="file"
        accept={accept}
        name="upload"
        id="upload"
        multiple={multiple}
        ref={element}
        onChange={onFilesSelected}
      />
      <label htmlFor="upload">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 17">
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
        <span>
          {selected
            ? `${selected} file${selected > 1 ? 's' : ''} selected`
            : 'Select file' + (multiple ? 's' : '')}
        </span>
      </label>
    </Row>
  );
};

export default FileUplaod;
