import React from 'react';

import Spinner from 'Components/Spinner';
import colors from 'Constants/colors';
import styled from 'styled-components';

const getWidthValue = (width?: string | number) => {
  // If width is numeric, treat it as pixels
  const isNumeric = /^[0-9]+$/.test(width as string);
  return isNumeric ? width + 'px' : width;
};

const Container = styled.button<ButtonProps>`
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px
      ${({ danger, isCTA }) => {
        if (danger) {
          return colors.redRose;
        }
        if (isCTA) {
          return colors.yellowDaffodil;
        }
        return colors.greenSeafoam;
      }} !important;
  }
  background: ${props => {
    if (props.primary) {
      if (props.danger) {
        return colors.redTart;
      }
      if (props.isCTA) {
        return colors.yellowButter;
      }
      return colors.nexusGreen;
    }
    return colors.transparent;
  }};

  border: 1px solid
    ${({ primary, danger }) => {
      if (primary) {
        return colors.transparent;
      }
      if (danger) {
        return colors.redTart;
      }
      return colors.greenSeaweed;
    }};
  max-height: ${({ small }) => (small ? '2rem' : '3rem')};
  height: ${({ small }) => (small ? '2rem' : '3rem')};
  color: ${({ primary, danger, isCTA }) => {
    if (primary) {
      if (isCTA) {
        return colors.black;
      }
      return colors.white;
    }
    if (danger) {
      return colors.redTart;
    }
    return colors.greenSeaweed;
  }};

  // Mobile view
  @media (max-width: 48rem) {
    ${({ fixedWidth, width }) =>
      fixedWidth
        ? `max-width: ${getWidthValue(width)}; width: ${getWidthValue(width)}`
        : 'width: 100%;'}

    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  // Desktop view
  @media (min-width: 48rem) {
    max-width: ${({ width }) => getWidthValue(width)};
    width: ${({ width }) => getWidthValue(width)};

    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
  }

  cursor: pointer;
  border-radius: 1.5rem;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-size: 200% auto;
  transition: 0.2s ease;
  white-space: nowrap;
  padding: 0 2rem;
  & svg {
    fill: ${props => {
      if (props.primary) {
        if (props.danger) {
          return colors.redRose;
        }
        return colors.greenMint;
      }
      if (props.danger) {
        return colors.redTart;
      }
      return colors.nexusGreen;
    }};
  }
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    text-decoration: none;
    color: ${props => {
      if (props.primary) {
        if (props.isCTA) {
          return colors.black;
        }
        return colors.white;
      }
      if (props.danger) {
        return colors.white;
      }
      return colors.nexusBlue;
    }};

    & svg {
      fill: ${props => {
        if (props.primary) {
          if (props.danger) {
            return colors.redTart;
          }
          if (props.isCTA) {
            return colors.black;
          }
          return colors.white;
        }
        if (props.danger) {
          return colors.white;
        }
        return colors.nexusBlue;
      }};
    }
    border-color: ${props => {
      if (props.primary) {
        if (props.danger) {
          return colors.redDark;
        }
        if (props.isCTA) {
          return colors.yellowButter;
        }
        return colors.greenSeafoam;
      }
      if (props.danger) {
        return colors.transparent;
      }
      return colors.nexusGreen;
    }};
    background: ${props => {
      if (props.primary) {
        if (props.isCTA) {
          return colors.yellowDaffodil;
        }
        return '';
      }
      if (props.danger) {
        return colors.redTart;
      }
      return colors.greenMint;
    }};
    background-position: right center;
  }
  span {
    font-size: ${({ small }) => (small ? '1rem' : '1.25rem')};
    margin: 0 0.5rem 0 0.5rem;
    line-height: 2rem;
  }
`;

const IconContainer = styled.div<{ small?: boolean; replacesIcon?: boolean }>`
  & > svg {
    height: 100%;
  }
  display: flex;
  justify-content: center;
  width: ${({ small }) => (small ? '1rem' : '2rem')};
  height: ${({ small }) => (small ? '1rem' : '2rem')};
  border-radius: 1rem;
  align-items: center;
`;

const SpinnerContainer = styled.div<{ small?: boolean; replacesIcon?: boolean }>`
  padding: ${({ small }) => (small ? '0.125rem' : '0.25rem')};
  ${({ replacesIcon }) => (replacesIcon ? '' : 'margin-left: -2rem;')}
`;

type ButtonProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  width?: string | number;
  primary?: boolean;
  danger?: boolean;
  isCTA?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  small?: boolean;
  'data-rh'?: string;
  noContentAbove?: boolean;
  alwaysEnabled?: boolean;
  fixedWidth?: boolean;
};

const Button = (props: ButtonProps) => (
  <Container
    width={props.width}
    noContentAbove={props.noContentAbove}
    data-rh={props['data-rh']}
    primary={props.primary}
    danger={props.danger}
    isCTA={props.isCTA}
    disabled={props.disabled || (props.loading && !props.alwaysEnabled)}
    onClick={props.onClick}
    small={props.small}
    fixedWidth={props.fixedWidth}
  >
    {props.loading && (
      <SpinnerContainer replacesIcon={!!props.icon} small={props.small}>
        <Spinner
          color={props.primary ? colors.white : colors.nexusGreen}
          size={props.small ? '1rem' : '1.5rem'}
        />
      </SpinnerContainer>
    )}
    {props.icon && !props.loading ? (
      <IconContainer small={props.small}>{props.icon}</IconContainer>
    ) : null}
    <span>{props.children}</span>
  </Container>
);

export default Button;
