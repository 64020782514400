import colors from 'Constants/colors';
import styled from 'styled-components';

export const SmallDetailCardWithPatternStyles = styled.div<{ patternUrl: string }>`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${colors.greenDark};
  background-image: url(${({ patternUrl }) => patternUrl});
  min-width: 140px;
  height: 120px;
  border-radius: 10px;
  padding: 10px;
  text-align: center;

  .title,
  .subtitle {
    color: ${colors.greenTea};
    font-size: 12px;
    line-height: 15px;
    height: 15px;
    text-transform: capitalize;
  }

  .text {
    color: ${colors.white};
    font-size: 32px;
    line-height: 39px;
    align-self: center;
    width: 100%;
  }
`;
