import 'izitoast-react/dist/iziToast.css';
import 'react-hint/css/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';

import colors from 'Constants/colors';
import store from 'Core/store';
import { createGlobalStyle } from 'styled-components';
import WebFont from 'webfontloader';

import App from './App';

import './index.css';
import 'flexboxgrid/css/flexboxgrid.min.css';

WebFont.load({
  google: {
    families: ['Open Sans:400,600', 'sans-serif'],
  },
  typekit: {
    id: 'uua0mzk', // proxima-nova
  },
});

const GlobalStyle = createGlobalStyle`
html {
  overflow-y: scroll;
}
html, body {
  color: ${colors.greyAsher};
  background-color: ${colors.whitePale};
}
body *:focus {
  outline: 0;
}
body.user-is-tabbing *:focus {
  box-shadow: 0 0 0 3px ${colors.greenSeafoam};
}

a {
  color: ${colors.nexusGreen}
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1rem 0;
  padding: 0;
}

h3 {
  margin: 0.75rem 0;
}

input:disabled {
  color: ${colors.greyAsher};
  background: ${colors.greyPearl};
}

.toast {
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid ${colors.greyPearl};
}
`;

const render = Component =>
  // eslint-disable-next-line
  ReactDOM.render(
    <Provider store={store}>
      <GlobalStyle />
      <AppContainer>
        <Component />
      </AppContainer>
    </Provider>,
    document.getElementById('root'),
  );

render(App);
if (module.hot) {
  module.hot.accept('./App', () => render(App));
}
