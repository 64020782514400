import { createSagaTypes } from 'Services/utils';

const PREFIX = 'root/';

const types = {
  ENTERED_AS: PREFIX + 'ENTERED_AS',
  LOAD_MAX_SELL_TOKENS_LIMIT: createSagaTypes(PREFIX + 'LOAD_MAX_SELL_TOKENS_LIMIT'),
  LISTEN_FOR_TRANSFER_EVENTS: createSagaTypes(PREFIX + 'LISTEN_FOR_TRANSFER_EVENTS'),
  UPDATE_FROM_SENT_TRANSFER_EVENT: PREFIX + 'UPDATE_FROM_SENT_TRANSFER_EVENT',
  UPDATE_FROM_RECEIVED_TRANSFER_EVENT: PREFIX + 'UPDATE_FROM_RECEIVED_TRANSFER_EVENT',
  IS_LOCKED_FOR_MV: createSagaTypes(PREFIX + 'IS_LOCKED_FOR_MV'),
  LOAD_MEMBER_ROLES: createSagaTypes(PREFIX + 'LOAD_MEMBER_ROLES'),
  LOAD_NXM_TO_ETH_RATE: createSagaTypes(PREFIX + 'LOAD_NXM_TO_ETH_RATE'),
  LOAD_ETH_TO_USD_RATE: createSagaTypes(PREFIX + 'LOAD_ETH_TO_USD_RATE'),
  LOAD_ETH_TO_DAI_RATE: createSagaTypes(PREFIX + 'LOAD_ETH_TO_DAI_RATE'),
  LOAD_ETH_BALANCE: createSagaTypes(PREFIX + 'LOAD_ETH_BALANCE'),
  LOAD_NXM_BALANCE: createSagaTypes(PREFIX + 'LOAD_NXM_BALANCE'),
  LOAD_WNXM_BALANCE: createSagaTypes(PREFIX + 'LOAD_WNXM_BALANCE'),
  LOAD_NXM_TOTAL_BALANCE: createSagaTypes(PREFIX + 'LOAD_NXM_TOTAL_BALANCE'),
  LOAD_NXM_ALLOWANCE: createSagaTypes(PREFIX + 'LOAD_NXM_ALLOWANCE'),
  LOAD_TOTAL_SUPPLY: createSagaTypes(PREFIX + 'LOAD_TOTAL_SUPPLY'),
  SEND_SET_NXM_ALLOWANCE_TX: createSagaTypes(PREFIX + 'SEND_SET_NXM_ALLOWANCE_TX'),
  IS_MEMBER: createSagaTypes(PREFIX + 'IS_MEMBER'),
  CHANGE_IS_MEMBER_VALUE: PREFIX + 'CHANGE_IS_MEMBER_VALUE',
  NO_ACCOUNT_STATE_INIT: PREFIX + 'NO_ACCOUNT_STATE_INIT',
  LISTEN_FOR_MINED_BLOCKS: PREFIX + 'LISTEN_FOR_MINED_BLOCKS',
  LOAD_PRODUCT_CAPACITIES: createSagaTypes(PREFIX + 'LOAD_PRODUCT_CAPACITIES'),
  MINED_BLOCK: PREFIX + 'MINED_BLOCK',
  SET_CHAIN_ID: PREFIX + 'SET_CHAIN_ID',
  TOGGLE_USER_MENU: PREFIX + 'TOGGLE_USER_MENU',
  OPEN_USER_MENU: PREFIX + 'OPEN_USER_MENU',
  CLOSE_USER_MENU: PREFIX + 'CLOSE_USER_MENU',
  TOGGLE_NAV_MENU: PREFIX + 'TOGGLE_NAV_MENU',
  OPEN_NAV_MENU: PREFIX + 'OPEN_NAV_MENU',
  CLOSE_NAV_MENU: PREFIX + 'CLOSE_NAV_MENU',
  CLOSE_ALL_MENUS: PREFIX + 'CLOSE_ALL_MENUS',
  ADD_PENDING_TX: PREFIX + 'ADD_PENDING_TX',
  RESTORE_TX_HISTORY: PREFIX + 'RESTORE_TX_HISTORY',
  RESTORE_TX_HISTORY_VALUE: PREFIX + 'RESTORE_TX_HISTORY_VALUE',
  SAVE_TX_HISTORY: PREFIX + 'SAVE_TX_HISTORY',
  CLEAR_TX_HISTORY: PREFIX + 'CLEAR_TX_HISTORY',
  UPDATE_TX_HISTORY: PREFIX + 'UPDATE_TX_HISTORY',
  SET_ACCOUNT: PREFIX + 'SET_ACCOUNT',
  SET_WALLET: PREFIX + 'SET_WALLET',
  SET_PROVIDER: PREFIX + 'SET_PROVIDER',
  SET_ETH_BALANCE: PREFIX + 'SET_ETH_BALANCE',
  LOAD_WNXM_RATE: createSagaTypes(PREFIX + 'LOAD_WNXM_RATE'),
  LOAD_PRODUCTS: createSagaTypes(PREFIX + 'LOAD_PRODUCTS'),
  LOAD_DAI_ALLOWANCE: createSagaTypes(PREFIX + 'LOAD_DAI_ALLOWANCE'),
  LOAD_DAI_BALANCE: createSagaTypes(PREFIX + 'LOAD_DAI_BALANCE'),
  SEND_SET_DAI_ALLOWANCE_TX: createSagaTypes(PREFIX + 'SEND_SET_DAI_ALLOWANCE_TX'),
};

export default types;
