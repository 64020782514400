import React from 'react';

import colors from 'Constants/colors';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 6px;
  background: ${colors.greenSeafoam};
  border-radius: 3px;
  position: relative;
`;

const Fill = styled.div`
  background: ${colors.nexusGreen};
  height: 6px;
  border-radius: 3px;
  width: ${({ progress }) => {
    if (progress === 100) {
      return 'calc(100% - 14px)';
    }
    return progress + '%';
  }};
  transition: width ease-out 0.2s;
  position: absolute;
`;

const Indicator = styled.div`
  background: ${colors.greenSeafoam};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  top: -9px;
  left: ${({ progress }) => {
    if (!progress) {
      return 0;
    }
    if (progress === 100) {
      return 'calc(100% - 23px)';
    }
    return `calc(${progress}% - 12px)`;
  }};
  transition: left ease-out 0.2s;
  z-index: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerIndicator = styled.div`
  background: ${colors.nexusGreen};
  height: 12px;
  width: 12px;
  border-radius: 50%;
`;

const StepBar = ({ progress }) => (
  <Container>
    <Indicator progress={progress}>
      <InnerIndicator />
    </Indicator>
    <Fill progress={progress} />
  </Container>
);

StepBar.propTypes = {
  progress: PropTypes.number,
};

export default StepBar;
