import t from './types';

const actions = {
  isMember: () => ({
    type: t.IS_MEMBER.TRIGGER,
  }),

  loadMemberRoles: () => ({
    type: t.LOAD_MEMBER_ROLES.TRIGGER,
  }),

  changeIsMemberValue: isMember => ({
    type: t.CHANGE_IS_MEMBER_VALUE,
    payload: { isMember },
  }),

  isLockedForMV: () => ({
    type: t.IS_LOCKED_FOR_MV.TRIGGER,
  }),

  loadEthToUsdRate: () => ({
    type: t.LOAD_ETH_TO_USD_RATE.TRIGGER,
  }),

  loadEthToDaiRate: () => ({
    type: t.LOAD_ETH_TO_DAI_RATE.TRIGGER,
  }),

  loadNxmToEthRate: () => ({
    type: t.LOAD_NXM_TO_ETH_RATE.TRIGGER,
  }),

  loadETHBalance: ({ silentLoad } = {}) => ({
    type: t.LOAD_ETH_BALANCE.TRIGGER,
    payload: { silentLoad },
  }),

  loadNXMBalance: () => ({
    type: t.LOAD_NXM_BALANCE.TRIGGER,
  }),

  loadwNXMBalance: () => ({
    type: t.LOAD_WNXM_BALANCE.TRIGGER,
  }),

  loadNXMTotalBalance: () => ({
    type: t.LOAD_NXM_TOTAL_BALANCE.TRIGGER,
  }),

  loadNXMAllowance: () => ({
    type: t.LOAD_NXM_ALLOWANCE.TRIGGER,
  }),

  setNXMAllowance: ({ value }) => ({
    type: t.SEND_SET_NXM_ALLOWANCE_TX.TRIGGER,
    payload: { value },
  }),

  listenForTransferEvents: () => ({
    type: t.LISTEN_FOR_TRANSFER_EVENTS.TRIGGER,
  }),

  noAccountStateInit: () => ({ type: t.NO_ACCOUNT_STATE_INIT }),

  listenForMinedBlocks: () => ({
    type: t.LISTEN_FOR_MINED_BLOCKS,
  }),

  loadProductCapacities: () => ({ type: t.LOAD_PRODUCT_CAPACITIES.TRIGGER }),

  loadTotalSupply: () => ({
    type: t.LOAD_TOTAL_SUPPLY.TRIGGER,
  }),

  setChainId: chainId => ({ type: t.SET_CHAIN_ID, payload: { chainId } }),

  toggleUserMenu: () => ({ type: t.TOGGLE_USER_MENU }),

  openUserMenu: () => ({ type: t.OPEN_USER_MENU }),

  closeUserMenu: () => ({ type: t.CLOSE_USER_MENU }),

  toggleNavMenu: () => ({ type: t.TOGGLE_NAV_MENU }),

  openNavMenu: () => ({ type: t.OPEN_NAV_MENU }),

  closeNavMenu: () => ({ type: t.CLOSE_NAV_MENU }),

  closeAllMenus: () => ({ type: t.CLOSE_ALL_MENUS }),

  restoreTxHistory: () => ({ type: t.RESTORE_TX_HISTORY }),

  saveTxHistory: () => ({ type: t.SAVE_TX_HISTORY }),

  clearTxHistory: () => ({ type: t.CLEAR_TX_HISTORY }),

  setAccount: account => ({ type: t.SET_ACCOUNT, payload: { account } }),

  setWallet: wallet => ({ type: t.SET_WALLET, payload: { wallet } }),

  setProvider: provider => ({ type: t.SET_PROVIDER, payload: { provider } }),

  setETHBalance: balanceETH => ({
    type: t.SET_ETH_BALANCE,
    payload: { balanceETH },
  }),

  loadWnxmToEthRate: () => ({ type: t.LOAD_WNXM_RATE.TRIGGER }),

  loadProducts: () => ({
    type: t.LOAD_PRODUCTS.TRIGGER,
  }),

  loadDaiBalance: () => ({ type: t.LOAD_DAI_BALANCE.TRIGGER }),

  loadDaiAllowance: () => ({ type: t.LOAD_DAI_ALLOWANCE.TRIGGER }),

  setDaiAllowance: ({ value }) => ({
    type: t.SEND_SET_DAI_ALLOWANCE_TX.TRIGGER,
    payload: { value },
  }),
};

export default actions;
