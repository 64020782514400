import { formatUnits } from '@ethersproject/units';
import { IPFS } from 'Config';
import { ORDER_DIRECTION } from 'Constants/tableConfig';

export const shortenAddress = (s, start = 4, end = 4) =>
  s ? s.slice(0, start) + '...' + s.slice(-end) : '';

export const capitalize = s => (s ? s.charAt(0).toUpperCase() + s.slice(1) : '');

export const errorDisplay = e =>
  capitalize(
    (e.reason ||
      ('reason' in e && 'An unexpected error occured!') ||
      e.message ||
      'An unexpected error occured!') +
      ' ' +
      (e.code || ''),
  );

export const hexToString = hex => {
  if (!hex) {
    return '';
  }
  const string = Buffer.from(hex.replace(/^0x/, ''), 'hex').toString('utf8');
  return string.replace(/[^ -~]+/, ''); // strip non-printable chars
};

export const zeroPadRight = (bytes, length) => {
  return new Uint8Array(length).fill(0).map((x, i) => bytes[i] || x);
};

export const unique = (arr, keyProps) => {
  const kvArray = arr.map(entry => {
    const key = keyProps.map(k => entry[k]).join('|');
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
};

export const createReducer = (handlers, initialState) => {
  const reducer = (state = initialState, action) => {
    if (handlers[action.type]) {
      const diff = handlers[action.type]({ ...(action.payload || {}), state });
      if (!diff) {
        return state;
      }
      return { ...state, ...diff };
    }
    return state;
  };
  return reducer;
};

export const createSortableColumnReducer = (key, actionType, resetProps) => ({
  [actionType]: ({ column, state }) => {
    if (column !== state[key].column) {
      return {
        [key]: {
          column,
          order: ORDER_DIRECTION[0],
        },
        ...resetProps,
      };
    }
    const current = ORDER_DIRECTION.indexOf(state[key].order);
    const next = (current + 1) % ORDER_DIRECTION.length;
    const order = ORDER_DIRECTION[next];
    return { [key]: { column, order }, ...resetProps };
  },
});

export const getURLSearchParamsDict = params => {
  const dict = {};
  for (const [key, value] of params.entries()) {
    dict[key] = value;
  }
  return dict;
};

export const shortenNumeric = x => {
  const value = Number(x);
  if (value >= 1e9) {
    return Math.floor(value / 1e8) / 10 + 'b';
  }
  if (value >= 1e6) {
    return Math.floor(value / 1e5) / 10 + 'm';
  }
  if (value >= 1e3) {
    return Math.floor(value / 1e2) / 10 + 'k';
  }
  return value;
};

export function get(obj, path, fallback) {
  if (!obj) {
    return fallback;
  }
  return path.reduce(
    (acc, key) => (acc && acc[key]) || (fallback !== undefined && fallback) || (acc && acc[key]),
    obj,
  );
}

export const createSagaTypes = action => ({
  TRIGGER: action + '_TRIGGER',
  REQUESTED: action + '_REQUESTED',
  CONFIRMED: action + '_CONFIRMED',
  SUCCEEDED: action + '_SUCCEEDED',
  FAILED: action + '_FAILED',
  CANCELLED: action + '_CANCELLED',
  RESET: action + '_RESET',
});

const resetLoadingProps = (props, initialState) =>
  Array.isArray(props)
    ? {
        [props[0]]: initialState ? initialState[props[0]] : false,
        [props[1]]: initialState ? initialState[props[1]] : false,
      }
    : { [props]: initialState ? initialState[props] : false };

/**
 * createSagaRoutineReducer - Returns an object with handlers used in reducers
 * boilerplate saga actions.
 *
 * @param {Object} rootActionType - An object containing all boilerplate types
 * for a given saga, which can be obtained by calling createSagaTypes.
 * @param {string|string[]} loadingProp - The name(s) of the loading property/
 * properties. If a string is provided, then the REQUESTED action will set the
 * loading property unless silentLoad is provided in the payload. If an array
 * of strings is provided, the first one is going to be set by the REQUESTED
 * action. A CONFIRMED action will unset the first loading prop and set the
 * second one to true instead. Both will be set to false on FAILED, SUCCEDED
 * CANCELLED and RESET actions. Other elements of the array will be ignored.
 * @param {string} errorProp - The name of the error property. Whenever a saga
 * fails, the error message will be set on this property.
 * @param {string[]} payloadProps - The allowed props that a SUCCEEDED action
 * must set from the given payload.
 * @param {Object} initialState - The initial state that reducers can use to
 * reset states.
 */
export const createSagaRoutineReducer = (
  rootActionType,
  loadingProp,
  errorProp,
  payloadProps,
  initialState,
) => ({
  [rootActionType.REQUESTED]: ({ silentLoad }) => {
    if (silentLoad) {
      return {};
    }
    return {
      [Array.isArray(loadingProp) ? loadingProp[0] : loadingProp]: true,
    };
  },
  [rootActionType.CONFIRMED]: () => {
    if (!Array.isArray(loadingProp)) {
      return {};
    }
    return {
      [loadingProp[0]]: false,
      [loadingProp[1]]: true,
    };
  },
  [rootActionType.SUCCEEDED]: payload => {
    const fromPayload = Object.keys(payload).reduce((acc, key) => {
      if (payloadProps && payloadProps.some(prop => key === prop)) {
        return { ...acc, [key]: payload[key] };
      }
      return acc;
    }, {});
    return {
      ...fromPayload,
      ...resetLoadingProps(loadingProp),
      [errorProp]: '',
    };
  },
  [rootActionType.FAILED]: ({ error }) => ({
    [errorProp]: errorDisplay(error),
    ...resetLoadingProps(loadingProp),
  }),
  [rootActionType.CANCELLED]: () => ({
    ...resetLoadingProps(loadingProp),
  }),
  [rootActionType.RESET]: () => {
    if (!initialState) {
      throw new Error(`${rootActionType.RESET} reducer was called without an "initialState".`);
    }
    const fromInitialState = Object.keys(initialState).reduce((acc, key) => {
      if (payloadProps && payloadProps.some(prop => key === prop)) {
        return { ...acc, [key]: initialState[key] };
      }
      return acc;
    }, {});
    return {
      ...fromInitialState,
      ...resetLoadingProps(loadingProp, initialState),
      [errorProp]: '',
    };
  },
});

const resetLoadingPropsById = ({ state, waitingProp, loadingProp, id }) => ({
  [waitingProp]: {
    ...state[waitingProp],
    [id]: false,
  },
  [loadingProp]: {
    ...state[loadingProp],
    [id]: false,
  },
});

export const createSagaMultipleIdReducer = (
  rootActionType,
  [waitingProp, loadingProp],
  errorProp,
) => ({
  [rootActionType.REQUESTED]: ({ id, state }) => {
    return {
      [waitingProp]: {
        ...state[waitingProp],
        [id]: true,
      },
    };
  },
  [rootActionType.CONFIRMED]: ({ id, state }) => {
    return {
      [waitingProp]: {
        ...state[waitingProp],
        [id]: false,
      },
      [loadingProp]: {
        ...state[loadingProp],
        [id]: true,
      },
    };
  },
  [rootActionType.SUCCEEDED]: ({ id, state }) => {
    return {
      ...resetLoadingPropsById({ state, waitingProp, loadingProp, id }),
      [errorProp]: {
        ...state[errorProp],
        [id]: '',
      },
    };
  },
  [rootActionType.FAILED]: ({ id, error, state }) => ({
    [errorProp]: {
      [id]: errorDisplay(error),
    },
    ...resetLoadingPropsById({ state, waitingProp, loadingProp, id }),
  }),
  [rootActionType.CANCELLED]: ({ id, state }) => {
    return {
      ...resetLoadingPropsById({ state, waitingProp, loadingProp, id }),
    };
  },
  [rootActionType.RESET]: ({ id, state }) => {
    return {
      ...resetLoadingPropsById({ state, waitingProp, loadingProp, id }),
      [errorProp]: {
        ...state[errorProp],
        [id]: '',
      },
    };
  },
});

export const displayFormat = (x, noOfDecimals = 2) => {
  const str = typeof x === 'number' ? x.toString() : x;
  const [integerPart, decimalPart] = str.split('.');
  const decimals = decimalPart ? decimalPart.slice(0, noOfDecimals).padEnd(noOfDecimals, '0') : '';
  const allZerosOrEmpty = /^0*$/;
  if (allZerosOrEmpty.test(decimals)) {
    return integerPart;
  }
  return integerPart + '.' + decimals;
};

export const displayEthFormatForBigInt = (n, noOfDecimals = 18) => {
  const weiDecimals = 18;
  const nArray = n.toString().split('');
  const integerPart =
    nArray.length - weiDecimals > 0 ? nArray.slice(0, nArray.length - weiDecimals).join('') : '0';
  const decimalPart =
    nArray.length - weiDecimals > 0
      ? nArray.slice(nArray.length - weiDecimals).join('')
      : nArray.join('').padStart(weiDecimals, '0');

  const allZerosOrEmpty = /^0*$/;
  if (allZerosOrEmpty.test(decimalPart)) {
    return integerPart;
  }

  return integerPart + '.' + decimalPart.slice(0, noOfDecimals);
};

export const formatUnitsDisplay = (value, noOfDecimals) =>
  value && displayFormat(formatUnits(value, noOfDecimals), noOfDecimals);

export const makeGroupsOf = (n, arr) => {
  const group = [];
  for (let i = 0, j = 0; i < arr.length; i++) {
    if (i >= n && i % n === 0) {
      j++;
    }
    group[j] = group[j] || [];
    group[j].push(arr[i]);
  }
  const incompleteGroupCount = n - group[group.length - 1].length;
  for (let i = 0; i < incompleteGroupCount; i++) {
    group[group.length - 1].push('');
  }
  return group;
};

export const ipfsLink = hash => IPFS.GATEWAY + hash;

export const governanceContractsMap = {
  AS: 'Assessment',
  CD: 'LegacyClaimsData',
  CR: 'LegacyClaimsReward',
  GV: 'Governance',
  CI: 'IndividualClaims',
  MC: 'MCR',
  MR: 'MemberRoles',
  MS: 'NXMaster',
  P1: 'Pool',
  PC: 'ProposalCategory',
  PS: 'LegacyPooledStaking',
  QD: 'LegacyQuotationData',
  QT: 'Quotation',
  TC: 'TokenController',
  TK: 'NXMToken',
  CG: 'YieldTokenIncidents',
  CO: 'Cover',
  GW: 'LegacyGateway',
  RA: 'Ramm',
};

// [todo] Yes, this can be replaced with the map above, fow now avoid conflicts.
export const codeToContractName = code => {
  return governanceContractsMap[code];
};

export async function readFile(file) {
  const result = await new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
  return result;
}

export const separatorsFormat = (x, decimals) =>
  Number(x).toLocaleString('en', {
    maximumFractionDigits: decimals || 18,
    minimumFractionDigits: decimals || 0,
  });

export const checkHttpUrl = url => {
  let givenURL;
  try {
    givenURL = new URL(url);
  } catch (error) {
    return false;
  }
  return givenURL.protocol === 'http:' || givenURL.protocol === 'https:';
};

export const toKebabCase = s => s.toLowerCase().replace(' & ', ' ').replace(' ', '-');
