import * as Sentry from '@sentry/react';
import { Cookies } from 'react-cookie-consent';
import TagManager from 'react-gtm-module';

import packageJson from '../../package.json';

export const initGTM = () => {
  if (process.env.REACT_APP_GTM_CONTAINER_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
    };

    TagManager.initialize(tagManagerArgs);
  }
};

export const clearGTM = () => {
  Cookies.remove('_ga', {
    path: '/',
  });
  Cookies.remove('_ga_FFGP39H1K5', {
    path: '/',
  });
  Cookies.remove('_gat', {
    path: '/',
  });
  Cookies.remove('_gid', {
    path: '/',
  });
};

/* eslint-disable */
export const consentAnalytics = () => {
  // @ts-ignore
  gtag('consent', 'update', {
    analytics_storage: 'granted',
  });
};

export const initSentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    // This sets the sample rate to be 100% on preview environments and 10% in production
    const replaysSessionSampleRate = process.env.REACT_APP_SENTRY_ENV === 'preview' ? 1.0 : 0.1;

    // Sample 100% of sessions in which an error occurs
    const replaysOnErrorSampleRate = 1.0;

    // Used for performance monitoring, sample 100% on preview environments and 10% in production
    const tracesSampleRate = process.env.REACT_APP_SENTRY_ENV === 'preview' ? 1.0 : 0.1;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      replaysSessionSampleRate,
      replaysOnErrorSampleRate,
      tracesSampleRate,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      environment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
      release: packageJson.version,
    });
  }
};
