// for dev/debug purposes

import { parseEther } from '@ethersproject/units';

// rpc url for JsonRpcProvider e.g. from a Tenderly fork
export const DEV_RPC_URL = __DEV__ && ('' || process.env.REACT_APP_DEV_RPC_URL);
// account address to be used in place of wallet
export const DEV_ADDRESS = __DEV__ && ('' || process.env.REACT_APP_DEV_ADDRESS);
// chain id e.g. '1' for mainnet
export const DEV_CHAIN_ID = __DEV__ && ('' || process.env.REACT_APP_DEV_CHAIN_ID);
// start block number of the fork - should be a number
export const DEV_FORK_BLOCK = '' || process.env.REACT_APP_DEV_FORK_BLOCK;
// erigon url
export const DEV_ERIGON_URL = '' || process.env.REACT_APP_DEV_ERIGON_URL;

export const DEV_MOCK_QUOTE = {
  premiumInCoverAsset: parseEther('0.1'),
  premiumInPaymentAsset: parseEther('0.2'),
  premiumInNXM: parseEther('0.001'),
  poolAllocationRequests: [
    {
      poolId: '2',
      skip: false,
      coverAmountInAsset: parseEther('1'),
    },
  ],
};

export const DEV_MOCK_CAPACITIES = [
  {
    capacityETH: '63308592105244716702',
    capacityDAI: '106581915697645948080000',
    netStakedNXM: '970878371933067603610',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 0,
  },
  {
    capacityETH: '32742110178301684560',
    capacityDAI: '55122325591847816563000',
    netStakedNXM: '502121522000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 1,
  },
  {
    capacityETH: '1064453671248348002700',
    capacityDAI: '1792039716574938976600000',
    netStakedNXM: '8162044147354823761600',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 2,
  },
  {
    capacityETH: '557205957869609334840',
    capacityDAI: '938072960604739700740000',
    netStakedNXM: '8545115208193597797400',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 3,
  },
  {
    capacityETH: '4211883564457701964800',
    capacityDAI: '7090832445761211408900000',
    netStakedNXM: '34880003392272973406000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 4,
  },
  {
    capacityETH: '287991306941022325200',
    capacityDAI: '484842012392502104750000',
    netStakedNXM: '52980196369690794295000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 5,
  },
  {
    capacityETH: '37212900834379926362',
    capacityDAI: '62649035900233886702000',
    netStakedNXM: '570684000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 6,
  },
  {
    capacityETH: '3958804985528452731800',
    capacityDAI: '6664767059257652034200000',
    netStakedNXM: '30355422631740725911000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 7,
  },
  {
    capacityETH: '445539235960194405100',
    capacityDAI: '750078681392269912120000',
    netStakedNXM: '6832633512402831731700',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 8,
  },
  {
    capacityETH: '2922087749781607261400',
    capacityDAI: '4919422464656992982000000',
    netStakedNXM: '22406056609482720113000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 9,
  },
  {
    capacityETH: '1121467055866221611000',
    capacityDAI: '1888023461449219028900000',
    netStakedNXM: '17198425571775856000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 10,
  },
  {
    capacityETH: '10940853588224858258000',
    capacityDAI: '18419255523198805128000000',
    netStakedNXM: '90334198677480536712000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 11,
  },
  {
    capacityETH: '3623413326284404945600',
    capacityDAI: '6100125130531498755200000',
    netStakedNXM: '66122821660689965250000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 12,
  },
  {
    capacityETH: '1010829119191681318900',
    capacityDAI: '1701761173069717198800000',
    netStakedNXM: '7750860482822632259300',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 13,
  },
  {
    capacityETH: '11720348173508353276000',
    capacityDAI: '19731558062439356346000000',
    netStakedNXM: '91249783606981256872000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 14,
  },
  {
    capacityETH: '1701457837721854776500',
    capacityDAI: '2864455357366041139600000',
    netStakedNXM: '13046480426021839429000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 15,
  },
  {
    capacityETH: '1716610030243465169200',
    capacityDAI: '2889964528432238201300000',
    netStakedNXM: '26325329564052657372000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 16,
  },
  {
    capacityETH: '549218776656929783740',
    capacityDAI: '924626301212023407360000',
    netStakedNXM: '8422626597497447296900',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 17,
  },
  {
    capacityETH: '10901079527358449160000',
    capacityDAI: '18352294697483878786000000',
    netStakedNXM: '93733590460260777701000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 18,
  },
  {
    capacityETH: '164441031206670665550',
    capacityDAI: '276841413503039892170000',
    netStakedNXM: '2521810000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 19,
  },
  {
    capacityETH: '239457697687820217580',
    capacityDAI: '403134223956329597430000',
    netStakedNXM: '3672239295600000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 20,
  },
  {
    capacityETH: '12707291441072221708000',
    capacityDAI: '21393106687102849725000000',
    netStakedNXM: '173334148603248758870000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 21,
  },
  {
    capacityETH: '1977046725184473766400',
    capacityDAI: '3328417524174605239800000',
    netStakedNXM: '22827472398302829791000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 22,
  },
  {
    capacityETH: '10446880890666296067000',
    capacityDAI: '17587637654954285538000000',
    netStakedNXM: '84044589961843432488000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 23,
  },
  {
    capacityETH: '1871343895404465227600',
    capacityDAI: '3150463636432372982000000',
    netStakedNXM: '14349136934500377509000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 24,
  },
  {
    capacityETH: '137581854592452373700',
    capacityDAI: '231623183205866047740000',
    netStakedNXM: '2109907084526467236300',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 25,
  },
  {
    capacityETH: '142044021741142109390',
    capacityDAI: '239135375580636221810000',
    netStakedNXM: '2178337315440640603300',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 26,
  },
  {
    capacityETH: '461587390847159725060',
    capacityDAI: '777096232002491161570000',
    netStakedNXM: '7078742389113988621500',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 27,
  },
  {
    capacityETH: '11538991714143274568',
    capacityDAI: '19426239017733084005000',
    netStakedNXM: '176957931248142307100',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 28,
  },
  {
    capacityETH: '884189209001289382800',
    capacityDAI: '1488559081804894666200000',
    netStakedNXM: '39656216989788815664000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 29,
  },
  {
    capacityETH: '5471736027552268469600',
    capacityDAI: '9211831895406098536300000',
    netStakedNXM: '51294417669659901583000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 30,
  },
  {
    capacityETH: '93248642295291790514',
    capacityDAI: '156986889165291992310000',
    netStakedNXM: '1430028484382008354200',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 31,
  },
  {
    capacityETH: '1273119641953266863400',
    capacityDAI: '2143335143610611059800000',
    netStakedNXM: '19524116461171456445000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 32,
  },
  {
    capacityETH: '3188977951528743316000',
    capacityDAI: '5368740132878909267800000',
    netStakedNXM: '49710924652457392455000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 33,
  },
  {
    capacityETH: '5033324461226648004000',
    capacityDAI: '8473752859857317470000000',
    netStakedNXM: '38594649603033714142000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 34,
  },
  {
    capacityETH: '269646866415568435380',
    capacityDAI: '453958595962168446240000',
    netStakedNXM: '4135209802599802928500',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 35,
  },
  {
    capacityETH: '309989905931596404740',
    capacityDAI: '521877314317743404330000',
    netStakedNXM: '4753896511965241651600',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 36,
  },
  {
    capacityETH: '305191024564633913140',
    capacityDAI: '513798253446411409520000',
    netStakedNXM: '4680302549854836446100',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 37,
  },
  {
    capacityETH: '1314584287794959487200',
    capacityDAI: '2213142119892522579800000',
    netStakedNXM: '20160003731901470477000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 38,
  },
  {
    capacityETH: '245101321346929196800',
    capacityDAI: '412635433840517848450000',
    netStakedNXM: '3758787929328102514500',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 39,
  },
  {
    capacityETH: '62543161725731162204',
    capacityDAI: '105293290671107530770000',
    netStakedNXM: '959140000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 40,
  },
  {
    capacityETH: '385668917282337381580',
    capacityDAI: '649285202246397046380000',
    netStakedNXM: '6005575520075612652300',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 41,
  },
  {
    capacityETH: '676747968526986653600',
    capacityDAI: '1139325524885918371700000',
    netStakedNXM: '10382687342480988882000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 42,
  },
  {
    capacityETH: '2489118837862373150600',
    capacityDAI: '4190506301221187478900000',
    netStakedNXM: '38279603334513514068000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 43,
  },
  {
    capacityETH: '276598003252730259540',
    capacityDAI: '465661043540682940020000',
    netStakedNXM: '4241810000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 44,
  },
  {
    capacityETH: '3256696414299986481800',
    capacityDAI: '5482746198252473075000000',
    netStakedNXM: '52228566140233798370000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 45,
  },
  {
    capacityETH: '1715628149392220139200',
    capacityDAI: '2888311502537450359800000',
    netStakedNXM: '26310271783574704594000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 46,
  },
  {
    capacityETH: '2357633687456991289200',
    capacityDAI: '3969147102572419214500000',
    netStakedNXM: '36155843622104041285000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 47,
  },
  {
    capacityETH: '400312056981703503720',
    capacityDAI: '673937367601649023920000',
    netStakedNXM: '6277066197181312150900',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 48,
  },
  {
    capacityETH: '1270155028391016084500',
    capacityDAI: '2138344127663792462000000',
    netStakedNXM: '19478652187002417058000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 49,
  },
  {
    capacityETH: '597311644790556487420',
    capacityDAI: '1005592088739804521000000',
    netStakedNXM: '9160161961378948581400',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 50,
  },
  {
    capacityETH: '6907337162895766662000',
    capacityDAI: '11628709511769165579000000',
    netStakedNXM: '196049764994999562570000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 51,
  },
  {
    capacityETH: '7926510553499298140400',
    capacityDAI: '13344518516303806698000000',
    netStakedNXM: '126171055996342386880000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 52,
  },
  {
    capacityETH: '289150246571347038200',
    capacityDAI: '486793122058194646710000',
    netStakedNXM: '5558084787156288215400',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 53,
  },
  {
    capacityETH: '1616633883344734005400',
    capacityDAI: '2721651683268630794100000',
    netStakedNXM: '29324795384118022727000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 54,
  },
  {
    capacityETH: '434585896854039050120',
    capacityDAI: '731638406124747975760000',
    netStakedNXM: '6664656944215432039400',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 55,
  },
  {
    capacityETH: '310905941045755990740',
    capacityDAI: '523419486937079733230000',
    netStakedNXM: '4884651862456800478400',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 56,
  },
  {
    capacityETH: '166349143298418295290',
    capacityDAI: '280053777502008948450000',
    netStakedNXM: '2551072138036841088700',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 57,
  },
  {
    capacityETH: '3523625078339969019000',
    capacityDAI: '5932128618899255911700000',
    netStakedNXM: '57432376224452946014000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 58,
  },
  {
    capacityETH: '897595078977596722400',
    capacityDAI: '1511128266431415886500000',
    netStakedNXM: '13765203425850750334000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 59,
  },
  {
    capacityETH: '177172480694142070410',
    capacityDAI: '298275190986619421890000',
    netStakedNXM: '2717055045572895003200',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 60,
  },
  {
    capacityETH: '7625829582540023218600',
    capacityDAI: '12838313073519795314000000',
    netStakedNXM: '121942715518088416010000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 61,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '62725636007989239095000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 62,
  },
  {
    capacityETH: '229031502604655705820',
    capacityDAI: '385581411479411616050000',
    netStakedNXM: '3512346823327492697700',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 63,
  },
  {
    capacityETH: '5397327665271288860000',
    capacityDAI: '9086563183338589639600000',
    netStakedNXM: '100615828650868825360000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 64,
  },
  {
    capacityETH: '6726579893962618402400',
    capacityDAI: '11324399222146195058000000',
    netStakedNXM: '103156470851088584010000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 65,
  },
  {
    capacityETH: '1316044963023250314000',
    capacityDAI: '2215601210497231121700000',
    netStakedNXM: '231372262523127657770000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 66,
  },
  {
    capacityETH: '303583522558958661600',
    capacityDAI: '511091975553390901530000',
    netStakedNXM: '5115719944416660199100',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 67,
  },
  {
    capacityETH: '483365563096184243140',
    capacityDAI: '813760438889853773870000',
    netStakedNXM: '7956395350607080928000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 68,
  },
  {
    capacityETH: '5650843440273310596000',
    capacityDAI: '9513364602557805006800000',
    netStakedNXM: '99005659018504295631000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 69,
  },
  {
    capacityETH: '1625106627353678804800',
    capacityDAI: '2735915802208250652000000',
    netStakedNXM: '24922065459295988551000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 70,
  },
  {
    capacityETH: '190791645808089390080',
    capacityDAI: '321203464381705735920000',
    netStakedNXM: '6134085632299169570500',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 71,
  },
  {
    capacityETH: '2527684283052602776400',
    capacityDAI: '4255432386155663860300000',
    netStakedNXM: '40069642366993336057000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 72,
  },
  {
    capacityETH: '276269446137486451880',
    capacityDAI: '465107907771993555250000',
    netStakedNXM: '5469742238623761975500',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 73,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '8767140752745568105200',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 74,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '48353130209127335394000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 75,
  },
  {
    capacityETH: '19562262566173185000',
    capacityDAI: '32933656401914485092000',
    netStakedNXM: '300000000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 76,
  },
  {
    capacityETH: '90941777725639293340',
    capacityDAI: '153103213396924684490000',
    netStakedNXM: '2023412843161475601300',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 77,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '2584602377716974300700',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 78,
  },
  {
    capacityETH: '3501753735194327140600',
    capacityDAI: '5895307556009911178000000',
    netStakedNXM: '62698955196532537107000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 79,
  },
  {
    capacityETH: '3491378499374126172400',
    capacityDAI: '5877840534982282820200000',
    netStakedNXM: '55931381199443582576000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 80,
  },
  {
    capacityETH: '4885596025229634892000',
    capacityDAI: '8225047602198075543400000',
    netStakedNXM: '76094955148129973363000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 81,
  },
  {
    capacityETH: '2140595870920313300000',
    capacityDAI: '3603757421707963602100000',
    netStakedNXM: '38703626817958900839000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 82,
  },
  {
    capacityETH: '659463123533813966200',
    capacityDAI: '1110225969349339157600000',
    netStakedNXM: '106287029099307554540000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 83,
  },
  {
    capacityETH: '5707341472132607272000',
    capacityDAI: '9608480735589163575100000',
    netStakedNXM: '124227698083912315060000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 84,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '61067035367838551942000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 85,
  },
  {
    capacityETH: '1233161136409597927600',
    capacityDAI: '2076063799743426535100000',
    netStakedNXM: '18911326829984857291000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 86,
  },
  {
    capacityETH: '1888969687055082143700',
    capacityDAI: '3180137185903941385700000',
    netStakedNXM: '28968576829984857291000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 87,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '742786614820888801710',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 88,
  },
  {
    capacityETH: '254099495110314433700',
    capacityDAI: '427784129548164840650000',
    netStakedNXM: '20630239716082209856000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 89,
  },
  {
    capacityETH: '1190253993465998175900',
    capacityDAI: '2003828336278385454100000',
    netStakedNXM: '21522643718924514394000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 90,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '191076814609619117670000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 91,
  },
  {
    capacityETH: '200964427493134874380',
    capacityDAI: '338329647793960966310000',
    netStakedNXM: '3081920000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 92,
  },
  {
    capacityETH: '209984978938547409020',
    capacityDAI: '353516017001207779630000',
    netStakedNXM: '3220256014275936933600',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 93,
  },
  {
    capacityETH: '625998378862436995160',
    capacityDAI: '1053887066890750677900000',
    netStakedNXM: '12936478733105799909000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 94,
  },
  {
    capacityETH: '392357875642371818400',
    capacityDAI: '660546264486560074080000',
    netStakedNXM: '6017062816458133848700',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 95,
  },
  {
    capacityETH: '2679077100568415322400',
    capacityDAI: '4510306740127637371700000',
    netStakedNXM: '41085387104470849170000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 96,
  },
  {
    capacityETH: '3308563832819245242400',
    capacityDAI: '5570066554688202229700000',
    netStakedNXM: '51218120023515042856000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 97,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '14881291958962528774000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 98,
  },
  {
    capacityETH: '4096753497574168320600',
    capacityDAI: '6897007521295254668600000',
    netStakedNXM: '62830929889348317909000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 99,
  },
  {
    capacityETH: '2431003088046894115200',
    capacityDAI: '4092666691437388028800000',
    netStakedNXM: '37281011025542929659000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 100,
  },
  {
    capacityETH: '2438903051032887770200',
    capacityDAI: '4105966516326685247600000',
    netStakedNXM: '37402162088094162923000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 101,
  },
  {
    capacityETH: '2403038902994903597800',
    capacityDAI: '4045588146256508691700000',
    netStakedNXM: '36852162088094162923000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 102,
  },
  {
    capacityETH: '1229765585248652822100',
    capacityDAI: '2070347287409977983000000',
    netStakedNXM: '18859253847893051879000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 103,
  },
  {
    capacityETH: '1095620283760103406000',
    capacityDAI: '1844509644539644448600000',
    netStakedNXM: '45951560157587596549000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 104,
  },
  {
    capacityETH: '1077829537075337529000',
    capacityDAI: '1814558388315186331400000',
    netStakedNXM: '20172904835915369963000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 105,
  },
  {
    capacityETH: '778552336782277825200',
    capacityDAI: '1310716235597026591700000',
    netStakedNXM: '17198146170292277766000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 106,
  },
  {
    capacityETH: '1978603830444642937600',
    capacityDAI: '3331038957633365776500000',
    netStakedNXM: '34663325373514962219000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 107,
  },
  {
    capacityETH: '366421593388046851300',
    capacityDAI: '616881754554869360260000',
    netStakedNXM: '11488181760990298459000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 108,
  },
  {
    capacityETH: '2962241942794882513800',
    capacityDAI: '4987023254254924348600000',
    netStakedNXM: '51184891290125437872000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 109,
  },
  {
    capacityETH: '1471603805311321463600',
    capacityDAI: '2477489192261353665200000',
    netStakedNXM: '22568000000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 110,
  },
  {
    capacityETH: '2878913232038358189600',
    capacityDAI: '4846736867688611238600000',
    netStakedNXM: '85732187913097246580000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 111,
  },
  {
    capacityETH: '1908816994368048124400',
    capacityDAI: '3213550723695766131400000',
    netStakedNXM: '40079737913097246580000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 112,
  },
  {
    capacityETH: '3260377094362197500000',
    capacityDAI: '5488942733652414182100000',
    netStakedNXM: '50000000000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 113,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '186811868076516868820000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 114,
  },
  {
    capacityETH: '2752818621677330190400',
    capacityDAI: '4634452805059563612900000',
    netStakedNXM: '50892797913097246580000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 115,
  },
  {
    capacityETH: '0',
    capacityDAI: '0',
    netStakedNXM: '70951187913097246580000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 116,
  },
  {
    capacityETH: '3267472697044496937400',
    capacityDAI: '5500888393818914354500000',
    netStakedNXM: '51019737913097246580000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 117,
  },
  {
    capacityETH: '4372800737215021803600',
    capacityDAI: '7361741337758248124700000',
    netStakedNXM: '70703427913097246580000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 118,
  },
  {
    capacityETH: '3322626144626890240000',
    capacityDAI: '5593740878847943972100000',
    netStakedNXM: '51200000000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 119,
  },
  {
    capacityETH: '4869191059129927267400',
    capacityDAI: '8197429349197675495200000',
    netStakedNXM: '80493000000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 120,
  },
  {
    capacityETH: '935378204201633403000',
    capacityDAI: '1574737292213056453800000',
    netStakedNXM: '97276790000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 121,
  },
  {
    capacityETH: '2340146119564278803400',
    capacityDAI: '3939706256947556025200000',
    netStakedNXM: '62516390000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 122,
  },
  {
    capacityETH: '2847563782794897503400',
    capacityDAI: '4793959128596268461400000',
    netStakedNXM: '66761850000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 123,
  },
  {
    capacityETH: '4349548471956139594000',
    capacityDAI: '7322595451028030091400000',
    netStakedNXM: '77238588147752260023000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 124,
  },
  {
    capacityETH: '3044702492821815300600',
    capacityDAI: '5125848066165786125400000',
    netStakedNXM: '50791640000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 125,
  },
  {
    capacityETH: '42951555765708717426',
    capacityDAI: '72310233784590173952000',
    netStakedNXM: '658690000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 126,
  },
  {
    capacityETH: '2047466806169344439800',
    capacityDAI: '3446971844928945672100000',
    netStakedNXM: '96269030000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 127,
  },
  {
    capacityETH: '2117584636249441450000',
    capacityDAI: '3565017317209787117600000',
    netStakedNXM: '51000000000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 128,
  },
  {
    capacityETH: '2675056437056106583200',
    capacityDAI: '4503537832381195951400000',
    netStakedNXM: '50132950000000000000000',
    capacityLimit: 'STAKED_CAPACITY',
    productId: 129,
  },
];
