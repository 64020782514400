export const DRAFT_STATUS = [0, 1];

export const CLOSED_STATUS = [3, 4, 5, 6];

export const PROPOSAL_STATUS = {
  DRAFT: 0,
  AWAITING_SOLUTION: 1,
  VOTING_STARTED: 2,
  ACCEPTED: 3,
  REJECTED: 4,
  MAJORITY_NOT_REACHED_BUT_ACCEPTED: 5,
  DENIED: 6,
};

export const ACTION_STATUS = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  EXECUTED: 3,
  NO_ACTION: 4,
};

export const ACTION_STATUS_DISPLAY = ['Pending', 'Accepted', 'Rejected', 'Executed', 'No Action'];

export const ACTION_STATUS_COLOR = ['warning', 'success', 'danger', 'success', 'passive'];

export const PROPOSAL_STATUS_DISPLAY = [
  'Draft',
  'Draft',
  'Pending',
  'Accepted',
  'Rejected',
  'Accepted',
  'Rejected',
];

export const PROPOSAL_STATUS_COLOR = [
  'passive',
  'passive',
  'warning',
  'success',
  'danger',
  'success',
  'danger',
];

export const MEMBER_ROLES = {
  UNASSIGNED: 0,
  ADVISORY_BOARD: 1,
  MEMBER: 2,
  OWNER: 3,
};

export const MEMBER_ROLES_DISPLAY = {
  0: 'Unassigned',
  1: 'Advisory Board',
  2: 'Member',
  3: 'Owner',
};
