import { PRODUCT_TYPES } from 'Constants/coverV2';
import v1Products from 'Constants/v1Products';
import { Product, ProductSDK, ProductType } from 'Features/Membership/ducks/covers/initialState';
import { shortenAddress } from 'Services/utils';

import { get } from './utils';

export const getProductType = (address: string): ProductType =>
  PRODUCT_TYPES[get(v1Products, [address, 'type']) as keyof typeof PRODUCT_TYPES];

export const getProductName = (address: string) =>
  get(v1Products, [address, 'name']) || (address && shortenAddress(address, 6)) || 'Unknown';

export const parseProductsArrayToMap = (products: ProductSDK[]): Record<string, Product> => {
  return products.reduce((productsMap: Record<string, Product>, product: ProductSDK) => {
    productsMap[product.id] = {
      ...product,
      productId: product.id,
      type: product.productType,
      deprecated: product.isDeprecated,
    };
    return productsMap;
  }, {});
};
