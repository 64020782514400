import React from 'react';

import GradientShimmer from 'Components/GradientShimmer';

import { SmallDetailCardWithPatternStyles } from './SmallDetailCardWithPattern.styles';

export type SmallDetailCardWithPatternProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  text: React.ReactNode;
  patternUrl: string;
  loading?: boolean;
};

export const SmallDetailCardWithPattern = ({
  title,
  subtitle,
  text,
  patternUrl,
  loading,
  ...props
}: SmallDetailCardWithPatternProps) => {
  return (
    <SmallDetailCardWithPatternStyles patternUrl={patternUrl} {...props}>
      <span className="title">{title}</span>
      {loading ? (
        <GradientShimmer height="2rem" width="100%" />
      ) : (
        <span className="text">{text}</span>
      )}
      <span className="subtitle">{subtitle}</span>
    </SmallDetailCardWithPatternStyles>
  );
};
