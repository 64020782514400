import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import User from 'Assets/user.svg';
import { AppLifecycleContext } from 'Components/AppLifecycle';
import Spinner from 'Components/Spinner';
import colors from 'Constants/colors';
import { selectAccount, selectPendingTransactions } from 'Services/rootDuck/selectors';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const ButtonContainer = styled.button`
  position: relative;
  background: none;
  color: ${colors.nexusBlue};
  border: 0;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-right: -12px;
  & > div {
    position: absolute;
  }
  & > svg {
    position: absolute;
    fill: ${colors.nexusBlue};
  }
`;

const MobileUserButton = ({ toggleUserMenu }) => {
  const pendingTransactions = useSelector(selectPendingTransactions);
  const account = useSelector(selectAccount);
  const { connectWallet } = useContext(AppLifecycleContext);
  return (
    <Container>
      <ButtonContainer onClick={account ? toggleUserMenu : connectWallet}>
        {pendingTransactions ? (
          <>
            <Spinner size="2rem" color={colors.nexusBlue} />
            <div>{pendingTransactions}</div>
          </>
        ) : (
          <User height="30" />
        )}
      </ButtonContainer>
    </Container>
  );
};

export default MobileUserButton;
