import React from 'react';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';

import HeroCard from 'Components/HeroCard';
import PageLayout from 'Components/PageLayout';
import SidebarLayout from 'Components/SidebarLayout';
import Stepper from 'Components/Stepper';

import { BreadcrumbItem } from './Breadcrumb';

const WizardWithSidebar = ({
  routes,
  heading,
  breadcrumb,
}: {
  routes: Array<RouteType>;
  heading?: string;
  breadcrumb?: BreadcrumbItem[];
}) => {
  const match = useRouteMatch();
  const location = useLocation();
  const matchedRoutes = routes.map(r => ({
    ...r,
    path: match.path + '/' + r.path,
    url: match.url + '/' + r.path,
  }));
  const currentRouteIndex = matchedRoutes.findIndex(
    r => r.path === location.pathname || r.url === location.pathname,
  );
  const step = currentRouteIndex === -1 ? 0 : currentRouteIndex;
  return (
    <>
      <HeroCard heading={heading} breadcrumb={breadcrumb}>
        <Stepper currentStep={step} routes={matchedRoutes} search={location.search} />
      </HeroCard>
      <PageLayout>
        {matchedRoutes.map(route => {
          return (
            <Route
              path={route.path}
              key={route.path}
              render={props => (
                <SidebarLayout
                  components={{
                    Content: route.component,
                    Sidebar: route.sidebarComponent,
                  }}
                  routes={matchedRoutes}
                  step={step}
                  {...props}
                />
              )}
            />
          );
        })}
      </PageLayout>
      {currentRouteIndex === -1 ? (
        <>
          <Redirect to={matchedRoutes[0].url + (location.search || '')} />
        </>
      ) : null}
    </>
  );
};

export type RouteType = {
  title: string;
  path: string;
  component: () => JSX.Element;
  sidebarComponent: () => JSX.Element;
  nextText?: string;
};

export type SidebarProps = {
  step: number;
  routes: Array<RouteType>;
};

export default WizardWithSidebar;
