import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  width: ${full => (full ? `100%` : 'min-content')};
  & > button {
    margin-right: 0;
    padding: 0 0.5rem;
    box-sizing: border-box;
  }
  & > button:first-child:not(:last-child) {
    border-radius: 2rem 0 0 2rem;
    border-right: 0;
  }
  & > button:last-child:not(:first-child) {
    border-radius: 0 2rem 2rem 0;
  }
  & > button:not(:last-child):not(:first-child) {
    border-radius: 0;
    border-right: 0;
  }
`;

export default ButtonGroup;
