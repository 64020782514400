import colors from 'Constants/colors';
import type { IziToastSettings } from 'izitoast';

const toastOptions: IziToastSettings = {
  layout: 2,
  color: colors.white,
  position: 'topRight',
  class: 'toast',
  titleColor: colors.greyAsher,
  progressBarColor: colors.nexusGreen,
};

export default toastOptions;
