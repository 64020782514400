import type { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { Zero } from '@ethersproject/constants';
import { Provider } from '@ethersproject/providers';
import { CHAIN_ID } from 'Config';
import { Product } from 'Features/Membership/ducks/covers/initialState';

export type TxHistory = {
  nonce: number;
  from: string;
  hash: string;
  message: string;
  status: string;
};

export type ProductCapacity = {
  productId: number;
  capacityETH: BigNumber;
  capacityDAI: BigNumber;
};

export type RootDuckState = {
  account: string;
  balanceDAI: BigNumberish;
  balanceETH: BigNumber;
  balanceETHError: string;
  balanceETHLoading: boolean;
  balanceNXM: BigNumber;
  balanceNXMLoading: boolean;
  balancewNXM: BigNumber;
  balancewNXMLoading: boolean;
  chainId: number;
  daiAllowance: BigNumber;
  daiAllowanceError: string;
  daiAllowanceLoading: boolean;
  ethDaiRate: BigNumber;
  ethDaiRateDecimals: BigNumberish;
  ethDaiRateError: string;
  ethDaiRateLoading: boolean;
  ethUsdRate: BigNumber;
  ethUsdRateDecimals: BigNumberish;
  ethUsdRateError: string;
  ethUsdRateLoading: boolean;
  isLockedForMV: boolean | null;
  isLockedForMVError: string;
  isLockedForMVLoading: boolean;
  isMember: boolean;
  isMemberError: string;
  isMemberLoading: boolean;
  isNavMenuOpen: boolean;
  isUserMenuOpen: boolean;
  loadTotalSupplyError: string;
  memberRoles: Array<BigNumber>;
  memberRolesError: string;
  memberRolesLoading: boolean;
  nxmAllowance: BigNumber;
  nxmAllowanceError: string;
  nxmAllowanceLoading: boolean;
  nxmEthRate: BigNumber;
  nxmEthRateError: string;
  nxmEthRateLoading: boolean;
  productCapacities: Record<string, ProductCapacity>;
  productCapacitiesError: string;
  productCapacitiesLoading: boolean;
  products: Record<string | number, Product>;
  productsError: string;
  productsLoading: boolean;
  provider: Provider;
  setDaiAllowanceError: string;
  setDaiAllowanceLoading: boolean;
  setDaiAllowanceWaiting: boolean;
  setNxmAllowanceError: string;
  setNxmAllowanceLoading: boolean;
  setNxmAllowanceWaiting: boolean;
  totalBalanceNXM: BigNumber;
  totalBalanceNXMLoading: boolean;
  totalSupply: BigNumber;
  totalSupplyLoading: boolean;
  txHistory: Array<TxHistory>;
  wallet: any;
  wnxmToEthRate: BigNumber;
  wnxmToEthRateError: string;
  wnxmToEthRateLoading: boolean;
};

const initialState = {
  account: '',
  balanceDAI: Zero,
  balanceETH: Zero,
  balanceETHError: '',
  balanceETHLoading: false,
  balanceNXM: Zero,
  balanceNXMLoading: false,
  chainId: CHAIN_ID,
  daiAllowance: Zero,
  daiAllowanceError: '',
  daiAllowanceLoading: false,
  ethDaiRate: Zero,
  ethDaiRateDecimals: 18,
  ethDaiRateError: '',
  ethDaiRateLoading: false,
  ethUsdRate: Zero,
  ethUsdRateDecimals: 8,
  ethUsdRateError: '',
  ethUsdRateLoading: false,
  isLockedForMV: null,
  isLockedForMVError: '',
  isLockedForMVLoading: false,
  isMember: false,
  isMemberError: '',
  isMemberLoading: true,
  isNavMenuOpen: false,
  isUserMenuOpen: false,
  loadTotalSupplyError: '',
  memberRoles: [],
  memberRolesError: '',
  memberRolesLoading: false,
  nxmAllowance: Zero,
  nxmAllowanceError: '',
  nxmAllowanceLoading: false,
  nxmEthRate: Zero,
  nxmEthRateError: '',
  nxmEthRateLoading: true,
  productCapacities: {},
  productCapacitiesError: '',
  productCapacitiesLoading: false,
  products: {},
  productsError: '',
  productsLoading: false,
  provider: null,
  setDaiAllowanceError: '',
  setDaiAllowanceLoading: false,
  setDaiAllowanceWaiting: false,
  setNxmAllowanceError: '',
  setNxmAllowanceLoading: false,
  setNxmAllowanceWaiting: false,
  totalBalanceNXM: Zero,
  totalBalanceNXMLoading: false,
  totalSupply: Zero,
  totalSupplyLoading: false,
  txHistory: [],
  wallet: null,
  wnxmToEthRate: Zero,
  wnxmToEthRateError: '',
  wnxmToEthRateLoading: false,
};

export default initialState;
