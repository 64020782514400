import React from 'react';

import colors from 'Constants/colors';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RelativeWrapper = styled.div`
  position: relative;
  @media only screen and (max-width: 48em) {
    margin-bottom: 1rem;
  }
  margin-bottom: 2rem;
`;

const Container = styled.div`
  border-radius: 1rem;
  ${({ collapsed }) => (collapsed ? 'overflow: hidden;' : '')}
  height: ${({ collapsed, height, stretchY }) =>
    (collapsed && '5rem') || height || (stretchY && '100%') || 'auto'};
  min-height: ${({ minHeight }) => minHeight || 'initial'};
  width: 100%;
  background: ${colors.white};
  transition: height ease-out 0.2ms;
  border: 1px solid ${colors.greyPearl};
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  height: calc(100% - 5rem);
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'initial')};
  padding-top: ${({ condensed }) => (condensed ? '1rem' : '2rem')};
`;

const Content = styled.div`
  flex: 1;
  padding: ${({ noPadding, condensed }) =>
    (noPadding && '0') || (condensed && '0 2rem 1rem 2rem') || '0 2rem 2rem 2rem'};
`;

const Heading = styled.h2`
  font-size: 1.5em;
  line-height: 3rem;
  font-weight: 600;
  margin: 0 2rem 0 0;
`;

const TitleContainer = styled.div`
  display: flex;
  border-radius: 1rem 1rem 0 0;
  & > h2 {
    white-space: nowrap;
  }
  & + div {
    padding-top: 0;
  }
  padding: 1rem 2rem;
  background: ${colors.white};
`;

const Card = props => (
  <RelativeWrapper>
    <Container {...props} stretchY={'stretchY' in props}>
      {props.heading || props.headingContent ? (
        <TitleContainer>
          {props.heading ? <Heading>{props.heading}</Heading> : null}
          {props.headingContent}
        </TitleContainer>
      ) : null}

      {props.children ? (
        <ContentWrapper {...props}>
          <Content {...props}>{props.children}</Content>
        </ContentWrapper>
      ) : null}
    </Container>
  </RelativeWrapper>
);

Card.propTypes = {
  children: PropTypes.node,
  headingContent: PropTypes.node,
  heading: PropTypes.string,
  collapsed: PropTypes.bool,
  primary: PropTypes.bool,
  stretchX: PropTypes.bool,
  stretchY: PropTypes.bool,
  scrollable: PropTypes.bool,
  noPadding: PropTypes.bool,
  condensed: PropTypes.bool,
};

export default Card;
