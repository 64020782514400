import { Provider } from '@ethersproject/providers';
import { abis, addresses } from '@nexusmutual/sdk';
import { getContract } from 'Services/getContract';

const address = addresses.Ramm;

const Ramm = (provider: Provider, account?: string) =>
  getContract(address, abis.Ramm, provider, account);

export default Ramm;
