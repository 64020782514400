import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import Ethereum from 'Assets/ethereum.svg';
import { AppLifecycleContext } from 'Components/AppLifecycle';
import Button from 'Components/Button';
import PropTypes from 'prop-types';
import { selectAccount } from 'Services/rootDuck/selectors';

const TransactionButton = ({ children, ...props }) => {
  const account = useSelector(selectAccount);
  const { connectWallet } = useContext(AppLifecycleContext);
  const overrideProps = account ? {} : { onClick: connectWallet };
  return (
    <Button width="100%" icon={<Ethereum />} {...props} {...overrideProps}>
      {children}
    </Button>
  );
};

TransactionButton.propTypes = {
  children: PropTypes.node,
};

export default TransactionButton;
