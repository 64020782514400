import { ChainListProps } from 'Components/ChainList';
import { CHAIN_ID } from 'Config';
import type { ProductType } from 'Features/Membership/ducks/covers/initialState';

import { PRODUCT_TYPES } from './coverV2';
import v1Products from './v1Products.json'; // olds contracts.json file

export type ProductDetailsV1 = {
  name: string;
  type: ProductType;
  dateAdded: Date;
  symbol?: string;
  coveredToken?: string;
  underlyingToken?: string;
  site?: string;
  supportedChains?: ChainListProps['supportedChains'];
  logo?: string;
  github?: string;
  deprecated?: boolean;
  isPrivate?: boolean;
  metadata?: Record<string, unknown>;
};

type ProductsJsonV1 = { [key: string]: ProductDetailsV1 };

// needed for old V1 covers, claims etc.
const v1ProductsMap: ProductsJsonV1 = Object.keys(v1Products).reduce((acc, productId: string) => {
  acc[productId] = {
    ...(v1Products as unknown as ProductsJsonV1)[productId],
    // Use a mock token on testnet
    ...(CHAIN_ID === 42 &&
    (v1Products as unknown as ProductsJsonV1)[productId].type === PRODUCT_TYPES.token
      ? {
          coveredToken: '0xbd0B0205408509544815D377214D8f2CBe3e5381',
        }
      : {}),
    dateAdded: new Date((v1Products as unknown as ProductsJsonV1)[productId].dateAdded),
  };
  return acc;
}, {} as ProductsJsonV1);

export const coverableProducts = Object.keys(v1Products).filter(x => !v1ProductsMap[x].deprecated);

export default v1ProductsMap;
