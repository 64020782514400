import * as Sentry from '@sentry/react';
import { immediateToast } from 'izitoast-react';

import { Zero } from '@ethersproject/constants';
import { addresses as CONTRACTS_ADDRESSES, products } from '@nexusmutual/sdk';
import { CHAIN_ID, ETHERSCAN_URL, WNXM_PRICE } from 'Config';
import toastOptions from 'Constants/toastOptions';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import coversApi from 'Services/api/coversApi';
import ChainlinkEthDai from 'Services/contracts/ChainlinkEthDai';
import ChainlinkEthUsd from 'Services/contracts/ChainlinkEthUsd';
import ERC20 from 'Services/contracts/ERC20';
import MemberRoles from 'Services/contracts/MemberRoles';
import NXMaster from 'Services/contracts/NXMaster';
import NXMToken from 'Services/contracts/NXMToken';
import Ramm from 'Services/contracts/Ramm';
import TokenController from 'Services/contracts/TokenController';
import wNXM from 'Services/contracts/wNXM';
import { parseProductsArrayToMap } from 'Services/productDetailsUtils';
import { capitalize } from 'Services/utils';
import { getBalance } from 'Services/web3SagaUtils';

import {
  selectAccount,
  selectChainId,
  selectIsAccountBlacklisted,
  selectProvider,
  selectTxHistory,
} from './selectors';
import t from './types';

const LOCALSTORAGE_KEY = 'TX_HISTORY_' + 1;

function* loadNxmToEthRate() {
  yield put({ type: t.LOAD_NXM_TO_ETH_RATE.REQUESTED });
  try {
    const provider = yield select(selectProvider);
    const RAMM = yield call(Ramm, provider);

    const nxmEthRate = yield call(RAMM.getInternalPrice);

    yield put({
      type: t.LOAD_NXM_TO_ETH_RATE.SUCCEEDED,
      payload: { nxmEthRate },
    });
  } catch (error) {
    yield put({ type: t.LOAD_NXM_TO_ETH_RATE.FAILED, payload: { error } });
  }
}

function* loadEthToUsdRate({ payload = {} }) {
  const { silentLoad } = payload;
  const provider = yield select(selectProvider);
  yield put({
    type: t.LOAD_ETH_TO_USD_RATE.REQUESTED,
    payload: { silentLoad },
  });
  try {
    const { decimals, latestAnswer } = yield call(ChainlinkEthUsd, provider);
    const decimalsValue = yield call(decimals);
    const latestAnswerValue = yield call(latestAnswer);
    yield put({
      type: t.LOAD_ETH_TO_USD_RATE.SUCCEEDED,
      payload: { decimals: decimalsValue, latestAnswer: latestAnswerValue },
    });
  } catch (error) {
    yield put({ type: t.LOAD_ETH_TO_USD_RATE.FAILED, payload: { error } });
  }
}

function* loadEthToDaiRate({ payload = {} }) {
  const { silentLoad } = payload;
  const provider = yield select(selectProvider);
  yield put({
    type: t.LOAD_ETH_TO_DAI_RATE.REQUESTED,
    payload: { silentLoad },
  });
  try {
    const { decimals, latestAnswer } = yield call(ChainlinkEthDai, provider);
    const decimalsValue = yield call(decimals);
    const latestAnswerValue = yield call(latestAnswer);
    yield put({
      type: t.LOAD_ETH_TO_DAI_RATE.SUCCEEDED,
      payload: { decimals: decimalsValue, latestAnswer: latestAnswerValue },
    });
  } catch (error) {
    yield put({ type: t.LOAD_ETH_TO_DAI_RATE.FAILED, payload: { error } });
  }
}

function* loadNXMBalance({ payload = {} }) {
  try {
    const { silentLoad } = payload;
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    yield put({ type: t.LOAD_NXM_BALANCE.REQUESTED, payload: { silentLoad } });
    const { balanceOf } = yield call(NXMToken, provider, account);
    const balanceNXM = yield call(balanceOf, account);
    yield put({
      type: t.LOAD_NXM_BALANCE.SUCCEEDED,
      payload: { balanceNXM },
    });
  } catch (error) {
    yield put({ type: t.LOAD_NXM_BALANCE.FAILED, payload: { error } });
  }
}

function* loadwNXMBalance({ payload = {} }) {
  try {
    const { silentLoad } = payload;
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    yield put({
      type: t.LOAD_WNXM_BALANCE.REQUESTED,
      payload: { silentLoad },
    });
    const { balanceOf } = yield call(wNXM, provider, account);
    const balancewNXM = yield call(balanceOf, account);
    yield put({
      type: t.LOAD_WNXM_BALANCE.SUCCEEDED,
      payload: { balancewNXM },
    });
  } catch (error) {
    yield put({ type: t.LOAD_WNXM_BALANCE.FAILED, payload: { error } });
  }
}

/**
 * @dev Returns the total amount of tokens held by an address:
 *   transferable + locked + staked for pooled staking - pending burns.
 *   Used by Claims and Governance in member voting to calculate the user's vote weight.
 */
function* loadNXMTotalBalance({ payload = {} }) {
  try {
    const { silentLoad } = payload;
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    yield put({
      type: t.LOAD_NXM_TOTAL_BALANCE.REQUESTED,
      payload: { silentLoad },
    });
    const { totalBalanceOf } = yield call(TokenController, provider, account);
    const totalBalanceNXM = yield call(totalBalanceOf, account);
    yield put({
      type: t.LOAD_NXM_TOTAL_BALANCE.SUCCEEDED,
      payload: { totalBalanceNXM },
    });
  } catch (error) {
    yield put({ type: t.LOAD_NXM_TOTAL_BALANCE.FAILED, payload: { error } });
  }
}

function* loadETHBalance({ payload = {} }) {
  try {
    const { silentLoad } = payload;
    yield put({ type: t.LOAD_ETH_BALANCE.REQUESTED, payload: { silentLoad } });
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    if (!account || !provider) {
      yield put({
        type: t.LOAD_ETH_BALANCE.SUCCEEDED,
        payload: { balanceETH: Zero },
      });
    }
    const balanceETH = yield call(getBalance, provider, account);
    yield put({
      type: t.LOAD_ETH_BALANCE.SUCCEEDED,
      payload: { balanceETH },
    });
  } catch (error) {
    yield put({ type: t.LOAD_ETH_BALANCE.FAILED, payload: { error } });
  }
}

function* isMember() {
  try {
    yield put({ type: t.IS_MEMBER.REQUESTED });
    const account = yield select(selectAccount);
    const isBlacklisted = yield select(selectIsAccountBlacklisted);
    if (isBlacklisted) {
      yield put({ type: t.IS_MEMBER.SUCCEEDED, payload: { isMember: false } });
      return;
    }
    const provider = yield select(selectProvider);
    const NM = yield call(NXMaster, provider);
    const isMember = yield call(NM.isMember, account);
    yield put({ type: t.IS_MEMBER.SUCCEEDED, payload: { isMember } });
  } catch (error) {
    yield put({ type: t.IS_MEMBER.FAILED, payload: { error } });
  }
}

function* isLockedForMV() {
  const account = yield select(selectAccount);
  const provider = yield select(selectProvider);
  if (!account) {
    yield put({ type: t.IS_LOCKED_FOR_MV.RESET });
    return;
  }
  yield put({ type: t.IS_LOCKED_FOR_MV.REQUESTED });
  try {
    const TK = yield call(NXMToken, provider, account);
    const isLockedForMV = yield call(TK.isLockedForMV, account);
    yield put({
      type: t.IS_LOCKED_FOR_MV.SUCCEEDED,
      payload: { isLockedForMV },
    });
  } catch (error) {
    yield put({ type: t.IS_LOCKED_FOR_MV.FAILED, payload: { error } });
  }
}

function listenForTransferEventsChannel(TK, account, provider) {
  return eventChannel(emitter => {
    const sentHandler = (from, to, value) => {
      emitter({ type: t.UPDATE_FROM_SENT_TRANSFER_EVENT });
    };

    const receivedHandler = (from, to, value) => {
      emitter({ type: t.UPDATE_FROM_RECEIVED_TRANSFER_EVENT });
    };

    const sentTransferFilter = TK.filters.Transfer(account, null, null);
    const receivedTransferFilter = TK.filters.Transfer(null, account, null);

    // Subscribe to events
    TK.on(sentTransferFilter, sentHandler);
    TK.on(receivedTransferFilter, receivedHandler);

    // Unsubscribe function
    return () => {
      TK.removeListener('Transfer', sentHandler);
      TK.removeListener('Transfer', receivedHandler);
    };
  });
}

function* listenForTransferEvents() {
  const account = yield select(selectAccount);
  const provider = yield select(selectProvider);
  let transferChannel;
  try {
    const TK = yield call(NXMToken, provider, account);
    transferChannel = yield call(listenForTransferEventsChannel, TK, account, provider);
    while (true) {
      const action = yield take(transferChannel);
      yield put(action);
    }
  } catch (error) {
    yield put({
      type: t.LISTEN_FOR_TRANSFER_EVENTS.FAILED,
      payload: { error },
    });
  } finally {
    if (yield cancelled()) {
      transferChannel && transferChannel.close();
    }
  }
}

function* updateFromTransferEvent() {
  yield put({
    type: t.LOAD_NXM_BALANCE.TRIGGER,
    payload: { silentLoad: true },
  });
}

function* setNXMAllowance({ payload }) {
  let tx, unsignedTx;
  const { value } = payload;
  const account = yield select(selectAccount);
  const provider = yield select(selectProvider);
  yield put({ type: t.SEND_SET_NXM_ALLOWANCE_TX.REQUESTED });
  try {
    const TK = yield call(NXMToken, provider, account);
    const args = [CONTRACTS_ADDRESSES.TokenController, value];
    unsignedTx = yield call(TK.populateTransaction.approve, ...args);
    tx = yield call(TK.approve, ...args);
    yield put({
      type: t.SEND_SET_NXM_ALLOWANCE_TX.CONFIRMED,
      payload: { tx, message: 'Set allowance for TokenController' },
    });
    yield call(tx.wait);
    yield put({
      type: t.SEND_SET_NXM_ALLOWANCE_TX.SUCCEEDED,
      payload: { nxmAllowance: value, tx },
    });
  } catch (error) {
    yield put({
      type: t.SEND_SET_NXM_ALLOWANCE_TX.FAILED,
      payload: { error, unsignedTx, tx },
    });
  }
}

function* loadProductCapacities() {
  try {
    yield put({ type: t.LOAD_PRODUCT_CAPACITIES.REQUESTED });

    const chainId = yield select(selectChainId);

    const productCapacities = yield call(coversApi.getProductCapacities, chainId);

    yield put({
      type: t.LOAD_PRODUCT_CAPACITIES.SUCCEEDED,
      payload: { productCapacities },
    });
  } catch (error) {
    yield put({ type: t.LOAD_PRODUCT_CAPACITIES.FAILED, payload: { error } });
  }
}

function* loadMemberRoles() {
  try {
    yield put({ type: t.LOAD_MEMBER_ROLES.REQUESTED });
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    const MR = yield call(MemberRoles, provider);
    const memberRoles = yield call(MR.roles, account);
    yield put({
      type: t.LOAD_MEMBER_ROLES.SUCCEEDED,
      payload: { memberRoles },
    });
  } catch (error) {
    yield put({ type: t.LOAD_MEMBER_ROLES.FAILED, payload: { error } });
  }
}

function* loadNXMAllowance() {
  yield put({ type: t.LOAD_NXM_ALLOWANCE.REQUESTED });
  try {
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    const nxmAllowance = yield call(
      NXMToken(provider, account).allowance,
      account,
      CONTRACTS_ADDRESSES.TokenController,
    );
    yield put({
      type: t.LOAD_NXM_ALLOWANCE.SUCCEEDED,
      payload: { nxmAllowance },
    });
  } catch (error) {
    yield put({ type: t.LOAD_NXM_ALLOWANCE.FAILED, payload: { error } });
  }
}

function* loadTotalSupply() {
  yield put({ type: t.LOAD_TOTAL_SUPPLY.REQUESTED });
  try {
    const provider = yield select(selectProvider);
    const NT = yield call(NXMToken, provider);
    const totalSupply = yield call(NT.totalSupply);
    yield put({
      type: t.LOAD_TOTAL_SUPPLY.SUCCEEDED,
      payload: { totalSupply },
    });
  } catch (error) {
    yield put({ type: t.LOAD_TOTAL_SUPPLY.FAILED, payload: { error } });
  }
}

function* saveTxHistory() {
  const txHistory = yield select(selectTxHistory);
  window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(txHistory));
}

function* clearTxHistory() {
  window.localStorage.removeItem(LOCALSTORAGE_KEY);
  yield put({
    type: t.RESTORE_TX_HISTORY_VALUE,
    payload: { txHistoryToRestore: '[]' },
  });
}
const waitForTransaction = (provider, hash, confirmations) =>
  provider.waitForTransaction(hash, confirmations);

function* checkTxHistoryStatus(txHistoryItem, showToast) {
  const provider = yield select(selectProvider);
  const receipt = yield call(waitForTransaction, provider, txHistoryItem.hash);
  if (receipt) {
    yield put({
      type: t.UPDATE_TX_HISTORY,
      payload: { receipt, txHistoryItem },
    });
    if (showToast) {
      immediateToast('success', {
        title: getMessage(txHistoryItem.message, receipt.status ? 'confirmed' : 'reverted'),
        message: getEtherscanLink(txHistoryItem?.hash),
        ...toastOptions,
      });
    }
    yield put({ type: t.SAVE_TX_HISTORY });
  }
}

function* restoreTxHistory() {
  const txHistoryToRestore = window.localStorage.getItem(LOCALSTORAGE_KEY);
  if (txHistoryToRestore) {
    const txHistory = JSON.parse(txHistoryToRestore);
    const pendingTx = txHistory.filter(x => x.status === 'PENDING');
    yield put({
      type: t.RESTORE_TX_HISTORY_VALUE,
      payload: { txHistoryToRestore },
    });
    for (const tx of pendingTx) {
      yield fork(checkTxHistoryStatus, tx);
    }
  }
}

const fetchWNXMPrice = () => fetch(WNXM_PRICE).then(res => res.json());

function* loadWnxmToEthRate() {
  yield put({ type: t.LOAD_WNXM_RATE.REQUESTED });
  try {
    const res = yield call(fetchWNXMPrice);
    yield put({ type: t.LOAD_WNXM_RATE.SUCCEEDED, payload: { res } });
  } catch (error) {
    yield put({
      type: t.LOAD_WNXM_RATE.FAILED,
      payload: { error },
    });
  }
}

export function* loadProducts() {
  yield put({ type: t.LOAD_PRODUCTS.REQUESTED });
  try {
    yield put({
      type: t.LOAD_PRODUCTS.SUCCEEDED,
      payload: { products: parseProductsArrayToMap(products) },
    });
  } catch (error) {
    yield put({ type: t.LOAD_PRODUCTS.FAILED, payload: { error } });
  }
}

function* setDAIAllowance({ payload }) {
  let tx, unsignedTx;
  const { value } = payload;
  const account = yield select(selectAccount);
  const provider = yield select(selectProvider);
  yield put({ type: t.SEND_SET_DAI_ALLOWANCE_TX.REQUESTED });
  try {
    const DAIContract = yield call(ERC20, CONTRACTS_ADDRESSES.DAI);
    const DAI = yield call(DAIContract, provider, account);
    const args = [CONTRACTS_ADDRESSES.Cover, value];
    unsignedTx = yield call(DAI.populateTransaction.approve, ...args);
    tx = yield call(DAI.approve, ...args);
    yield put({
      type: t.SEND_SET_DAI_ALLOWANCE_TX.CONFIRMED,
      payload: { tx, message: 'Set DAI allowance for Cover' },
    });
    yield call(tx.wait);
    yield put({
      type: t.SEND_SET_DAI_ALLOWANCE_TX.SUCCEEDED,
      payload: { daiAllowance: value, tx },
    });
  } catch (error) {
    yield put({
      type: t.SEND_SET_DAI_ALLOWANCE_TX.FAILED,
      payload: { error, unsignedTx, tx, provider },
    });
  }
}

function* loadDAIAllowance() {
  yield put({ type: t.LOAD_DAI_ALLOWANCE.REQUESTED });
  try {
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    const DAI = yield call(ERC20, CONTRACTS_ADDRESSES.DAI);
    const { allowance } = yield call(DAI, provider, account);
    const daiAllowance = yield call(allowance, account, CONTRACTS_ADDRESSES.Cover);
    yield put({
      type: t.LOAD_DAI_ALLOWANCE.SUCCEEDED,
      payload: { daiAllowance },
    });
  } catch (error) {
    yield put({ type: t.LOAD_DAI_ALLOWANCE.FAILED, payload: { error } });
  }
}

function* loadDAIBalance({ payload = {} }) {
  try {
    const { silentLoad } = payload;
    const account = yield select(selectAccount);
    const provider = yield select(selectProvider);
    yield put({ type: t.LOAD_DAI_BALANCE.REQUESTED, payload: { silentLoad } });
    const DAI = yield call(ERC20, CONTRACTS_ADDRESSES.DAI);
    const { balanceOf } = yield call(DAI, provider, account);
    const balanceDAI = yield call(balanceOf, account);
    yield put({
      type: t.LOAD_DAI_BALANCE.SUCCEEDED,
      payload: { balanceDAI },
    });
  } catch (error) {
    yield put({ type: t.LOAD_DAI_BALANCE.FAILED, payload: { error } });
  }
}

function* loadProductsWatch() {
  yield takeLatest(t.LOAD_PRODUCTS.TRIGGER, loadProducts);
}

function* isLockedForMVWatch() {
  yield takeLatest(t.IS_LOCKED_FOR_MV.TRIGGER, isLockedForMV);
}

function* isMemberWatch() {
  yield takeLatest(t.IS_MEMBER.TRIGGER, isMember);
}

function* loadNxmToEthRateWatch() {
  yield takeLatest(t.LOAD_NXM_TO_ETH_RATE.TRIGGER, loadNxmToEthRate);
}

function* loadEthToUsdRateWatch() {
  yield takeLatest(t.LOAD_ETH_TO_USD_RATE.TRIGGER, loadEthToUsdRate);
}

function* loadEthToDaiRateWatch() {
  yield takeLatest(t.LOAD_ETH_TO_DAI_RATE.TRIGGER, loadEthToDaiRate);
}

function* loadNXMBalanceWatch() {
  yield takeLatest(t.LOAD_NXM_BALANCE.TRIGGER, loadNXMBalance);
}

function* loadNXMTotalBalanceWatch() {
  yield takeLatest(t.LOAD_NXM_TOTAL_BALANCE.TRIGGER, loadNXMTotalBalance);
}

function* updateFromReceivedTransferEventWatch() {
  yield takeLatest(t.UPDATE_FROM_RECEIVED_TRANSFER_EVENT, updateFromTransferEvent);
}

function* listenForTransferEventsWatch() {
  yield takeLatest(t.LISTEN_FOR_TRANSFER_EVENTS.TRIGGER, listenForTransferEvents);
}

function* updateFromSentTransferEventWatch() {
  yield takeLatest(t.UPDATE_FROM_SENT_TRANSFER_EVENT, updateFromTransferEvent);
}

function* loadNXMAllowanceWatch() {
  yield takeLatest(t.LOAD_NXM_ALLOWANCE.TRIGGER, loadNXMAllowance);
}

function* setNXMAllowanceWatch() {
  yield takeLatest(t.SEND_SET_NXM_ALLOWANCE_TX.TRIGGER, setNXMAllowance);
}

function* loadProductCapacitiesWatch() {
  yield takeLatest([t.LOAD_PRODUCT_CAPACITIES.TRIGGER, t.SET_CHAIN_ID], loadProductCapacities);
}

function* loadMemberRolesWatch() {
  yield takeLatest(t.LOAD_MEMBER_ROLES.TRIGGER, loadMemberRoles);
}

function* loadTotalSupplyWatch() {
  yield takeLatest(t.LOAD_TOTAL_SUPPLY.TRIGGER, loadTotalSupply);
}

function* saveTxHistoryWatch() {
  yield takeLatest(t.SAVE_TX_HISTORY, saveTxHistory);
}

function* clearTxHistoryWatch() {
  yield takeLatest(t.CLEAR_TX_HISTORY, clearTxHistory);
}

function* restoreTxHistoryWatch() {
  yield takeLatest(t.RESTORE_TX_HISTORY, restoreTxHistory);
}

function* loadWnxmToEthRateWatch() {
  yield takeLatest(t.LOAD_WNXM_RATE.TRIGGER, loadWnxmToEthRate);
}

function* loadDAIAllowanceWatch() {
  yield takeLatest(t.LOAD_DAI_ALLOWANCE.TRIGGER, loadDAIAllowance);
}

function* setDAIAllowanceWatch() {
  yield takeLatest(t.SEND_SET_DAI_ALLOWANCE_TX.TRIGGER, setDAIAllowance);
}

function* loadDAIBalanceWatch() {
  yield takeLatest(t.LOAD_DAI_BALANCE.TRIGGER, loadDAIBalance);
}

const txRegExp = /^(.*)\/(SEND_.*_TX)_(.*)$/;

const getEtherscanLink = hash =>
  hash
    ? `
  <div>
    <a href="${ETHERSCAN_URL}/tx/${hash}" target="_blank" rel="noreferrer" >
      View on etherscan
    </a>
  </div>`
    : null;

const getMessage = (message, status) =>
  capitalize((message ? message + ' ' : '') + `transaction ${status}`);

function* txHandler({ type, payload }) {
  const matched = type.match(txRegExp);
  if (!matched) {
    return;
  }
  const [actionFull, actionPrefix, actionBase, actionStatus] = matched;
  if (actionStatus !== 'CONFIRMED') {
    return;
  }

  // User signed the transaction and it was sent to the network
  immediateToast('warning', {
    title: getMessage(payload?.message, 'pending'),
    message: getEtherscanLink(payload?.tx?.hash),
    ...toastOptions,
  });
  yield put({
    type: t.ADD_PENDING_TX,
    payload: { tx: payload.tx, message: payload.message },
  });
  yield put({ type: t.SAVE_TX_HISTORY });

  const txHistory = yield select(selectTxHistory);
  const pendingTx = txHistory.find(x => x.nonce === payload.tx.nonce && x.from === payload.tx.from);
  yield fork(checkTxHistoryStatus, pendingTx, true);
}

function* txWatch() {
  yield takeEvery(action => txRegExp.test(action.type), txHandler);
}

function* exceptionHandler({ payload }) {
  const { unsignedTx } = payload;
  if (unsignedTx) {
    const provider = yield select(selectProvider);
    const block = provider.blockNumber;
    let debugLink = `https://dashboard.tenderly.co/NexusMutual/nexusmutual/simulator/new?from=${unsignedTx.from}&contractAddress=${unsignedTx.to}&rawFunctionInput=${unsignedTx.data}&network=${CHAIN_ID}`;
    if (block && block > 0) {
      debugLink += '&block=' + block;
    }
    if (unsignedTx.value) {
      debugLink += '&value=' + unsignedTx.value;
    }
    console.log(debugLink);

    Sentry.captureException(payload.error, {
      extra: {
        debugLink,
      },
    });
  } else {
    Sentry.captureException(payload.error);
  }
}

function* exceptionsWatch() {
  yield takeEvery(action => /.*_FAILED$/.test(action.type), exceptionHandler);
}

function* loadETHBalanceWatch() {
  yield takeLatest(t.LOAD_ETH_BALANCE.TRIGGER, loadETHBalance);
}

function* loadwNXMBalanceWatch() {
  yield takeLatest(t.LOAD_WNXM_BALANCE.TRIGGER, loadwNXMBalance);
}

export default function* rootSaga() {
  yield all([
    fork(isMemberWatch),
    fork(loadNXMBalanceWatch),
    fork(loadNXMTotalBalanceWatch),
    fork(loadETHBalanceWatch),
    fork(loadNxmToEthRateWatch),
    fork(loadEthToUsdRateWatch),
    fork(loadEthToDaiRateWatch),
    fork(isLockedForMVWatch),
    fork(updateFromReceivedTransferEventWatch),
    fork(updateFromSentTransferEventWatch),
    fork(listenForTransferEventsWatch),
    fork(loadNXMAllowanceWatch),
    fork(setNXMAllowanceWatch),
    fork(loadProductCapacitiesWatch),
    fork(loadMemberRolesWatch),
    fork(loadTotalSupplyWatch),
    fork(saveTxHistoryWatch),
    fork(clearTxHistoryWatch),
    fork(restoreTxHistoryWatch),
    fork(txWatch),
    fork(exceptionsWatch),
    fork(loadWnxmToEthRateWatch),
    fork(loadProductsWatch),
    fork(loadDAIAllowanceWatch),
    fork(setDAIAllowanceWatch),
    fork(loadDAIBalanceWatch),
    fork(loadwNXMBalanceWatch),
  ]);
}
