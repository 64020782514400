import React from 'react';

import colors from 'Constants/colors';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Title = styled.span`
  padding-left: 1.5rem;
  font-size: 1rem;
  color: ${colors.greyAsher};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid ${colors.greyPearl};
  border-radius: 0.2rem;
  background: ${colors.white};
`;

type CardWithTitleProps = {
  title: string;
  children: React.ReactNode;
};

const CardWithTitle = ({ title, children }: CardWithTitleProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <CardWrapper>{children}</CardWrapper>
    </Container>
  );
};

export default CardWithTitle;
