import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import User from 'Assets/user.svg';
import { AppLifecycleContext } from 'Components/AppLifecycle';
import Button from 'Components/Button';
import colors from 'Constants/colors';
import {
  selectAccount,
  selectBalanceNXMCompactDisplay,
  selectPendingTransactions,
} from 'Services/rootDuck/selectors';
import { shortenAddress } from 'Services/utils';
import styled from 'styled-components';

const Balance = styled.div`
  position: relative;
  right: -1rem;
  width: fit-content;
  height: 2rem;
  box-sizing: border-box;
  &:focus-within {
    outline: 0;
    box-shadow: 0 0 0 3px ${colors.greenSeafoam} !important;
    & > *:focus {
      box-shadow: 0 0 0 0 ${colors.tranparent} !important;
    }
  }
  white-space: nowrap;
  color: ${colors.nexusGreen};
  border: 1px solid ${colors.nexusGreen};
  border-radius: 2rem 0 0 2rem;
  padding: 0 2rem 0 0.5rem;
  z-index: -1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  & > button {
    width: 10rem;
  }
`;

const DesktopUserButton = ({ toggleUserMenu }) => {
  const account = useSelector(selectAccount);
  const { connectWallet } = useContext(AppLifecycleContext);
  const balanceNXMCompactDisplay = useSelector(selectBalanceNXMCompactDisplay);
  const pendingTransactions = useSelector(selectPendingTransactions);
  return (
    <Container>
      {account ? <Balance>{balanceNXMCompactDisplay} NXM</Balance> : null}
      <Button
        primary
        small
        noContentAbove
        onClick={account ? toggleUserMenu : connectWallet}
        loading={!!pendingTransactions}
        alwaysEnabled
        icon={<User />}
      >
        {pendingTransactions
          ? `${pendingTransactions} tx pending`
          : shortenAddress(account, 6) || 'Connect wallet'}
      </Button>
    </Container>
  );
};

export default DesktopUserButton;
