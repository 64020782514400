import colors from 'Constants/colors';
import styled from 'styled-components';

export const RadioOptionStyles = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &::before {
    box-sizing: border-box;
    content: '';
    height: 16px;
    width: 16px;
    padding: 3px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid ${colors.greyPearl};
    margin-right: 8px;
  }

  &.disabled {
    color: #afafaf;
    cursor: not-allowed;
  }

  &.checked::before {
    background: ${colors.greySterling};
    border: 1px solid ${colors.greyPearl};
    outline: 3px solid white;
    outline-offset: -4px;
  }

  &:not(.disabled):hover::before {
    border: 1px solid ${colors.greyStone};
  }

  &:not(.disabled).checked::before,
  &:not(.disabled):active::before {
    background: ${colors.nexusGreen};
    border: 1px solid ${colors.nexusGreen};
    outline: 3px solid white;
    outline-offset: -4px;
  }
`;
