// These async methods return an __awaiter wrapper.
// I couldn't get it working with saga call effect hence this is a workaround.
// More about how async/await is transpiled in this link:
// https://github.com/basarat/typescript-book/blob/master/docs/async-await.md

import { JsonRpcProvider } from '@ethersproject/providers';
import { DEV_ERIGON_URL, DEV_FORK_BLOCK } from 'Constants/devSetup';

/* Batch size is an apporx. of the no. of blocks added in a month (1 month = 2678400s) */
export const LOGS_BATCH_SIZE = 224000;

export const getBlock = (provider, hashOrNumber) => provider.getBlock(hashOrNumber);
export const getBlockNumber = provider => provider.getBlockNumber();

export const getLogs = async (provider, filter) => {
  if (!provider) {
    throw new Error('No provider found.');
  }

  const fetchedLogs = [];

  if (__DEV__ && DEV_FORK_BLOCK) {
    // fetch before DEV_FORK_BLOCK from erigon
    const mainnetFilter = { ...filter, toBlock: +DEV_FORK_BLOCK };
    filter.fromBlock = +DEV_FORK_BLOCK;
    const erigonProvider = new JsonRpcProvider(DEV_ERIGON_URL);
    const mainnetLogs = await erigonProvider.getLogs(mainnetFilter);
    fetchedLogs.push(...mainnetLogs);
  }
  // fetch in batches from mainnet
  const fromBlockNumber = filter.fromBlock;
  const toBlockNumber = await provider.getBlockNumber();
  const totalBlocks = toBlockNumber >= fromBlockNumber && toBlockNumber - fromBlockNumber + 1;

  if (!totalBlocks || fromBlockNumber < 0 || toBlockNumber < 0) {
    return fetchedLogs;
  }

  if (!LOGS_BATCH_SIZE || LOGS_BATCH_SIZE < 0 || LOGS_BATCH_SIZE > totalBlocks) {
    const chunkLogs = await provider.getLogs({
      ...filter,
      fromBlock: fromBlockNumber,
      toBlock: toBlockNumber,
    });

    fetchedLogs.push(...chunkLogs);
    return fetchedLogs;
  }

  const chunksTotal = Math.ceil(totalBlocks / LOGS_BATCH_SIZE);
  for (let index = 0; index < chunksTotal; index++) {
    const fromBlock = fromBlockNumber + LOGS_BATCH_SIZE * index;
    const toBlock = index === chunksTotal - 1 ? toBlockNumber : fromBlock + LOGS_BATCH_SIZE - 1;
    const chunkLogs = await provider.getLogs({
      ...filter,
      fromBlock,
      toBlock,
    });
    fetchedLogs.push(...chunkLogs);
  }

  return fetchedLogs;
};

export const getBalance = (provider, account) => provider.getBalance(account);
export const getSigner = (provider, address) => provider.getSigner(address);
export const signMessage = (signer, msg) => signer.signMessage(msg);

// Returns the transaction with hash or null if the transaction is unknown.
export const getTx = (provider, txHash) => provider.getTransaction(txHash);
// Returns a Promise which will not resolve until transactionHash is mined.
export const waitForTransaction = (provider, txHash, timeout = 10000) =>
  provider.waitForTransaction(txHash, 1, timeout);

export const parseLog = (contract, log) => contract.interface.parseLog(log);

/**
 * Parses transaction events from the logs in a transaction receipt
 * @param {Contract} contract Contract for filtering the logs
 * @param {TransactionReceipt} receipt Transaction receipt containing the events in the logs
 * @returns {{[eventName: string]: TransactionEvent}}
 */
export function getTransactionEventsByContract(receipt, contract) {
  const events = receipt.logs
    .filter(log => log.address === contract.address)
    .map(log => contract.interface.parseLog(log))
    .reduce((acc, event) => {
      acc[event.name] = event;
      return acc;
    }, {});

  return events;
}
