import React from 'react';

import { ExternalLink } from 'Components';
import GradientShimmer from 'Components/GradientShimmer';
import { Row } from 'Components/gridComponents';
import colors from 'Constants/colors';
import { useTitle } from 'Hooks/useTitle';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Breadcrumb } from './Breadcrumb';
import { Banner } from './informational/Banner';

const Container = styled.div`
  display: flex;
  background-color: ${colors.whitePale};
  @media only screen and (max-width: 48em) {
    padding: 5rem 1rem 2rem 1rem;
  }
  padding: 6rem 4rem 1rem 4rem;
  font-weight: 600;
`;

const Content = styled.div`
  width: 100%;
  max-width: calc(1536px - 2rem);
  margin: auto;
  h1 {
    line-height: 2rem;
    font-weight: 400;
    color: ${colors.greyStone};
    margin-bottom: 0;
    @media only screen and (max-width: 48em) {
      margin-bottom: 1rem;
    }
  }
`;

const HeroCard = ({
  heading,
  description,
  breadcrumb,
  actionComponent,
  twoColumns = false,
  loading,
  children,
}) => {
  useTitle('Nexus Mutual - ' + heading);
  return (
    <Container primary={true}>
      <Content>
        {/* Uncomment banner component and add content whenever we need an announcement to show up in header */}
        {/* <Banner>
          🚀 Exciting news! Try our brand new Buy Cover experience{' '}
          <ExternalLink
            href={process.env.REACT_APP_NEXT_APP_URL || 'https://v2.nexusmutual.io/'}
            target="_self"
          >
            here
          </ExternalLink>
          !
        </Banner> */}
        {loading ? (
          <>
            <GradientShimmer width="360px" height="2rem" />
            <Row align="center" minHeight="3rem">
              <GradientShimmer width="560px" height="1.5rem" margin="1rem 0 0 0" />
            </Row>
          </>
        ) : (
          <>
            {breadcrumb && breadcrumb.length > 0 && (
              <div className="row">
                <div className="col-xs-12">
                  <Breadcrumb items={breadcrumb} />
                </div>
              </div>
            )}

            <Row justifyContent="space-between">
              <h1>{heading}</h1>
              <div>{actionComponent}</div>
            </Row>

            {description ? (
              <Row align="center" minHeight="4rem">
                {description}
              </Row>
            ) : null}
          </>
        )}
        {children}
      </Content>
    </Container>
  );
};

HeroCard.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  breadcrumb: PropTypes.array,
  actionComponent: PropTypes.node,
  loading: PropTypes.bool,
};

export default HeroCard;
