import React from 'react';

import colors from 'Constants/colors';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.75rem 0 0.25rem;
`;

const Icon = styled.svg`
  fill: none;
  stroke-width: 4px;
`;

// Hide checkbox visually but remain accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked: boolean; disabled?: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  margin-bottom: 0.25rem;
  border: 1px solid ${colors.greyAsher};
  transition: all 150ms;
  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
    stroke: ${({ disabled }) => (disabled ? colors.greyAsher : colors.nexusGreen)};
  }
`;

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:not([disabled]):hover,
  &:not([disabled]):focus-within {
    ${StyledCheckbox} {
      border: 1px solid ${({ disabled }) => (disabled ? colors.greyAsher : colors.greenDark)};
      box-shadow: 0 0 0 3px ${colors.greenSeafoam};
    }
  }
`;

type CheckboxProps = React.PropsWithChildren<{
  checked: boolean;
  disabled?: boolean;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}>;

const Checkbox = ({ className, checked, children, onChange, ...props }: CheckboxProps) => (
  <Label {...props}>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} onChange={e => onChange(e)} />
      <StyledCheckbox checked={checked} {...props}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
    {children}
  </Label>
);

export default Checkbox;
