import { useSetChain } from '@web3-onboard/react';
import React, { useContext, useEffect } from 'react';
import { resetCookieConsentValue } from 'react-cookie-consent';
import ReactHintFactory from 'react-hint';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Discord from 'Assets/discord.svg';
import Discourse from 'Assets/discourse.svg';
import Dune from 'Assets/dune.svg';
import Github from 'Assets/github.svg';
import Twitter from 'Assets/twitter.svg';
import AppLifecycle, { AppLifecycleContext } from 'Components/AppLifecycle';
import MenuBar from 'Components/menu/MenuBar';
import colors from 'Constants/colors';
import PropTypes from 'prop-types';
import { selectAccount } from 'Services/rootDuck/selectors';
import styled from 'styled-components';

import packageJson from '../../package.json';
import { NEXUS_MUTUAL_COOKIE_CONSENT } from '../constants/cookieConsent';
import ButtonClear from './ButtonClear';
import HeroCard from './HeroCard';
import Message from './Message';

const ReactHint = ReactHintFactory(React);

const PageContent = styled.div`
  flex: 1;
  padding-bottom: 4rem;
`;

const FooterContainer = styled.footer`
  position: absolute;
  height: 4rem;
  background: ${colors.black};
  width: 100%;
  bottom: 0;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${colors.black};
  color: ${colors.whitePale};
  margin: auto;
  padding: 1rem 0 0 0;
  max-width: calc(1536px - 2rem);
  @media (min-width: 48rem) {
    padding: 1rem 1rem 0 1rem;
  }
  flex: 1;
`;

const FooterLink = styled.a`
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
  & > svg {
    fill: ${colors.whitePale};
    height: 20px;
    padding: 5px;
    line-height: 1rem;
  }
  &:hover {
    & > svg {
      fill: ${colors.greenSeafoam};
    }
    color: ${colors.greenSeafoam};
  }
  line-height: 0;
  color: ${colors.whitePale};
`;

const FooterDocuments = styled.div`
  margin-bottom: 0.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;

const AppVersion = styled.div`
  margin-right: 1rem;
  color: ${colors.greySterling};
  font-size: 0.9rem;
`;

const SwitchNetworkButton = styled(ButtonClear)`
  display: inline;
  left: 1.5rem;
  top: 0;
  opacity: 1;
`;

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <AppVersion>{`v${packageJson.version}`}</AppVersion>
      <ButtonsContainer>
        <FooterDocuments>
          <FooterLink
            color={colors.whitePale}
            onClick={() => {
              resetCookieConsentValue(NEXUS_MUTUAL_COOKIE_CONSENT);
              window.location.reload();
              return false;
            }}
          >
            Preferences
          </FooterLink>
          <FooterLink href="https://nexusmutual.io/contact.html" target="_blank" rel="noreferrer">
            Contact us
          </FooterLink>
          <FooterLink
            href="https://nexusmutual.io/pages/Privacy-Policy.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </FooterLink>
          <FooterLink
            href="https://nexusmutual.io/pages/Terms-of-Use.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms of use
          </FooterLink>
        </FooterDocuments>
        <div>
          <FooterLink href="https://github.com/NexusMutual" target="_blank" rel="noreferrer">
            <Github />
          </FooterLink>
          <FooterLink href="https://twitter.com/NexusMutual" target="_blank" rel="noreferrer">
            <Twitter />
          </FooterLink>
          <FooterLink href="https://discord.gg/aQjkzW5" target="_blank" rel="noreferrer">
            <Discord />
          </FooterLink>
          <FooterLink href="https://forum.nexusmutual.io/" target="_blank" rel="noreferrer">
            <Discourse />
          </FooterLink>
          <FooterLink href="https://dune.com/nexus_mutual" target="_blank" rel="noreferrer">
            <Dune />
          </FooterLink>
        </div>
      </ButtonsContainer>
    </FooterContent>
  </FooterContainer>
);

const Layout = ({ children }) => {
  const history = useHistory();
  const { connectWallet } = useContext(AppLifecycleContext);
  const account = useSelector(selectAccount);

  const [
    {
      chains, // the list of chains that web3-onboard was initialized with
      connectedChain, // the current chain the user's wallet is connected to
      settingChain, // boolean indicating if the chain is in the process of being set
    },
    setChain, // function to call to initiate user to switch chains in their wallet
  ] = useSetChain();

  const isSupportedChain =
    connectedChain && chains.map(chain => chain?.id).includes(connectedChain?.id);

  useEffect(
    () =>
      history.listen((_, action) => {
        if (action === 'PUSH' || action === 'REPLACE') {
          window.scrollTo(0, 0);
        }
      }),
    [history],
  );

  return (
    <>
      <ReactHint autoPosition events delay={{ show: 100, hide: 50 }} />
      <AppLifecycle>
        <MenuBar account={account} activate={connectWallet} />

        <PageContent>
          {connectedChain && !isSupportedChain ? (
            <HeroCard
              heading="Connected to the wrong network"
              description={
                <Message danger>
                  To use the app, please switch to{' '}
                  <SwitchNetworkButton
                    loading={settingChain}
                    onClick={() => {
                      setChain({
                        chainId: chains[0]?.id,
                      });
                    }}
                  >
                    {chains[0]?.label}
                  </SwitchNetworkButton>{' '}
                </Message>
              }
            ></HeroCard>
          ) : (
            children
          )}
        </PageContent>
      </AppLifecycle>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
