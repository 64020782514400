import React from 'react';

import colors from 'Constants/colors';
import styled from 'styled-components';

import Spinner from './Spinner';

const Button = styled.button<{ primary?: boolean; danger?: boolean; warning?: boolean }>`
  line-height: 1rem;
  padding: 3px 0.5rem;
  margin: 0 -0.5rem;
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
  border-radius: 3rem;
  transition: all 0.1s ease;
  outline: 0;
  &:focus {
    box-shadow: 0 0 0px 3px ${({ danger }) => (danger ? colors.redRose : colors.greenSeafoam)} !important;
  }
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    text-decoration: none;
    color: ${({ danger, warning }) =>
      (danger && colors.redDark) || (warning && colors.brown) || colors.nexusBlue};
    svg {
      fill: ${({ danger, warning }) =>
        (danger && colors.redDark) || (warning && colors.brown) || colors.nexusBlue};
      filter: brightness(0.5);
    }
  }
  font-weight: 600;
  font-size: 1rem;
  border: 0px;
  background: none;
  color: ${({ danger, warning }) =>
    (danger && colors.redTart) || (warning && colors.yellowButter) || colors.nexusGreen};
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  display: inline;
`;

const LoadingContainer = styled.div`
  font-weight: 800;
  opacity: 0.5;
  cursor: default;
  position: relative;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  left: -1rem;
  top: 0.1rem;
`;

type ButtonClearProps = React.PropsWithChildren<{
  onClick: React.MouseEventHandler;
  loading?: boolean;
  disabled?: boolean;
  danger?: boolean;
}>;

const ButtonClear = ({ loading, children, onClick, ...props }: ButtonClearProps) => {
  if (loading) {
    return (
      <LoadingContainer {...props}>
        <SpinnerContainer>
          <Spinner color={colors.nexusGreen} size="0.9rem" />
        </SpinnerContainer>
        <Button {...props} disabled={true}>
          {children}
        </Button>
      </LoadingContainer>
    );
  }
  return (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export default ButtonClear;
