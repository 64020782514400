import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { createSelector, Selector, SelectorArray } from 'reselect';
import { displayFormat, separatorsFormat, shortenNumeric } from 'Services/utils';

export type DisplayObj = {
  compact: string | number;
  short: string;
  full: string;
  shortInput: string;
  fullInput: string;
};

export const toDisplayObj = (
  args: string | BigNumber | Array<string | BigNumber>,
  formatFn = (x: any) => x,
  decimals?: BigNumberish,
): DisplayObj => {
  const formatted = Array.isArray(args) ? formatFn(...(args as [any])) : formatFn(args);
  const displayFormatted = displayFormat(formatted, decimals as number);
  return {
    compact: shortenNumeric(formatted),
    short: separatorsFormat(displayFormatted, decimals),
    full: separatorsFormat(formatted),
    shortInput: displayFormatted,
    fullInput: formatted,
  };
};

export const displayObjFallback = (value: string) => ({
  compact: value,
  short: value,
  full: value,
  shortInput: value,
  fullInput: value,
});
/**
 * createDisplaySelector - Returns an object with all forms of display formats
 *
 * @param {array} selectors - A function or array of functions that select
 * the base value from which the display formats are derived.
 * @param {number} [decimals] - Number of decimals to display for short
 * display formats. This parameter is optional.
 * @param {function} [formatunits] - A function used to transform the base value to
 * a string representing the human readable number. Defaults to the identity
 * function. This parameter is optional.
 */
export const createDisplaySelector = (
  selectors: SelectorArray | Selector,
  formatUnits?: any,
  decimals?: number | BigNumber,
) =>
  createSelector(...(Array.isArray(selectors) ? selectors : [selectors]), (...args: any) =>
    toDisplayObj(args, formatUnits, decimals),
  );
