import React, { useRef, useState } from 'react';

import colors from 'Constants/colors';
import useOutsideRefClick from 'Hooks/useOutsideRefClick';
import styled from 'styled-components';

import Dropdown from './Dropdown';

const INPUT_VERTICAL_PADDING = 0.5; // rem
const INPUT_HORIZONTAL_PADDING = 1; // rem

const Input = styled.input<Partial<InputLargeProps>>`
  ::placeholder {
    color: ${colors.greySterling};
  }
  &[disabled] {
    cursor: not-allowed;
  }
  ${({ error, disabled }) =>
    (disabled && `color: ${colors.greyAsher}};`) ||
    (error && `color: ${colors.redTart}};`) ||
    `color: ${colors.greyAsher} !important;`}
  height: 7rem;
  text-align: right;
  padding: ${1 + INPUT_VERTICAL_PADDING}rem ${6.2 + INPUT_HORIZONTAL_PADDING}rem
    ${INPUT_VERTICAL_PADDING}rem ${INPUT_HORIZONTAL_PADDING}rem;
  margin: 0;
  border-radius: 1rem;
  position: relative;
  border: 1px solid ${colors.greyPearl};
  border-color: ${({ error }) => (error ? colors.redTart : colors.greyPearl)};
  &:focus {
    border-color: ${({ error }) => (error ? colors.redTart : colors.nexusGreen)};
    box-shadow: 0 0 0px 3px ${({ error }) => (error ? colors.redRose : colors.greenSeafoam)} !important;
  }
  box-sizing: border-box;
  background: ${({ disabled }) => (disabled && colors.greyPearl) || colors.white};
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  width: 100%;
  appearance: none;
`;

const Label = styled.label`
  position: absolute;
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
  left: ${INPUT_HORIZONTAL_PADDING}rem;
  top: ${INPUT_VERTICAL_PADDING}rem;
  font-weight: 600;
`;

const Container = styled.div<Partial<InputLargeProps>>`
  ${({ error, disabled }) =>
    (disabled && `color: ${colors.greyAsher}};`) ||
    (error && `color: ${colors.redTart}};`) ||
    `color: ${colors.greyAsher} !important;`}
  &[disabled] {
    cursor: not-allowed;
  }
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  height: 7rem;
`;

const Unit = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
`;

const UnitContainer = styled.div`
  position: absolute;
  top: ${2.5 + INPUT_VERTICAL_PADDING}rem;
  right: ${INPUT_HORIZONTAL_PADDING}rem;
  margin-left: 1rem;
`;

const InfoContainer = styled.div`
  position: absolute;
  bottom: ${INPUT_VERTICAL_PADDING - 0.3}rem;
  right: ${INPUT_HORIZONTAL_PADDING}rem;
  font-size: 1rem;
  color: ${colors.greyAsher};
`;

const Action = styled.div`
  position: absolute;
  top: ${INPUT_VERTICAL_PADDING / 2}rem;
  right: ${INPUT_HORIZONTAL_PADDING}rem;
`;

type InputLargeProps = {
  label: string;
  unitOfMeasure: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  actionComponent?: JSX.Element;
  placeholder?: string;
  info?: string;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
  unitOfMeasureOptions?: string[];
  // eslint-disable-next-line no-unused-vars
  onUnitOfMeasureChange?: (value: string) => void;
  type?: string;
  title?: string;
};

const InputLarge = (props: InputLargeProps) => {
  const {
    label,
    unitOfMeasure,
    unitOfMeasureOptions,
    onUnitOfMeasureChange,
    info,
    value,
    actionComponent,
    loading,
    error,
    disabled,
    ...inputProps
  } = props;

  const dropdownOptions = unitOfMeasureOptions?.map(option => ({
    value: option,
    label: option,
  }));

  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setMenuOpen(!menuOpen);
  useOutsideRefClick(dropdownRef, toggleDropdown);

  const clickHandler = (value: string) => {
    toggleDropdown();
    onUnitOfMeasureChange?.(value);
  };

  return (
    <Container error={error} disabled={disabled}>
      <Input
        step="1"
        min="0"
        value={value}
        placeholder="0"
        error={error}
        disabled={disabled}
        {...inputProps}
      />
      <Label aria-disabled={disabled}>{label}</Label>
      <Action aria-disabled={disabled}>{actionComponent}</Action>
      <UnitContainer>
        {dropdownOptions && dropdownOptions.length > 1 ? (
          <Unit>
            <Dropdown
              readOnlyInput
              selected={unitOfMeasure}
              options={dropdownOptions}
              onChange={option => clickHandler(option)}
              disabled={disabled}
            />
          </Unit>
        ) : (
          <Unit>{unitOfMeasure}</Unit>
        )}
      </UnitContainer>
      <InfoContainer> {info}</InfoContainer>
    </Container>
  );
};

export default InputLarge;
