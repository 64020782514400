import colors from 'Constants/colors';
import styled from 'styled-components';

export const BreadcrumbStyles = styled.div`
  font-weight: 400;

  ul {
    display: flex;
    list-style: none;
    padding: 0;

    // Override index.css
    margin-bottom: 0.8rem !important;
  }

  li:not(:last-child):after {
    content: '/';
    margin: 0 12px;
  }

  .link {
    color: ${colors.blueCobalt};
  }
`;
