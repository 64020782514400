import React from 'react';

import colors from 'Constants/colors';
import styled from 'styled-components';

const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 2rem;
`;

const ToggleInput = styled.input<{ disabled?: boolean }>`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + span {
    background-color: ${({ disabled }) => (disabled ? colors.greyPearl : colors.greenTea)};
  }

  &:checked + span:before {
    transform: translateX(19px);
  }
`;

const ToggleHandle = styled.span<{ isToggled: boolean; disabled?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.greyPearl};
  transition: 0.3s;
  border-radius: 20px;
  :before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 0px;
    background-color: ${colors.white};
    border: 1px solid
      ${({ isToggled, disabled }) =>
        isToggled && !disabled ? colors.nexusGreen : colors.greyPearl};
    border-radius: 50%;
    transition: 0.3s;
  }
`;

const ToggleLabel = styled.label`
  position: absolute;
  left: 100%;
  top: 2px;
  width: max-content;
  line-height: 1.1rem;
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: 400;
  color: ${colors.greyStone};
`;

type ToggleButtonProps = {
  label: string;
  isActive: boolean;
  onClick: (_isToggled: boolean) => void;
  disabled?: boolean;
};

export const ToggleButton = ({ label, isActive, onClick, disabled }: ToggleButtonProps) => {
  const handleOnChange = () => {
    onClick(!isActive);
  };

  return (
    <ToggleContainer>
      <ToggleInput
        type="checkbox"
        checked={isActive}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <ToggleHandle isToggled={isActive} disabled={disabled} />
      <ToggleLabel>{label}</ToggleLabel>
    </ToggleContainer>
  );
};
