import React from 'react';

import colors from 'Constants/colors';
import styled, { keyframes } from 'styled-components';
const RippleBase = styled.div`
  background-color: ${({ warning, danger, success, passive }) =>
    (passive && colors.greyStone) ||
    (warning && colors.yellowButter) ||
    (danger && colors.redTart) ||
    (success && colors.nexusGreen) ||
    colors.nexusGreen};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const Static = styled(RippleBase)`
  box-shadow: 0 0 0 6px
    ${({ warning, danger, success, passive }) =>
      (passive && colors.greyPearl) ||
      (warning && colors.yellowDaffodil) ||
      (danger && colors.redRose) ||
      (success && colors.greenSeafoam) ||
      colors.greenSeafoam};
`;

const rippleAnimation = ({ warning, danger, success, passive }) => keyframes`
    0% {
      box-shadow: 0 0 0 0
    ${
      (passive && colors.greyPearl) ||
      (warning && colors.yellowDaffodil) ||
      (danger && colors.redRose) ||
      (success && colors.greenSeafoam) ||
      colors.greenSeafoam
    };
    }
    50% {
      box-shadow: 0 0 0 6px
    ${
      (passive && colors.greyPearl) ||
      (warning && colors.yellowDaffodil) ||
      (danger && colors.redRose) ||
      (success && colors.greenSeafoam) ||
      colors.greenSeafoam
    };
    }
    100% {
      box-shadow: 0 0 0 12px ${colors.transparent};
    }
`;
const Animated = styled(RippleBase)`
  animation: ${props => rippleAnimation(props)} 1s linear infinite;
`;

const Ripple = ({ pulse, ...props }) => (pulse ? <Animated {...props} /> : <Static {...props} />);

export default Ripple;
