import { useSetChain } from '@web3-onboard/react';
import Hamburger from 'hamburger-react';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Logo from 'Assets/logo.svg';
import { AppLifecycleContext } from 'Components/AppLifecycle';
import Button from 'Components/Button';
import CopyButton from 'Components/CopyButton';
import EtherscanLink from 'Components/EtherscanLink';
import ExternalLink from 'Components/ExternalLink';
import { Row } from 'Components/gridComponents';
import DesktopUserButton from 'Components/menu/DesktopUserButton';
import MobileUserButton from 'Components/menu/MobileUserButton';
import { CHAIN_ID, ETHERSCAN_URL } from 'Config';
import colors from 'Constants/colors';
import navItems from 'Core/navItems';
import actions from 'Services/rootDuck/actions';
import {
  selectAccount,
  selectBalanceETHDisplay,
  selectBalanceNXMDisplay,
  selectIsNavMenuOpen,
  selectIsUserMenuOpen,
  selectPendingTransactions,
  selectUserTxHistory,
} from 'Services/rootDuck/selectors';
import styled from 'styled-components';

const TestnetPill = styled.div`
  position: relative;
  margin-left: 1rem;
  background: ${colors.redTart};
  color: ${colors.white};
  padding: 0.2rem 2rem;
  line-height: 1rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
`;

const WalletConnectionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Container = styled.div`
  min-height: 4rem;
  background: ${colors.white};
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.2s;
  z-index: 100;
`;

const InnerContainer = styled.div`
  max-width: calc(1536px + 2rem);
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 0 2rem;
  @media only screen and (max-width: 62rem) {
    & > a {
      margin: auto;
    }
    margin: 0 1rem;
  }
`;

const LogoContainer = styled.div`
  padding: 1rem 0;
  height: 2rem;
  & > svg {
    height: 100%;
    fill: ${colors.nexusGreen};
  }
`;

const Status = styled.div`
  margin-left: auto;
`;

const ActiveHighlightContainer = styled.div`
  display: flex;
  gap: 1rem;
  height: 4rem;
  align-items: center;
  margin-left: 2rem;
  padding-left: 1rem;
  font-weight: 600;
  font-size: 14px;
  & > .active > * {
    color: ${colors.blue500} !important;
    background: ${colors.blue200};
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
  }
`;

const NavContainer = styled.div`
  color: ${colors.greyStone};
  display: flex;
  align-items: center;
  height: 1rem;
  padding: 0.5rem 1rem;
  transition: all 0.1s ease;
  font-weight: 600;
  font-size: 14px;
  @media only screen and (max-width: 62rem) {
    padding: 0 1rem;
    height: 4rem;
  }
  &:hover {
    color: ${colors.nexusBlue};
  }
`;

const HamburgerContainer = styled.div`
  width: 48px;
  height: 48px;
  margin-left: -12px;
`;

const AccountInfoContainer = styled.div`
  @media only screen and (max-width: 62rem) {
    padding: 0.5rem 1rem 1rem 1rem;
  }
  padding: 0.5rem 2rem 1rem 2rem;
  color: ${colors.greyStone};
`;

const ExpandableMenu = styled.div`
  display: flex;
  ${({ collapsed }) => (collapsed ? 'transform: scaleY(0);' : '')}
  ${({ collapsed }) => (collapsed ? 'max-height: 0;' : 'max-height: 100%;')}
  transform-origin: top;
  transition: transform 0.2s ease;
  overflow: hidden;
  flex-direction: column;
  & > .active > * {
    color: ${colors.blue500} !important;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  bottom: 0;
  z-index: 3;
`;

const TransactionsContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 10rem;
`;

const TransactionStatus = styled.div`
  padding: 0 1rem;
  color: ${({ status }) =>
    (status === 'CONFIRMED' && colors.nexusGreen) ||
    (status === 'REVERTED' && colors.redTart) ||
    colors.yellowButter};
`;

const TransactionLink = ({ tx }) => {
  return (
    <div>
      <ExternalLink href={ETHERSCAN_URL + '/tx/' + tx.hash}>{tx.message}</ExternalLink>
    </div>
  );
};

const getStatusMessage = status =>
  (status === 'CONFIRMED' && 'Confirmed') || (status === 'REVERTED' && 'Reverted') || 'Pending';

const Transactions = () => {
  const dispatch = useDispatch();
  const txHistory = useSelector(selectUserTxHistory);
  const transactionsPending = useSelector(selectPendingTransactions);
  return (
    <>
      <Row justifyContent="space-between" align="center">
        <h3>
          Recent transactions
          {transactionsPending ? ` (${transactionsPending} pending)` : ''}
        </h3>
        <Button
          width="10rem"
          fixedWidth
          small
          noContentAbove
          onClick={() => dispatch(actions.clearTxHistory())}
        >
          Clear all
        </Button>
      </Row>
      <TransactionsContainer>
        {txHistory.map((x, index) => (
          <Row justifyContent="space-between" key={index}>
            <TransactionLink tx={x} />
            <TransactionStatus status={x.status}>{getStatusMessage(x.status)}</TransactionStatus>
          </Row>
        ))}
      </TransactionsContainer>
    </>
  );
};

const AccountInfo = () => {
  const account = useSelector(selectAccount);
  const { disconnectWallet } = useContext(AppLifecycleContext);
  const balanceNXMDisplay = useSelector(selectBalanceNXMDisplay);
  const balanceETHDisplay = useSelector(selectBalanceETHDisplay);

  if (!account) {
    return null;
  }
  return (
    <AccountInfoContainer>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 hidden-xs hidden-sm">
          <Transactions />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Row justifyContent="space-between" align="center">
            <h3>Account</h3>
            <Button
              width="10rem"
              fixedWidth
              primary
              small
              noContentAbove
              danger
              onClick={disconnectWallet}
            >
              Disconnect
            </Button>
          </Row>
          <Row justifyContent="space-between">
            <EtherscanLink address={account} length={6} />
            <CopyButton width="10rem" content={account}>
              Copy address
            </CopyButton>
          </Row>
          <h3>Balance</h3>
          <div>
            <span data-rh={balanceNXMDisplay.full + ' NXM'}>{balanceNXMDisplay.short} NXM</span>
          </div>
          <div>
            <span data-rh={balanceETHDisplay.full + ' ETH'}>{balanceETHDisplay.short} ETH</span>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 hidden-md hidden-lg">
          <Transactions />
        </div>
      </div>
    </AccountInfoContainer>
  );
};

const MobileMenu = ({
  elementRef,
  toggleUserMenu,
  closeAllMenus,
  toggleNavMenu,
  isNavMenuOpen,
  isUserMenuOpen,
  closeNavMenu,
  isConnectedToMainnet,
  connectedChain,
}) => {
  return (
    <>
      <Container ref={elementRef}>
        <InnerContainer>
          <Content centered>
            <HamburgerContainer>
              <Hamburger
                toggled={isNavMenuOpen}
                toggle={toggleNavMenu}
                color={colors.nexusBlue}
                size="25"
              />
            </HamburgerContainer>
            <NavLink to="/" onClick={closeAllMenus}>
              <LogoContainer>
                <Logo />
              </LogoContainer>
            </NavLink>
            {!isConnectedToMainnet && (
              <TestnetPill
                data-rh={`The app is connected to ${connectedChain.name} (chain ID: ${connectedChain.id})`}
              >
                TESTNET
              </TestnetPill>
            )}
            <MobileUserButton toggleUserMenu={toggleUserMenu} />
          </Content>
          <ExpandableMenu collapsed={!isNavMenuOpen}>
            {navItems.map(({ text, to, external }) =>
              external ? (
                <a href={to} target="_blank" rel="noreferrer" key={text}>
                  <NavContainer>{text}</NavContainer>
                </a>
              ) : (
                <NavLink key={text} to={to} activeClassName="active" onClick={closeNavMenu}>
                  <NavContainer>{text}</NavContainer>
                </NavLink>
              ),
            )}
          </ExpandableMenu>
          <ExpandableMenu collapsed={!isUserMenuOpen}>
            <AccountInfo />
          </ExpandableMenu>
        </InnerContainer>
      </Container>
      {isNavMenuOpen || isUserMenuOpen ? <Backdrop onClick={closeAllMenus} /> : null}
    </>
  );
};

const DesktopMenu = ({
  elementRef,
  toggleUserMenu,
  closeAllMenus,
  isUserMenuOpen,
  isConnectedToMainnet,
  connectedChain,
}) => {
  return (
    <>
      <Container ref={elementRef}>
        <InnerContainer>
          <Content>
            <NavLink to="/">
              <LogoContainer>
                <Logo />
              </LogoContainer>
            </NavLink>
            <ActiveHighlightContainer>
              {navItems.map(({ text, to, external }) =>
                external ? (
                  <a href={to} target="_blank" rel="noreferrer" key={text}>
                    <NavContainer>{text}</NavContainer>
                  </a>
                ) : (
                  <NavLink key={text} to={to} activeClassName="active">
                    <NavContainer>{text}</NavContainer>
                  </NavLink>
                ),
              )}
            </ActiveHighlightContainer>
            <WalletConnectionContainer>
              {!isConnectedToMainnet && (
                <TestnetPill
                  data-rh={`The app is connected to ${connectedChain.name} (chain ID: ${connectedChain.id})`}
                >
                  TESTNET
                </TestnetPill>
              )}
              <Status>
                <DesktopUserButton toggleUserMenu={toggleUserMenu} />
              </Status>
            </WalletConnectionContainer>
          </Content>
          <ExpandableMenu collapsed={!isUserMenuOpen}>
            <AccountInfo />
          </ExpandableMenu>
        </InnerContainer>
      </Container>
      {isUserMenuOpen ? <Backdrop onClick={closeAllMenus} /> : null}
    </>
  );
};

const withAutoHide = Component => props => {
  const scrollY = useRef(window.pageYOffset);
  const element = useRef(null);
  const handler = () => {
    const currentScroll = window.pageYOffset;
    if (scrollY.current > currentScroll || currentScroll < 60) {
      element.current.style.top = '0';
    } else {
      element.current.style.top = '-4rem';
    }
    scrollY.current = currentScroll;
  };

  useEffect(() => {
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);
  return <Component elementRef={element} {...props} />;
};

const DesktopMenuHideable = withAutoHide(DesktopMenu);

const MobileMenuHideable = withAutoHide(MobileMenu);

const Menu = props => {
  const dispatch = useDispatch();
  const isNavMenuOpen = useSelector(selectIsNavMenuOpen);
  const isUserMenuOpen = useSelector(selectIsUserMenuOpen);
  const toggleUserMenu = () => dispatch(actions.toggleUserMenu());
  const closeAllMenus = () => dispatch(actions.closeAllMenus());
  const toggleNavMenu = () => dispatch(actions.toggleNavMenu());
  const closeNavMenu = () => dispatch(actions.closeNavMenu());

  const [
    {
      chains, // the list of chains that web3-onboard was initialized with
      connectedChain, // the current chain the user's wallet is connected to
    },
  ] = useSetChain();
  const chainId = connectedChain?.id || CHAIN_ID;
  const connectedChainName =
    chains.find(chain => +chain.id === +chainId)?.label || 'Unknown network';
  // This is used for showing a visual cue when connected to other network than mainnet
  const isConnectedToMainnet = +chainId === +CHAIN_ID;

  const mergedProps = {
    ...props,
    toggleUserMenu,
    closeAllMenus,
    toggleNavMenu,
    isNavMenuOpen,
    isUserMenuOpen,
    closeNavMenu,
    isConnectedToMainnet,
    connectedChain: {
      id: +chainId,
      name: connectedChainName,
    },
  };

  return (
    <div className="row">
      <div className="col-xs-12 hidden-xs hidden-sm">
        <DesktopMenuHideable {...mergedProps} />
      </div>
      <div className="col-xs-12 hidden-md hidden-lg">
        <MobileMenuHideable {...mergedProps} />
      </div>
    </div>
  );
};

export default Menu;
