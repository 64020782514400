import styled from 'styled-components';

const PageLayout = styled.div`
  padding: 0rem 4rem 2rem 4rem;
  max-width: calc(1536px - 1rem);
  min-height: calc(100vh - 30rem);
  margin: 0 auto;

  & > * {
    @media only screen and (max-width: 48em) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 48em) {
    padding: 0 0 2rem 0;
    width: 100%;
  }
`;

export default PageLayout;
